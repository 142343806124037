export const formatCSV = (data) =>
  data
    .map((line) =>
      line
        .map(
          (cell) =>
            //`"=""${cell}"""`
            `"${cell}"`,
        )
        .join(';'),
    )
    .join('\n')
