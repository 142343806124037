import React, { FC } from 'react'
import { Spinner } from 'reactstrap'

interface Props {
  /** Width & height */
  size?: number
  /** Line width */
  width?: number
  color?: string
  centered?: boolean
}

const Loader: FC<Props> = ({ size, width, color, centered }) => {
  const Content = () => (
    <Spinner
      style={{
        width: size,
        height: size,
        borderWidth: width,
        color,
      }}
    />
  )

  return centered ? (
    <div className="d-flex h-100 justify-content-center align-items-center">
      <Content />
    </div>
  ) : (
    <Content />
  )
}

Loader.defaultProps = {
  size: 40,
  width: 6,
  color: 'rgba(0, 199, 147, .8)',
  centered: false,
}

export default Loader
