//** @jsxImportSource @emotion/react */
import { FC } from 'react'
import { Input } from 'reactstrap'

const TableInput: FC<any> = ({
  field,
  form: { errors },
  formatter = (x: any) => x,
  textAlign = 'left',
  ...props
}) => {
  return (
    <Input
      type="text"
      title={field.value}
      bsSize="sm"
      name={field.name}
      value={formatter(field.value) || ''}
      onChange={field.onChange}
      // onBlur={field.onBlur}
      {...props}
      invalid={!!errors[field.name]}
      style={{
        borderRadius: 0,
        height: 25,
        padding: '2px 5px',
        marginTop: 3,
        textAlign,
        fontSize: 14,
      }}
    />
  )
}

export default TableInput
