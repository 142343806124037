import { useDispatch, useSelector } from 'react-redux'
import { Input } from 'reactstrap'

import { setStartDate, getStartDate, getIsPeriod } from 'store/invoices/crawler'
import { FormGroup } from 'library'

const StartDate = () => {
  const dispatch = useDispatch()
  const value = useSelector(getStartDate)
  const isPeriod = useSelector(getIsPeriod)

  return (
    <FormGroup label={isPeriod ? 'Début' : 'Date'}>
      <Input
        type="date"
        value={value || ''}
        onChange={(e) => {
          dispatch(setStartDate(e.target.value))
        }}
        style={{
          fontSize: 14,
          border: !value ? '1px solid red' : '1px solid #d7dee4',
        }}
      />
    </FormGroup>
  )
}

export default StartDate
