import { Provider } from 'react-redux'
import { OidcProvider } from 'redux-oidc'
import { I18nextProvider } from 'react-i18next'
import { Global, ThemeProvider } from '@emotion/react'
import { BrowserRouter as Router, Route } from 'react-router-dom'

import i18n from 'i18n'
import userManager from 'userManager'
import configureStore from 'configureStore'
import global from 'styles/global'
import theme from 'styles/theme'
import Root from 'components/Root'
import { ReactComponent as Icons } from 'assets/images/icons.svg'
import { QueryProvider } from 'components/QueryProvider'

export const store = configureStore()

export default function App() {
  return (
    <>
      <Icons />
      <Global styles={global} />
      <Provider store={store}>
        <OidcProvider store={store} userManager={userManager}>
          <I18nextProvider i18n={i18n}>
            <ThemeProvider theme={theme}>
              <QueryProvider>
                <Router>
                  <Route path="/" component={Root} />
                </Router>
              </QueryProvider>
            </ThemeProvider>
          </I18nextProvider>
        </OidcProvider>
      </Provider>
    </>
  )
}
