import React, { FC } from 'react'
import last from 'lodash/last'

import { ReactComponent as PdfIcon } from 'assets/images/pdf.svg'
import { ReactComponent as XlsIcon } from 'assets/images/xls.svg'

const isPdfFilename = (filename: string) => last(filename.split('.')) === 'pdf'

interface Props {
  filename?: string
  isPdf?: boolean
  style?: object
}

const FileIcon: FC<Props> = ({ filename = '', isPdf = false, style = {} }) => {
  return isPdf || isPdfFilename(filename) ? (
    <PdfIcon
      style={{
        height: 15,
        top: -1,
        position: 'relative',
        marginRight: 8,
        marginLeft: 2,
        opacity: 0.7,
        ...style,
      }}
    />
  ) : (
    <XlsIcon
      style={{
        height: 17,
        top: -2,
        position: 'relative',
        marginRight: 8,
        opacity: 0.6,
        ...style,
      }}
    />
  )
}

export default FileIcon
