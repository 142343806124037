import React, { FC, useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { Row, Col, Form, FormGroup, Input, Label, Button } from 'reactstrap'
import Notifications from 'react-notification-system-redux'

import { Icon } from 'library'
import UserInfosInput from './UserInfosInput'
import {
  fetchUserInfo,
  postUserForm,
  logout,
  postSuccess,
  postError,
  userDetail,
} from 'store/user'
import { getUser } from 'store/selectors'

const notifySuccess = Notifications.success({
  title: 'Your profile information has been saved successfully !',
  position: 'bl',
  autoDismiss: 4,
})

const notifyError = Notifications.error({
  title: 'Something went wrong !',
  position: 'bl',
  autoDismiss: 4,
})

interface Props {
  userPanelOpen: boolean
  setUserPanelOpen: (b: boolean) => void
}

const UserInfos: FC<Props> = ({ setUserPanelOpen, userPanelOpen }) => {
  const { t } = useTranslation()

  const dispatch = useDispatch()
  const userPostSuccess = useSelector(postSuccess)
  const userPostError = useSelector(postError)
  const userInfo = useSelector(userDetail)
  const user = useSelector(getUser)

  const [isInit, setIsInit] = useState(false)
  const [formData, setFormData] = useState<any>({})

  useEffect(() => {
    dispatch(fetchUserInfo())
  }, [dispatch])

  useEffect(() => {
    if (!isInit && userInfo) {
      setFormData(userInfo)
      setIsInit(true)
    }
  }, [isInit, userInfo])

  useEffect(() => {
    if (userPostSuccess) dispatch(notifySuccess)
  }, [dispatch, userPostSuccess])

  useEffect(() => {
    if (userPostError) dispatch(notifyError)
  }, [dispatch, userPostError])

  if (!isInit)
    return (
      <Row className="form-inline panel-header radio-input-btn">
        <Col className="d-flex">
          <div className="ml-auto">
            <Button
              color="danger"
              onClick={() => dispatch(logout(user))}
              style={{ cursor: 'default' }}
            >
              <Icon
                id="power"
                size={20}
                style={{ marginRight: 8, verticalAlign: 'text-bottom' }}
              />
            </Button>
          </div>
        </Col>
      </Row>
    )

  return (
    <Form
      className="user-infos"
      onSubmit={(e) => {
        e.preventDefault()
        dispatch(postUserForm(formData))
        setUserPanelOpen(!userPanelOpen)
      }}
    >
      <Row className="form-inline panel-header radio-input-btn">
        <Col className="d-flex">
          <FormGroup>
            <Input
              id="userLanFr"
              type="radio"
              name="userLan"
              defaultChecked={formData.LanId === 1}
              onChange={() => setFormData({ ...formData, LanId: 1 })}
              value="1"
            />
            <Label for="userLanFr" className="btn">
              FR
            </Label>
          </FormGroup>
          <FormGroup>
            <Input
              id="userLanEn"
              type="radio"
              name="userLan"
              defaultChecked={formData.LanId === 2}
              onChange={() => setFormData({ ...formData, LanId: 2 })}
              value="2"
            />
            <Label for="userLanEn" className="btn">
              EN
            </Label>
          </FormGroup>
          <div className="ml-auto">
            <Button
              color="danger"
              onClick={() => dispatch(logout(user))}
              style={{ cursor: 'default' }}
            >
              <Icon
                id="power"
                size={20}
                style={{ marginRight: 8, verticalAlign: 'text-bottom' }}
              />
              {t('user.logout')}
            </Button>
          </div>
        </Col>
      </Row>

      <Row className="panel-flex">
        <Col>
          <Row className="radio-input form-inline">
            <Col className="d-flex">
              <FormGroup>
                <Input
                  id="userM"
                  type="radio"
                  name="userGender"
                  defaultChecked={formData.UsrCiviliteId === 1}
                  onChange={() =>
                    setFormData({
                      ...formData,
                      UsrCiviliteId: 1,
                      UserCivilite: t('user.userInfo.male'),
                    })
                  }
                  value="0"
                />
                <Label for="userM">{t('user.userInfo.male')}</Label>
              </FormGroup>
              <FormGroup>
                <Input
                  id="userF"
                  type="radio"
                  name="userGender"
                  defaultChecked={formData.UsrCiviliteId === 2}
                  onChange={() =>
                    setFormData({
                      ...formData,
                      UsrCiviliteId: 2,
                      UserCivilite: t('user.userInfo.female'),
                    })
                  }
                  value="1"
                />
                <Label for="userF">{t('user.userInfo.female')}</Label>
              </FormGroup>
            </Col>
          </Row>

          <UserInfosInput
            id="userFirstname"
            value={formData.UsrNom}
            onChange={(e) =>
              setFormData({ ...formData, UsrNom: e.target.value })
            }
            label={t('user.userInfo.firstName')}
          />
          <UserInfosInput
            id="userLastname"
            value={formData.UsrPrenom}
            onChange={(e) =>
              setFormData({ ...formData, UsrPrenom: e.target.value })
            }
            label={t('user.userInfo.lastName')}
          />
          <UserInfosInput
            id="userEmail"
            value={formData.UsrEMail}
            onChange={(e) =>
              setFormData({ ...formData, UsrEMail: e.target.value })
            }
            label={t('user.userInfo.email')}
            required
          />
          <UserInfosInput
            id="userAddress"
            value={formData.UsrAdresse && formData.UsrAdresse.AdrAdresse}
            onChange={(e) =>
              setFormData({
                ...formData,
                UsrAdresse: {
                  ...formData.UsrAdresse,
                  AdrAdresse: e.target.value,
                },
              })
            }
            label={t('user.userInfo.address')}
          />
          <UserInfosInput
            id="userAddressComp1"
            value={formData.UsrAdresse && formData.UsrAdresse.AdrCompl1}
            onChange={(e) =>
              setFormData({
                ...formData,
                UsrAdresse: {
                  ...formData.UsrAdresse,
                  AdrCompl1: e.target.value,
                },
              })
            }
            label={t('user.userInfo.addressComp1')}
          />
          <UserInfosInput
            id="userAddressComp2"
            value={formData.UsrAdresse && formData.UsrAdresse.AdrCompl2}
            onChange={(e) =>
              setFormData({
                ...formData,
                UsrAdresse: {
                  ...formData.UsrAdresse,
                  AdrCompl2: e.target.value,
                },
              })
            }
            label={t('user.userInfo.addressComp2')}
          />
          <UserInfosInput
            id="userPc"
            value={formData.UsrAdresse && formData.UsrAdresse.AdrCodePostal}
            onChange={(e) =>
              setFormData({
                ...formData,
                UsrAdresse: {
                  ...formData.UsrAdresse,
                  AdrCodePostal: e.target.value,
                },
              })
            }
            label={t('user.userInfo.pc')}
          />
          <UserInfosInput
            id="userCity"
            value={formData.UsrAdresse && formData.UsrAdresse.AdrVille}
            onChange={(e) =>
              setFormData({
                ...formData,
                UsrAdresse: {
                  ...formData.UsrAdresse,
                  AdrVille: e.target.value,
                },
              })
            }
            label={t('user.userInfo.city')}
          />
          <UserInfosInput
            id="userPhone"
            value={formData.UsrNumTel}
            onChange={(e) =>
              setFormData({
                ...formData,
                UsrNumTel: e.target.value,
              })
            }
            label={t('user.userInfo.phone')}
          />
          <UserInfosInput
            id="userMobile"
            value={formData.UsrNumMob}
            onChange={(e) =>
              setFormData({
                ...formData,
                UsrNumMob: e.target.value,
              })
            }
            label={t('user.userInfo.mobile')}
          />
        </Col>
      </Row>

      <Row className="panel-footer">
        <Col className="d-flex">
          <div className="ml-auto">
            <div className="push-right">
              <Button
                type="button"
                color="link"
                onClick={(e) => {
                  e.preventDefault()
                  setUserPanelOpen(!userPanelOpen)
                }}
                style={{ cursor: 'default' }}
              >
                {t('user.userInfo.cancel')}
              </Button>
              <Button
                type="submit"
                color="primary"
                style={{ cursor: 'default' }}
              >
                {t('user.userInfo.save')}
              </Button>
            </div>
          </div>
        </Col>
      </Row>
    </Form>
  )
}

export default UserInfos
