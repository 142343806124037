import { createStructuredSelector } from 'reselect'
import moment from 'moment'
import {
  createAction,
  createReducer,
  ActionType,
  isActionOf,
} from 'typesafe-actions'
import { combineEpics } from 'redux-observable'
import { filter, flatMap, withLatestFrom } from 'rxjs/operators'
import { of } from 'rxjs'

import { getInvoiceClient, getInvoiceProvider } from 'store/invoices/invoice'
import { RootState, RootAction } from 'store'
import { getPageSort, getSortDirection } from './invoices'
import { Energy } from 'api/types'

/* Types */

export interface SelectionState {
  provider: string | null
  client: number | null
  country: string | null
  energy: Energy
  startDate: string | null
  endDate: string | null
  createdDate: string | null
  updatedDate: string | null
  invoiceNumber: string | null
  templateName: string | null
  sortField: string | null
  sortDirection: number | 0
  pdl: string | null
  status: string[]
  errorCodes: string[]
  lineErrorCodes: string[]
  pdfStatus: string[]
  invoice: number | null
  adminProvider: string | null
  adminClient: number | null
  fileType: string | null
}

// Actions
export const setProvider = createAction(
  '@data-mgmt/invoices/selection/SET_PROVIDER',
  (action) => (id: string) => action(id)
)
export const setClient = createAction(
  '@data-mgmt/invoices/selection/SET_CLIENT',
  (action) => (id: number) => action(id)
)
export const setCountry = createAction(
  '@data-mgmt/invoices/selection/SET_COUNTRY',
  (action) => (id: string | null) => action(id)
)
export const setEnergy = createAction(
  '@data-mgmt/invoices/selection/SET_ENERGY',
  (action) => (id: Energy) => action(id)
)
export const setStatus = createAction(
  '@data-mgmt/invoices/selection/SET_STATUS',
  (action) => (id: string[]) => action(id)
)
export const setErrorCodes = createAction(
  '@data-mgmt/invoices/selection/SET_ERROR_CODES',
  (action) => (id: string[]) => action(id)
)
export const setLineErrorCodes = createAction(
  '@data-mgmt/invoices/selection/SET_LINE_ERROR_CODES',
  (action) => (id: string[]) => action(id)
)
export const setPdfStatus = createAction(
  '@data-mgmt/invoices/selection/SET_PDF_STATUS',
  (action) => (id: string[]) => action(id)
)
export const setStartDate = createAction(
  '@data-mgmt/invoices/selection/SET_START_DATE',
  (action) => (date: string) => action(date)
)
export const setEndDate = createAction(
  '@data-mgmt/invoices/selection/SET_END_DATE',
  (action) => (date: string) => action(date)
)
export const setCreatedDate = createAction(
  '@data-mgmt/invoices/selection/SET_CREATED_DATE',
  (action) => (date: string) => action(date)
)
export const setUpdatedDate = createAction(
  '@data-mgmt/invoices/selection/SET_UPDATED_DATE',
  (action) => (date: string) => action(date)
)
export const setInvoiceNumber = createAction(
  '@data-mgmt/invoices/selection/SET_INVOICE_NUMBER',
  (action) => (invoiceNumber: string) => action(invoiceNumber)
)
export const setTemplateName = createAction(
  '@data-mgmt/invoices/selection/SET_TEMPLATE_NAME',
  (action) => (templateName: string) => action(templateName)
)
export const setInvoice = createAction(
  '@data-mgmt/invoices/selection/SET_INVOICE',
  (action) => (id: number | null) => action(id)
)
export const setPdl = createAction(
  '@data-mgmt/invoices/selection/SET_PDL',
  (action) => (pdl: string) => action(pdl)
)
export const setAdminProvider = createAction(
  '@data-mgmt/invoices/selection/SET_ADMIN_PROVIDER',
  (action) => (id: string | null) => action(id)
)
export const setAdminClient = createAction(
  '@data-mgmt/invoices/selection/SET_ADMIN_CLIENT',
  (action) => (id: number | null) => action(id)
)
export const setFileType = createAction(
  '@data-mgmt/invoices/selection/SET_FILE_TYPE',
  (action) => (id: string | null) => action(id)
)

const actions = {
  setProvider,
  setClient,
  setCountry,
  setEnergy,
  setStatus,
  setErrorCodes,
  setLineErrorCodes,
  setPdfStatus,
  setStartDate,
  setEndDate,
  setInvoice,
  setPdl,
  setCreatedDate,
  setUpdatedDate,
  setInvoiceNumber,
  setTemplateName,
  setAdminProvider,
  setAdminClient,
  setFileType,
}

export type SelectionAction = ActionType<typeof actions>

// Reducer
const initialState = {
  provider: null,
  client: null,
  country: null,
  energy: Energy.Electricite,
  status: [],
  errorCodes: [],
  lineErrorCodes: [],
  pdfStatus: [],
  startDate: moment()
    .subtract(6, 'month')
    .format('YYYY-MM-DD'),
  endDate: moment().format('YYYY-MM-DD'),
  invoice: null,
  pdl: null,
  invoiceNumber: null,
  templateName: null,
  sortField: null,
  sortDirection: 0,
  createdDate: null,
  updatedDate: null,
  adminProvider: null,
  adminClient: null,
  fileType: null,
}

export default createReducer<SelectionState, SelectionAction>(initialState)
  .handleAction(setProvider, (state, action) => ({
    ...state,
    provider: action.payload,
  }))
  .handleAction(setClient, (state, action) => ({
    ...state,
    client: action.payload,
  }))
  .handleAction(setCountry, (state, action) => ({
    ...state,
    country: action.payload,
  }))
  .handleAction(setEnergy, (state, action) => ({
    ...state,
    energy: action.payload,
  }))
  .handleAction(setStatus, (state, action) => ({
    ...state,
    status: action.payload,
  }))
  .handleAction(setErrorCodes, (state, action) => ({
    ...state,
    errorCodes: action.payload,
  }))
  .handleAction(setLineErrorCodes, (state, action) => ({
    ...state,
    lineErrorCodes: action.payload,
  }))
  .handleAction(setPdfStatus, (state, action) => ({
    ...state,
    pdfStatus: action.payload,
  }))
  .handleAction(setStartDate, (state, action) => ({
    ...state,
    startDate: action.payload,
  }))
  .handleAction(setEndDate, (state, action) => ({
    ...state,
    endDate: action.payload,
  }))
  .handleAction(setInvoice, (state, action) => ({
    ...state,
    invoice: action.payload,
  }))
  .handleAction(setPdl, (state, action) => ({
    ...state,
    pdl: action.payload,
  }))
  .handleAction(setCreatedDate, (state, action) => ({
    ...state,
    createdDate: action.payload,
  }))
  .handleAction(setUpdatedDate, (state, action) => ({
    ...state,
    updatedDate: action.payload,
  }))
  .handleAction(setInvoiceNumber, (state, action) => ({
    ...state,
    invoiceNumber: action.payload,
  }))
  .handleAction(setTemplateName, (state, action) => ({
    ...state,
    templateName: action.payload,
  }))
  .handleAction(setAdminProvider, (state, action) => ({
    ...state,
    adminProvider: action.payload,
  }))
  .handleAction(setAdminClient, (state, action) => ({
    ...state,
    adminClient: action.payload,
  }))
  .handleAction(setFileType, (state, action) => ({
    ...state,
    fileType: action.payload,
  }))

// Selectors
export const getProvider = (state: RootState) => state.invoices.selection.provider
export const getClient = (state: RootState) => state.invoices.selection.client
export const getCountry = (state: RootState) => state.invoices.selection.country
export const getEnergy = (state: RootState) => state.invoices.selection.energy
export const getStatus = (state: RootState) => state.invoices.selection.status
export const getErrorCodes = (state: RootState) => state.invoices.selection.errorCodes
export const getLineErrorCodes = (state: RootState) => state.invoices.selection.lineErrorCodes
export const getPdfStatus = (state: RootState) =>
  state.invoices.selection.pdfStatus
export const getStartDate = (state: RootState) =>
  state.invoices.selection.startDate
export const getEndDate = (state: RootState) => state.invoices.selection.endDate
export const getCreatedDate = (state: RootState) =>
  state.invoices.selection.createdDate
export const getUpdatedDate = (state: RootState) =>
  state.invoices.selection.updatedDate
export const getInvoiceNumber = (state: RootState) =>
  state.invoices.selection.invoiceNumber
export const getTemplateName = (state: RootState) =>
  state.invoices.selection.templateName
export const getInvoice = (state: RootState) => state.invoices.selection.invoice
export const getPdl = (state: RootState) => state.invoices.selection.pdl
export const getAdminProvider = (state: RootState) =>
  state.invoices.selection.adminProvider
export const getAdminClient = (state: RootState) =>
  state.invoices.selection.adminClient
export const getFileType = (state: RootState) =>
  state.invoices.selection.fileType

export const getSelection = createStructuredSelector<
  RootState,
  Pick<
    SelectionState,
    | 'provider'
    | 'client'
    | 'country'
    | 'energy'
    | 'startDate'
    | 'endDate'
    | 'pdl'
    | 'status'
    | 'errorCodes'
    | 'lineErrorCodes'
    | 'createdDate'
    | 'updatedDate'
    | 'invoiceNumber'
    | 'templateName'
    | 'sortField'
    | 'sortDirection'
    | 'fileType'
  >
>({
  provider: getProvider,
  client: getClient,
  country: getCountry,
  energy: getEnergy,
  startDate: getStartDate,
  endDate: getEndDate,
  pdl: getPdl,
  status: getStatus,
  errorCodes: getErrorCodes,
  lineErrorCodes: getLineErrorCodes,
  createdDate: getCreatedDate,
  updatedDate: getUpdatedDate,
  invoiceNumber: getInvoiceNumber,
  templateName: getTemplateName,
  sortField: getPageSort,
  sortDirection: getSortDirection,
  fileType: getFileType,
})

// Epics
export const selectionEpic = combineEpics<RootAction, RootAction, RootState>(
  (action$, state$) =>
    action$.pipe(
      filter(isActionOf(setInvoice)),
      withLatestFrom(state$),
      flatMap(([_, state]) =>
        of(
          setAdminProvider(getInvoiceProvider(state)),
          setAdminClient(getInvoiceClient(state))
        )
      )
    )
)
