//** @jsxImportSource @emotion/react */
import { FC, useState, Fragment, ReactChild } from 'react'
import { Navbar, Button } from 'reactstrap'
import { Switch, Route, Redirect } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { Theme } from '@emotion/react'

import UserPanel from 'components/userPanel/UserPanel'
import Version from 'components/Version'
import { Icon, TabNav, ErrorBoundary } from 'library'
import { getInvoice } from 'store/invoices/selection'

const adminPaths = ['admin']

interface Props {
  path: string
  url: string
  redirect: string
  routes: {
    id: string
    label: string
    icon: string | ReactChild
    component: FC
  }[]
}

const PageLayout = ({ path, url, routes, redirect }: Props) => {
  const [userPanelOpen, setUserPanelOpen] = useState(false)
  const selectedInvoice = useSelector(getInvoice)

  const reducer: (acc: any, route: any) => any = (acc, route) => {
    if (route.id === 'details/:id') {
      return !selectedInvoice
        ? acc
        : [
            ...acc,
            {
              ...route,
              id: `details/${selectedInvoice}`,
            },
          ]
    }
    if (!adminPaths.includes(route.id)) {
      return [...acc, route]
    }
    return acc
  }
  const mainRoutes = routes.reduce(reducer, [])

  const adminRoutes = routes.filter((route) => adminPaths.includes(route.id))

  return (
    <Fragment>
      <Navbar
        className="justify-content-between py-0"
        css={(theme: Theme) => ({
          height: 76,
          backgroundColor: theme.colors.primary,
        })}
      >
        <TabNav url={url} routes={mainRoutes} />
        <div className="d-flex" css={{ height: '100%' }}>
          <TabNav url={url} routes={adminRoutes} />
          <Button
            color="link"
            css={{
              cursor: 'default !important',
              color: 'rgba(255, 255, 255, .75)',
              '&:hover, &:active': { color: '#fff' },
              '&:focus': {
                color: 'rgba(255, 255, 255, .75)',
                outline: '-webkit-focus-ring-color auto 5px',
              },
            }}
            onClick={() => {
              setUserPanelOpen(true)
            }}
          >
            <Icon id="user" size={20} style={{ marginRight: 6, top: 3 }} />
            <Icon id="smallarrow-down" size={12} />
          </Button>
        </div>
      </Navbar>

      <div
        css={{
          overflowY: 'auto',
          height: 'calc(100vh - 76px)',
          position: 'relative',
        }}
      >
        <div style={{ minHeight: 'calc(100% - 24px)' }}>
          <main>
            <ErrorBoundary>
              <Switch>
                <Route
                  exact
                  path={path}
                  render={() => <Redirect to={`${path}/${redirect}`} push />}
                />
                {routes.map((route) => (
                  <Route
                    key={route.id}
                    path={`${path}/${route.id}`}
                    component={route.component}
                  />
                ))}
              </Switch>
            </ErrorBoundary>
          </main>
        </div>

        <div
          style={{
            textAlign: 'right',
            height: 24,
            padding: '6px 8px',
            fontSize: 12,
            lineHeight: 1,
          }}
        >
          <Version />
        </div>
      </div>

      <UserPanel
        userPanelOpen={userPanelOpen}
        setUserPanelOpen={setUserPanelOpen}
      />
    </Fragment>
  )
}

export default PageLayout
