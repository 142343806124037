import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import isEmpty from 'lodash/isEmpty'
import { useSearchCrawlersQuery } from 'queries/crawler'
import { setCustomer, getCustomer } from 'store/invoices/crawler'
import { Select, FormGroup } from 'library'

const CustomerSelect = () => {
  const dispatch = useDispatch()
  const selected = useSelector(getCustomer)
  const { data, isFetching } = useSearchCrawlersQuery({ suspense: false })

  const options = Object.values(data?.customers ?? []).map((customer) => ({
    value: customer.id,
    label: customer.name,
  }))

  /* Select first client */
  useEffect(() => {
    if (selected !== null || isEmpty(options)) return
    dispatch(setCustomer(options[0].value))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [options, selected])

  return (
    <FormGroup label="Client">
      <Select
        value={options.filter((option) => option.value === selected)}
        options={options}
        isLoading={isFetching}
        onChange={(option: any) => {
          dispatch(setCustomer(option ? option.value : null))
        }}
        placeholder=""
      />
    </FormGroup>
  )
}

export default CustomerSelect
