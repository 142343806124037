import { useReducer, useEffect } from 'react'
import { superFetch } from 'helpers/apiConf'

const isSuccess: (status: number) => boolean = (status) =>
  status >= 200 && status < 300

const initialState = {
  data: null,
  loading: false,
  error: null,
}

interface State {
  data: any | null
  loading: boolean
  error: string | null
  fileName?: string
}

type Action =
  | { type: 'request' }
  | { type: 'success'; data: any; fileName?: string }
  | { type: 'failure'; error: Error }

function reducer(state: State, action: Action): State {
  switch (action.type) {
    case 'request':
      return {
        ...state,
        loading: true,
        error: null,
      }
    case 'success':
      return {
        data: action.data,
        loading: false,
        error: null,
        ...(action.fileName && { fileName: action.fileName }),
      }
    case 'failure':
      return {
        data: null,
        loading: false,
        error: action.error.message,
      }
    default:
      return state
  }
}

type Body = 'text' | 'json' | 'arrayBuffer'

const useFetch = (url: string, bodyType?: Body) => {
  const [state, dispatch] = useReducer(reducer, initialState)

  useEffect(() => {
    const fetchData = async () => {
      try {
        dispatch({ type: 'request' })
        const resp = await superFetch({ url, invoiceApi: true })
        const { status, type, statusText } = resp
        if (!isSuccess(status))
          throw new Error(
            `${status}${type && ` (${type})`}${statusText &&
              ` - ${statusText}`}`
          )

        const disposition = resp.headers.get('content-disposition')

        let fileName
        if (disposition && disposition.indexOf('attachment') !== -1) {
          const filenameRx = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/
          const matches = filenameRx.exec(disposition)
          if (matches != null && matches[1]) {
            fileName = matches[1].replace(/['"]/g, '')
          }
        }

        const data =
          bodyType === 'text'
            ? await resp.text()
            : bodyType === 'json'
            ? await resp.json()
            : bodyType === 'arrayBuffer'
            ? await resp.arrayBuffer()
            : resp

        dispatch({
          type: 'success',
          data,
          ...(fileName && { fileName }),
        })
      } catch (error: any) {
        dispatch({ type: 'failure', error })
      }
    }

    fetchData()
  }, [bodyType, url])

  return state
}

export default useFetch
