import React, { FC } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { getClients, fetchClients } from 'store/invoices/clients'
import { setClient, getClient } from 'store/invoices/selection'
import { Select, FormGroup } from 'library'

interface Props {
  inline?: boolean
  width?: number
  showAllOption?: boolean
}

const ClientSelect: FC<Props> = ({
  inline = false,
  width,
  showAllOption = false,
}) => {
  const dispatch = useDispatch()
  const { clients, loading, error } = useSelector(getClients)
  const selected = useSelector(getClient)

  React.useEffect(() => {
    if (!clients && !loading) dispatch(fetchClients.request())
  }, [clients, dispatch, loading])

  const options = React.useMemo(
    () =>
      !clients
        ? []
        : [
            ...(showAllOption ? [{ value: null, label: 'Tous' }] : []),
            ...Object.values(clients).map((client: any) => ({
              value: client.id,
              label: client.label,
            })),
          ],
    [clients, showAllOption]
  )

  if (error) return null

  return (
    <FormGroup label="Client" inline={inline}>
      <Select
        value={options.filter((option) => option.value === selected)}
        options={options}
        isLoading={loading}
        width={width}
        onChange={(option: any) => {
          dispatch(setClient(option ? option.value : null))
        }}
        placeholder=""
      />
    </FormGroup>
  )
}

export default ClientSelect
