//** @jsxImportSource @emotion/react */
import { FC } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Formik } from 'formik'
import * as Yup from 'yup'
import moment from 'moment'

import { getInvoice, updateInvoice } from 'store/invoices/invoice'
import InvoiceFormContent from './InvoiceFormContent'

const formatDate = (date: string) =>
  !date ? '' : moment(date).format('DD/MM/YYYY')

interface Props {
  invoice: {
    [key: string]: any
  }
}

interface FormValues {
  numero: string
  dateEmission: string
  datePaiement: string | null
  compteur: string
  totalHT: number
  fournisseur: string
  client: number
  typeContrat: number
}

const InvoiceForm: FC<Props> = ({ invoice }) => {
  const dispatch = useDispatch()
  const { loading } = useSelector(getInvoice)

  const initialValues = {
    numero: invoice.numero,
    dateEmission: formatDate(invoice.dateEmission),
    datePaiement: invoice.datePaiement ? formatDate(invoice.datePaiement) : null,
    compteur: invoice.compteur,
    totalHT: invoice.totalHT,
    fournisseur: invoice.codeFournisseur,
    client: invoice.idClientEmcm,
    typeContrat: invoice.typeContrat,
  }

  const SignupSchema = Yup.object().shape({
    numero: Yup.string().required('Requis'),
    dateEmission: Yup.string().required('Requis'),
    datePaiement: Yup.string().nullable(),
    compteur: Yup.string().required('Requis'),
    totalHT: Yup.number().required('Requis'),
    fournisseur: Yup.string().required('Requis'),
    client: Yup.number().required('Requis'),
    typeContrat: Yup.number().required('Requis'),
  })

  return (
    <Formik
      enableReinitialize
      initialValues={initialValues}
      validationSchema={SignupSchema}
      onSubmit={async (values: FormValues, actions: any) => {
        const { lignes, ...rest } = invoice
        const m1 = moment(values.dateEmission, 'DD/MM/YYYY')
        const dateEmission = m1.isValid() ? m1.format('YYYY-MM-DD') : null

        const m2 = moment(values.datePaiement, 'DD/MM/YYYY')
        const datePaiement = m2.isValid() ? m2.format('YYYY-MM-DD') : null

        const payload = {
          ...rest,
          ...values,
          dateEmission,
          datePaiement,
        }

        dispatch(updateInvoice.request({ id: invoice.id, payload }))
        actions.setSubmitting(false)
        // actions.resetForm()
      }}
      render={(props: any) => (
        <InvoiceFormContent {...props} invoice={invoice} loading={loading} />
      )}
    />
  )
}

export default InvoiceForm
