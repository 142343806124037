//** @jsxImportSource @emotion/react */
import { FC, Suspense } from 'react'
import { Spinner } from 'reactstrap'

type Props = {
  height?: string | number
  width?: string | number
}

export type SuspenseBoundaryProps = Props

export const SuspenseBoundary: FC<Props> = ({ width = 'auto', height = '100%', children }) => {
  return (
    <Suspense fallback={<Loader width={width} height={height} />}>
      {children}
    </Suspense>
  )
}

const Loader = ({ width, height }: Props) => {
  return (
    <div
      css={{
        width,
        height,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      <Spinner
        css={{
          width: 40,
          height: 40,
          borderWidth: 6,
          color: 'rgba(0, 199, 147, .8)',
        }}
      />
    </div>
  )
}