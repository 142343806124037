import { createStructuredSelector, createSelector } from 'reselect'

import { RootState } from 'store'
import { State } from './types'

export const getItems = (state: RootState) => state.invoices.pdfs.items
export const getPageSize = (state: RootState) => state.invoices.pdfs.pageSize
export const getPageIndex = (state: RootState) => state.invoices.pdfs.pageIndex
export const getEvaluating = (state: RootState) =>
  state.invoices.pdfs.evaluating
export const getParsingResult = (state: RootState) =>
  state.invoices.pdfs.parsingResult

export const getPdfs = createStructuredSelector<
  RootState,
  Pick<State, 'items' | 'total' | 'errors' | 'loading' | 'error'>
>({
  items: getItems,
  total: (state) => state.invoices.pdfs.total,
  errors: (state) => state.invoices.pdfs.errors,
  loading: (state) => state.invoices.pdfs.loading,
  error: (state) => state.invoices.pdfs.error,
})

export const getPdf = (id: number) =>
  createSelector(getItems, (items: any) => (!items ? null : items[id]))

export const getPrevNext = (id: number) =>
  createSelector(getItems, (pdfs: any) => {
    const items: any = Object.values(pdfs)
    const index = items.findIndex((item: any) => item.id.toString() === id)
    const found = typeof index === 'undefined'
    return {
      prev: found ? null : items[index === 0 ? items.length - 1 : index - 1].id,
      next: found ? null : items[index === items.length - 1 ? 0 : index + 1].id,
    }
  })
