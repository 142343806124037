import { FC } from 'react'
import { ErrorBoundary as ReactErrorBoundary } from 'react-error-boundary'
import { useQueryErrorResetBoundary } from 'react-query'
import { ErrorFallback }from './ErrorFallback'

export const ErrorBoundary: FC = ({ children }) => {
  const { reset } = useQueryErrorResetBoundary()
  return (
    <ReactErrorBoundary onReset={reset} FallbackComponent={ErrorFallback}>
        {children}
    </ReactErrorBoundary>
  )
}
