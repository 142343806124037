//** @jsxImportSource @emotion/react */
import React, { FC } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import isNil from 'lodash/isNil'
import SplitPane from 'react-split-pane'
import { Alert } from 'reactstrap'

import { setInvoice } from 'store/invoices/selection'
import { fetchInvoice, getInvoice } from 'store/invoices/invoice'
import { evaluatePdf, getEvaluating } from 'store/invoices/pdfs'

import { HeaderButton, Section, Pdf, Loader } from 'library'
import InvoiceHeader from './InvoiceHeader'
import InvoiceForm from './InvoiceForm'
import InvoiceLinesForm from './InvoiceLinesForm'

const Details: FC<any> = (props) => {
  const { id } = props.match.params
  const [delta, setDelta] = React.useState(0)

  const dispatch = useDispatch()
  const evaluating = useSelector(getEvaluating)
  const { invoice, loading, error } = useSelector(getInvoice)
  React.useEffect(() => {
    dispatch(fetchInvoice.request(id))
  }, [id, dispatch])

  React.useEffect(() => {
    if (invoice !== null) {
      dispatch(setInvoice(id))
    }
  }, [dispatch, id, invoice])

  if (error) return <Alert color="danger">{error}</Alert>

  return (
    <div style={{ position: 'relative' }}>
      <InvoiceHeader id={Number(id)} />
      <SplitPane
        split="vertical"
        minSize={840}
        maxSize={1200}
        defaultSize={900}
        onDragFinished={(width) => setDelta(width - 900)}
        style={{
          height: 'unset',
          position: 'relative',
          overflow: 'visible',
        }}
        pane1Style={{ margin: 5 }}
        pane2Style={{ margin: 5 }}
      >
        {loading ? (
          <Loader centered />
        ) : invoice && invoice.id === Number(id) ? (
          <Section style={{ padding: 15 }}>
            {invoice.pdfId ? (
              <HeaderButton
                iconId="refresh"
                text="Relancer le parsing"
                color="secondary"
                style={{ margin: 15 }}
                onClick={() => {
                  dispatch(evaluatePdf.request(invoice.pdfId as number))
                }}
                disabled={!!evaluating}
              />
            ) : null}
            <InvoiceForm invoice={invoice} />
            <InvoiceLinesForm invoice={invoice} loading={loading} />
          </Section>
        ) : (
          <div />
        )}

        <div
          style={{
            width: '100%',
            height: 'calc(100vh - 140px)',
            position: 'sticky',
            top: 15,
          }}
        >
          {invoice && !isNil(invoice.pdfId) && invoice.pdfId !== 0 && (
            <Pdf id={invoice.pdfId} delta={delta} className="shadow-sm" />
          )}
        </div>
      </SplitPane>
    </div>
  )
}

export default Details
