import React, { useState, FC } from 'react'
import { Container, Button, FormGroup, Input, Label } from 'reactstrap'

import { Icon } from 'library'
import Security from './Security'
import UserInfos from './UserInfos'

interface Props {
  userPanelOpen: boolean
  setUserPanelOpen: (b: boolean) => void
}

const UserPanel: FC<Props> = ({ userPanelOpen, setUserPanelOpen }) => {
  const [showSecurityPanel, setShowSecurityPanel] = useState('0')

  return (
    <div>
      <Container
        className={['user-panel', userPanelOpen ? 'open' : ''].join(' ')}
        fluid
      >
        <div className="radio-input-btn panel-choice">
          <FormGroup>
            <Input
              id="showUser"
              type="radio"
              name="userLan"
              checked={showSecurityPanel === '0'}
              value="0"
              onChange={(e) => setShowSecurityPanel(e.target.value)}
            />
            <Label for="showUser" className="btn">
              Infos utilisateurs{' '}
            </Label>
          </FormGroup>
          <FormGroup>
            <Input
              id="showSecurity"
              type="radio"
              name="userLan"
              checked={showSecurityPanel === '1'}
              value="1"
              onChange={(e) => setShowSecurityPanel(e.target.value)}
            />
            <Label for="showSecurity" className="btn">
              Sécurité
            </Label>
          </FormGroup>
        </div>
        <Button
          className="close-panel"
          onClick={() => setUserPanelOpen(!userPanelOpen)}
          style={{ cursor: 'default' }}
        >
          <Icon id="cross" />
        </Button>
        {showSecurityPanel === '0' ? (
          <UserInfos
            userPanelOpen={userPanelOpen}
            setUserPanelOpen={setUserPanelOpen}
          />
        ) : (
          <Security />
        )}
      </Container>
      <div
        className="user-panel-overlay"
        onClick={() => setUserPanelOpen(!userPanelOpen)}
      />
    </div>
  )
}

export default UserPanel
