import { useRef, useEffect, useState } from 'react'

const { pdfjsLib: pdfjs } = window

const cleanElement = (el: HTMLElement) => {
  while (el.firstChild) {
    el.removeChild(el.firstChild)
  }
}

const useRenderPage = ({
  page,
  canvas,
  textLayer,
  containerWidth,
  pageWidth,
  scale,
  destroyed,
}: any) => {
  const rendering = useRef(false)
  const [size, setSize] = useState<any>({ width: null, height: null })

  useEffect(() => {
    if (!containerWidth || !pageWidth) return

    const defaultTask = {
      promise: Promise.resolve(),
      cancel: () => {},
    }

    let canvasRenderingTask = defaultTask
    let textRenderingTask = defaultTask

    const render = async () => {
      if (!page || page.destroyed || page.pendingCleanup) return

      try {
        rendering.current = true
        cleanElement(textLayer)

        // Width
        const width = containerWidth * scale

        // Viewport
        const viewportScale = width / pageWidth
        const viewport = page.getViewport({ scale: viewportScale })

        // Height
        const ratio = viewport.width / viewport.height
        const height = width / ratio
        setSize({ width, height })
        canvas.width = width
        canvas.height = height

        if (!destroyed) {
          // console.log(`pdf: rendering page #${page.pageIndex}`)
          const canvasContext = canvas.getContext('2d')
          canvasRenderingTask = page.render({ canvasContext, viewport })

          textRenderingTask = pdfjs.renderTextLayer({
            enhanceTextSelection: true,
            textContent: await page.getTextContent(),
            container: textLayer,
            viewport,
          })
        }

        await Promise.all([
          canvasRenderingTask.promise,
          textRenderingTask.promise,
        ])

        rendering.current = false
      } catch (error) {
        console.log(error)
      }
    }

    render()

    return () => {
      if (rendering.current) {
        // console.log(`pdf: cancelling #${page.pageIndex}`)
        canvasRenderingTask.cancel()
        textRenderingTask.cancel()
      }
    }
  }, [canvas, containerWidth, destroyed, page, pageWidth, scale, textLayer])

  return { ...size }
}

export default useRenderPage
