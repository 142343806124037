//** @jsxImportSource @emotion/react */
import { FC } from 'react'
import range from 'lodash/range'

import useDocument from './useDocument'
import PdfPage from './PdfPage'
import { Loader } from 'library'

const GAP = 10

interface Props {
  data: Uint8Array
  scale: number
  fetching: boolean
  width: number
}

const PdfDocument: FC<Props> = ({ data, scale, fetching, width }) => {
  const { document, loading } = useDocument(data)
  const pageIndexes = !document ? [] : range(1, document.numPages + 1)

  if (fetching || loading) return <Loader centered color="#fff" />

  return (
    <div
      css={{
        display: 'grid',
        gridTemplateColumns: '100%',
        gridGap: GAP,
        padding: GAP,
        '>*': {
          boxShadow: '0px 0px 5px 2px rgba(0, 0, 0, 1)',
        },
      }}
    >
      {pageIndexes.map((index) => (
        <PdfPage
          key={index}
          document={document}
          index={index}
          scale={scale}
          containerWidth={width - 2 * GAP - 10}
        />
      ))}
    </div>
  )
}

export default PdfDocument
