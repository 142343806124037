//** @jsxImportSource @emotion/react */
import { FC } from 'react'
import { ErrorBoundary } from './ErrorBoundary'
import { SuspenseBoundary, SuspenseBoundaryProps } from './SuspenseBoundary'

export const QueryBoundary: FC<SuspenseBoundaryProps> = ({ children, ...props }) => {
  return (
    <ErrorBoundary>
      <SuspenseBoundary {...props}>
        {children}
      </SuspenseBoundary>
    </ErrorBoundary>
  )
}
