import { useDispatch, useSelector } from 'react-redux'
import { Energy } from 'api/types'
import { setEnergy, getEnergy } from 'store/invoices/selection'
import { Select, FormGroup } from 'library'

interface Props {
  inline?: boolean
  width?: number
  showAllOption?: boolean
}

const options = [{ value: Energy.Gaz, label: 'Gaz' }, { value: Energy.Electricite, label: 'Électricté' }]

export const EnergySelect = ({
  inline = false,
  width,
}: Props) => {
  const dispatch = useDispatch()
  const selected = useSelector(getEnergy)

  return (
    <FormGroup label="Commodité" inline={inline}>
      <Select
        value={options.filter((option) => option.value === selected)}
        options={options}
        width={width}
        onChange={(option: any) => {
          dispatch(setEnergy(option ? option.value : null))
        }}
        placeholder=""
      />
    </FormGroup>
  )
}

