//** @jsxImportSource @emotion/react */
import { FC } from 'react'
import { FormGroup, Label, FormFeedback } from 'reactstrap'

import { Select } from 'library'

const options = [
  { value: 1, label: 'Fourniture' },
  { value: 3, label: 'Acheminement' },
  { value: 4, label: 'Unique' },
]

const InvoiceContractSelect: FC<any> = ({
  field,
  form: { touched, errors, setFieldValue },
  label,
  ...props
}) => {
  return (
    <FormGroup css={{ display: 'flex', alignItems: 'center', marginBottom: 0 }}>
      <Label for={props.name} css={{ width: 130, marginBottom: 0 }}>
        {label}
      </Label>

      <Select
        {...field}
        {...props}
        value={options.filter((option) => option.value === field.value)}
        options={options}
        isLoading={false}
        onChange={(option: any) => {
          setFieldValue(field.name, option.value)
        }}
        width={170}
        fontSize={12}
        inputId={field.name}
        invalid={!!errors[field.name]}
        placeholder=""
      />

      {touched[field.name] && errors[field.name] && (
        <FormFeedback
          css={{ width: 90, margin: '0 0 0 10px', display: 'block' }}
        >
          {errors[field.name]}
        </FormFeedback>
      )}
    </FormGroup>
  )
}

export default InvoiceContractSelect
