import { Reducer, AnyAction } from 'redux'
import types from './types'

interface UserState {
  userInfo: any
  userPostError: string
  userPostSuccess: boolean
}

const initialState: UserState = {
  userInfo: null,
  userPostError: '',
  userPostSuccess: false,
}

const userReducer: Reducer<UserState, AnyAction> = (
  state = initialState,
  action
) => {
  switch (action.type) {
    case types.USER_INFO_SUCCESS:
      return {
        ...state,
        userInfo: action.user,
        userPostError: '',
        userPostSuccess: false,
      }
    case types.USER_FORM_POST_SUCCESS:
      return {
        userInfo: action.user,
        userPostError: '',
        userPostSuccess: true,
      }
    case types.CHANGE_PASSWORD_REQUEST:
    case types.CHANGE_PASSWORD_SUCCESS:
      return {
        ...state,
        userPostError: '',
        userPostSuccess: true,
      }
    case types.USER_FORM_UPDATE:
    case types.USER_FORM_POST_REQUEST:
      return {
        ...state,
        userPostError: '',
        userPostSuccess: false,
      }
    case types.USER_FORM_RESET:
      return {
        ...state,
        userPostError: '',
        userPostSuccess: false,
      }
    case types.USER_FORM_POST_FAILURE:
    case types.CHANGE_PASSWORD_FAILURE:
      return {
        ...state,
        userPostError: action.message || 'Something went wrong',
        userPostSuccess: false,
      }
    case 'RESET_ALL':
    case 'USER_LOGOUT_SUCCESS':
      return initialState
    default:
      return state
  }
}

export default userReducer
