import { useState, useEffect } from 'react'
import { apiUrl } from 'helpers/env'
import { version } from '../../package.json'

const fetchVersion = async () => {
  const headers = new Headers()
  headers.append('Content-Type', 'application/json')
  const url = `${apiUrl()}version`
  const res = await fetch(url, {
    credentials: 'include',
    headers,
  })
  return res.json()
}

const Version = () => {
  const [backVersion, setBackVersion] = useState('')

  useEffect(() => {
    const wsCall = async () => {
      const versionNumber = await fetchVersion()
      setBackVersion(versionNumber.version)
    }
    wsCall()
  }, [])

  return <div>{`${backVersion} / ${version}`}</div>
}

export default Version
