//** @jsxImportSource @emotion/react */
import { useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import moment from 'moment'
import { Alert } from 'reactstrap'
import { RowRenderProps } from 'react-table'
import { withRouter } from 'react-router'
import { Trans } from 'react-i18next'

import { SelectTable, CellButton, Loader, HeaderButton } from 'library'
import { StatusCell } from './Status'
import PdfModal from './PdfModal'
import { getProviderLabel } from 'store/invoices/providers'
import { getClientLabel } from 'store/invoices/clients'
import {
  getPdfs,
  fetchPdfs,
  setPageSize,
  getPageSize,
  setPageIndex,
  getPageIndex,
  deletePdf,
  deletePdfs,
  reparsePdfs,
  evaluatePdf,
  getEvaluating,
} from 'store/invoices/pdfs'
import FileIcon from '../FileIcon'
import useBoolean from 'hooks/useBoolean'
import EditButton from './EditButton'

const formatDate = (date: string, format: string = 'DD/MM/YYYY') => {
  const m = moment(date)
  return m.isValid() ? m.format(format) : null
}

const errorLabel = {
  ERR_NO_TPL: 'Aucun template associé à cette facture',
  ERR_PARSE_JSON: 'Impossible de lire le JSON',
  ERR_PDF_CREATE: "Erreur lors de l'ajout du PDF en base",
  ERR_FAC_CREATE: "Erreur lors de l'ajout dans FAC",
  ERR_MAPPER: 'Erreur de mapping JSON -> Objet Fac',
  ERR_SELECT_MAPPER: 'Aucun mapper trouvé',
}

interface Row {
  value:
    | 'ERR_NO_TPL'
    | 'ERR_PARSE_JSON'
    | 'ERR_PDF_CREATE'
    | 'ERR_FAC_CREATE'
    | 'ERR_MAPPER'
    | 'ERR_SELECT_MAPPER'
}

const ProviderCell = ({ value }: RowRenderProps) => {
  const label = useSelector(getProviderLabel(value))
  return <div>{label}</div>
}

const ClientCell = ({ value }: RowRenderProps) => {
  const label = useSelector(getClientLabel(value))
  return <div>{label}</div>
}

const PdfsTable = ({ history }: any) => {
  const [selection, setSelection] = useState<string[]>([])
  const dispatch = useDispatch()
  const { items, loading, error, total } = useSelector(getPdfs)
  const pageSize = useSelector(getPageSize)
  const pageIndex = useSelector(getPageIndex)
  const [isModalOpen, toggleModal] = useBoolean(false)
  const [index, setIndex] = useState<number>(0)
  const evaluating = useSelector(getEvaluating)

  const data = !items ? [] : Object.values(items)

  useEffect(() => {
    if (!items) dispatch(fetchPdfs.request())
  }, [dispatch, items])

  const columns = [
    {
      Header: 'Fournisseur',
      accessor: 'providerCode',
      width: 90,
      Cell: ProviderCell,
    },
    {
      Header: 'Client',
      accessor: 'customerEmcmId',
      width: 120,
      Cell: ClientCell,
    },
    {
      Header: 'Numéro',
      accessor: 'invoiceNumber',
    },
    {
      Header: 'Compteur',
      accessor: 'externalId',
    },
    {
      filterable: false,
      Header: 'Fichier',
      accessor: 'fileName',
      width: 350,
      Cell: (row: RowRenderProps) => (
        <div>
          {<FileIcon isPdf={true} filename={row.value} />}
          {row.value}
        </div>
      ),
    },
    {
      filterable: true,
      Header: 'Total HT',
      width: 80,
      accessor: 'amount',
      Cell: (row: RowRenderProps) => <span>{row.value}</span>,
    },
    {
      filterable: false,
      Header: 'Émission',
      width: 100,
      accessor: 'invoiceDate',
      Cell: (row: RowRenderProps) => <span>{formatDate(row.value)}</span>,
    },
    {
      filterable: false,
      width: 100,
      Header: 'Created',
      accessor: 'created',
      Cell: (row: RowRenderProps) => <span>{formatDate(row.value)}</span>,
    },
    {
      filterable: false,
      width: 150,
      Header: 'Updated',
      accessor: 'updated',
      Cell: (row: RowRenderProps) => (
        <span>{formatDate(row.value, 'DD/MM/YYYY HH:mm:ss')}</span>
      ),
    },
    {
      filterable: false,
      width: 150,
      Header: 'État',
      accessor: 'status',
      Cell: StatusCell,
    },
    {
      filterable: false,
      width: 110,
      Header: 'Template',
      accessor: 'templateName',
    },
    {
      filterable: false,
      Header: 'Erreur',
      accessor: 'errorCode',
      width: 100,
      Cell: (row: Row) => {
        const label = errorLabel[row.value]
        return <div title={label}>{row.value}</div>
      },
    },
    {
      filterable: false,
      width: 140,
      Header: 'Actions',
      Cell: (row: RowRenderProps) => {
        const {
          index,
          original: { id, status },
        } = row

        return (
          <div>
            <CellButton
              iconId="open"
              style={{ margin: 0 }}
              title="Ouvrir le fichier"
              onClick={() => {
                if (typeof index === 'undefined') return
                setIndex(index)
                toggleModal()
              }}
            />
            {evaluating === id ? (
              <span
                style={{ padding: '0 6.4px', top: 1, position: 'relative' }}
              >
                <Loader size={14} width={4} />
              </span>
            ) : (
              <CellButton
                iconId="refresh"
                style={{ margin: 0 }}
                title="Relancer le parsing"
                onClick={() => {
                  dispatch(evaluatePdf.request(id))
                }}
              />
            )}
            <CellButton
              iconId="trash"
              style={{ margin: 0 }}
              title="Supprimer"
              onClick={() => {
                dispatch(deletePdf.request(id))
              }}
            />
            {status === 8 && <EditButton id={id} />}
          </div>
        )
      },
    },
  ]

  return (
    <div>
      {error && <Alert color="danger">{error}</Alert>}
      <SelectTable
        columns={columns}
        manual
        data={data}
        page={pageIndex}
        pages={Math.ceil(total / pageSize)}
        loading={loading}
        showPagination={true}
        showPageSizeOptions={true}
        showPageJump={true}
        filterable={true}
        sortable={true}
        minRows={10}
        defaultPageSize={pageSize}
        pageSizeOptions={[10, 15, 20, 25, 50, 75, 100, 500]}
        onPageSizeChange={(pageSize, pageIndex) => {
          dispatch(setPageSize(pageSize))
          dispatch(setPageIndex(pageIndex))
          dispatch(fetchPdfs.request())
        }}
        onPageChange={(pageIndex) => {
          dispatch(setPageIndex(pageIndex))
          dispatch(fetchPdfs.request())
        }}
        style={{ fontSize: 12 }}
        keyField="id"
        selection={selection}
        setSelection={setSelection}
      />

      {selection.length > 0 && (
        <>
          <div style={{ marginTop: '1rem', lineHeight: 1 }}>
            <Trans i18nKey="pdfs.suppression" count={selection.length}>
              Supprimer les <strong>{{ count: selection.length }}</strong> PDFs
            </Trans>
            <HeaderButton
              iconId="trash"
              text="Supprimer"
              color="danger"
              style={{ marginLeft: '1rem', padding: '0.25rem 0.5rem' }}
              onClick={async () => {
                try {
                  const ids = selection.map((item) =>
                    Number(item.replace('select-', ''))
                  )
                  await dispatch(deletePdfs.request(ids))
                  setSelection([])
                } catch (error) {
                  console.log(error)
                }
              }}
            />
          </div>
          <div style={{ marginTop: '1rem', lineHeight: 1 }}>
            <Trans i18nKey="pdfs.reparse" count={selection.length}>
              Reparser les <strong>{{ count: selection.length }}</strong> PDFs
            </Trans>
            <HeaderButton
              iconId="refresh"
              text="Reparser"
              color="danger"
              style={{ marginLeft: '1rem', padding: '0.25rem 0.5rem' }}
              onClick={async () => {
                try {
                  const ids = selection.map((item) =>
                    Number(item.replace('select-', ''))
                  )
                  await dispatch(reparsePdfs.request(ids))
                  setSelection([])
                } catch (error) {
                  console.log(error)
                }
              }}
            />
          </div>
        </>
      )}

      {data.length > 0 && (
        <PdfModal
          isOpen={isModalOpen}
          toggle={toggleModal}
          pdfId={data[index].id}
          pdfName={data[index].fileName}
        />
      )}
    </div>
  )
}

export default withRouter(PdfsTable)
