//** @jsxImportSource @emotion/react */
import { FC } from 'react'
import { Table } from 'reactstrap'

import FileIcon from '../FileIcon'

interface Result {
  name: string
  templateName: string
  errorType: string
  errorMessage: string
}

interface Props {
  results: Result[]
}

const ImportResult: FC<Props> = ({ results }) => {
  return (
    <Table size="sm" striped bordered css={{ fontSize: 14 }}>
      <thead>
        <tr>
          <th>Fichier</th>
          <th>Template ID</th>
          <th>Type d'erreur</th>
          <th>Message d'erreur</th>
        </tr>
      </thead>
      <tbody>
        {results.map(({ name, templateName, errorType, errorMessage }) => (
          <tr key={name}>
            <td>
              <FileIcon filename={name} />
              <span>{name}</span>
            </td>
            <td>{templateName}</td>
            <td>{errorType}</td>
            <td>{errorMessage}</td>
          </tr>
        ))}
      </tbody>
    </Table>
  )
}

export default ImportResult
