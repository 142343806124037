import { FallbackProps } from 'react-error-boundary'

export const ErrorFallback = ({ error, resetErrorBoundary }: FallbackProps) => {
  return (
    <div
      role="alert"
      className="shadow-sm border border-danger rounded p-3"
      css={{
        flex: 'none',
        minWidth: '20rem',
        maxWidth: '40rem',
        backgroundColor: '#ff466922',
      }}
    >
      <p className="font-weight-bold">{"Une erreur s'est produite."}</p>

      {error && (
        <p css={{ overflowWrap: 'break-word' }}>
          {error.message || (error as any).Message}
        </p>
      )}

      <button className="btn btn-danger" onClick={resetErrorBoundary}>
        Recommencer
      </button>
    </div>
  )
}
