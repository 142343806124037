//** @jsxImportSource @emotion/react */
import { FC } from 'react'
import { useDispatch } from 'react-redux'
import { FormGroup, Label, Input, FormFeedback } from 'reactstrap'
import { withRouter } from 'react-router-dom'

import { CellButton } from 'library'
import { setNewItem } from 'store/invoices/pdl'

const InvoiceInput: FC<any> = ({
  field,
  form: { touched, errors },
  formatter = (x: any) => x,
  label,
  history,
  staticContext,
  location,
  match,
  ...props
}) => {
  const dispatch = useDispatch()
  return (
    <FormGroup css={{ display: 'flex', alignItems: 'center', marginBottom: 0 }}>
      <Label for={props.name} css={{ width: 130, marginBottom: 0 }}>
        {label}
      </Label>

      <Input
        type="text"
        bsSize="sm"
        {...field}
        {...props}
        value={formatter(field.value) || ''}
        invalid={!!errors[field.name]}
        css={{ width: 170 }}
      />

      {field.name === 'compteur' && (
        <CellButton
          iconId="parameters"
          onClick={() => {
            dispatch(setNewItem({ label: field.value }))
            history.push('/factures/admin')
          }}
          style={{ margin: '0 0 0 5px' }}
        />
      )}

      {touched[field.name] &&
        errors[field.name] &&
        field.name !== 'compteur' && (
          <FormFeedback css={{ width: 90, margin: '0 0 0 10px' }}>
            {errors[field.name]}
          </FormFeedback>
        )}
    </FormGroup>
  )
}

export default withRouter(InvoiceInput)
