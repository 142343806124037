//** @jsxImportSource @emotion/react */
import { connect } from 'react-redux'
import NotificationSystem from 'react-notification-system-redux'

import { RootState } from 'store'

interface Props {
  notifications: Notification[]
}

const Notifications = (props: Props) => {
  return (
    <NotificationSystem
      notifications={props.notifications}
      style={{
        NotificationItem: {
          success: {
            color: '#00c793',
          },
          error: {
            color: '#ff4669',
          },
        },
      }}
    />
  )
}

const mapState = (state: RootState) => ({
  notifications: state.notifications,
})

export default connect(mapState)(Notifications)
