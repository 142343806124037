//** @jsxImportSource @emotion/react */
import { FC, useRef, useEffect } from 'react'
import { Form, Field, FormikProps } from 'formik'

import { HeaderButton } from 'library'
import InvoiceInput from './InvoiceInput'
import InvoiceDateInput from './InvoiceDateInput'
import InvoiceTotalInput from './InvoiceTotalInput'
import InvoiceProviderSelect from './InvoiceProviderSelect'
import InvoiceClientSelect from './InvoiceClientSelect'
import InvoiceContractSelect from './InvoiceContractSelect'
import { errorsInvoice } from 'labels'

const energies: any = {
  ELE: 'Éléctricité',
  GAZ: 'Gaz',
  H20: 'Eau',
}

const Item: FC<{ label: string; value: string; valueStyle?: object }> = ({
  label,
  value = '-',
  valueStyle = {},
}) => {
  return (
    <div css={{ display: 'flex', alignItems: 'center', minHeight: 33 }}>
      <div css={{ width: 130 }}>{label}</div>
      <div style={{ width: 250, ...valueStyle }}>{value}</div>
    </div>
  )
}

interface Props {
  invoice: any
  loading: boolean
}

const InvoiceFormContent: FC<Props & FormikProps<any>> = (props) => {
  const { invoice } = props

  const ref = useRef<any>(null)
  useEffect(() => {
    if (ref.current !== null) {
      ref.current.focus()
      setTimeout(() => {
        if (ref.current !== null) ref.current.blur()
      }, 100)
    }
  }, [invoice.id])

  return (
    <Form css={{ marginBottom: 30, padding: 15 }}>
      <div
        css={{
          fontSize: 14,
          display: 'grid',
          gridTemplateColumns: 'repeat(2, 400px)',
          gridGap: 10,
          marginBottom: 20,
          opacity: props.loading ? 0.5 : 1,
          pointerEvents: props.loading ? 'none' : 'unset',
        }}
      >
        <Item
          label="État"
          value={errorsInvoice[invoice.errorCode]}
          {...(errorsInvoice[invoice.errorCode] && {
            valueStyle: { color: '#ff4669', fontWeight: 'bold' },
          })}
        />

        {invoice.pdfId ? (
          <Item label="Template" value={invoice.templatePdf || '-'} />
        ) : (
          <div />
        )}
        <Item label="Energie" value={energies[invoice.energie]} />
        <Item label="Pays" value={invoice.pays} />
        <Field
          name="fournisseur"
          type="text"
          label="Fournisseur"
          component={InvoiceProviderSelect}
        />
        <Field
          name="numero"
          type="string"
          label="Numéro"
          component={InvoiceInput}
          innerRef={ref}
        />
        <Field name="client" label="Client" component={InvoiceClientSelect} />

        <Field
          name="compteur"
          type="text"
          label="Compteur"
          component={InvoiceInput}
        />
        <Field
          name="dateEmission"
          label="Date d'émission"
          component={InvoiceDateInput}
        />

        <Field
          name="totalHT"
          label="Montant HT"
          component={InvoiceTotalInput}
        />
        <Field
          name="datePaiement"
          label="Date de paiement"
          component={InvoiceDateInput}
        />

        <Field
          name="typeContrat"
          type="number"
          label="Type de contrat"
          component={InvoiceContractSelect}
        />
      </div>

      <HeaderButton
        type="submit"
        disabled={props.isSubmitting || !props.dirty || !props.isValid}
        iconId="ok"
        text="Modifier"
        color="primary"
      />
    </Form>
  )
}

export default InvoiceFormContent
