import keyBy from 'lodash/keyBy'
import get from 'lodash/get'

import { superFetch, checkStatus } from 'helpers/apiConf'
import { Pdf } from './types'

export const fetchPdfs = async (
  pageIndex: number,
  pageSize: number,
  provider: string | null,
  client: number | null,
  startDate: string | null,
  endDate: string | null,
  pdl: string | null,
  createdDate: string | null,
  updatedDate: string | null,
  invoiceNumber: string | null,
  status: string[]
) => {


  if (startDate === '') startDate = null
  if (endDate === '') endDate = null
  if (createdDate === '') createdDate = null
  if (updatedDate === '') updatedDate = null

  const response = await superFetch({
    url: `pdfs/search?offset=${pageIndex * pageSize}&limit=${pageSize}`,
    method: 'POST',
    body: {
      Supplier: provider,
      Account: client,
      Debut: startDate,
      Fin: endDate,
      DateCreation: createdDate,
      DateMaj: updatedDate,
      InvoiceNumber: invoiceNumber,
      Status: status,
      ...(pdl && { Meter: pdl }),
    },
    invoiceApi: true,
  })

  checkStatus(response)
  const data = await response.json()

  return {
    total: data.page.total,
    errors: data.page.errors,
    items: keyBy(data.items, 'id'),
  }
}

export const deletePdf = async (id: number) => {
  const response = await superFetch({
    url: `pdfs/${id}`,
    method: 'DELETE',
    invoiceApi: true,
  })
  checkStatus(response)
}

export const deletePdfs = async (ids: number[]) => {
  console.log('ids', ids)
  const response = await superFetch({
    url: `pdfs`,
    method: 'DELETE',
    invoiceApi: true,
    body: ids,
  })
  checkStatus(response)
}

export const reparsePdfs = async (ids: number[]) => {
  console.log('ids', ids)
  const response = await superFetch({
    url: `pdfs/reparse`,
    method: 'POST',
    invoiceApi: true,
    body: ids,
  })
  checkStatus(response)
}

export const repasePdfs = async (ids: number[]) => {
  console.log('ids', ids)
  const response = await superFetch({
    url: `pdfs`,
    method: 'DELETE',
    invoiceApi: true,
    body: ids,
  })
  checkStatus(response)
}

export const evaluatePdf = async (id: number) => {
  const response = await superFetch({
    url: `pdfs/${id}/evaluate`,
    method: 'POST',
    invoiceApi: true,
  })

  checkStatus(response)
  const data = await response.json()

  const parsingResult = get(data, 'factures.0.parsingResult', null)
  if (!parsingResult) return null

  const name = get(data, 'factures.0.name', null)
  const { templateName, errorType, errorMessage } = parsingResult
  return { name, templateName, errorType, errorMessage }
}

export const updatePdf = async (payload: Pdf) => {
  const response = await superFetch({
    url: `pdfs/${payload.id}`,
    method: 'PUT',
    invoiceApi: true,
    body: payload,
  })
  checkStatus(response)
}

export const forceDownloadPdf = async (payload: Pdf) => {
  const response = await superFetch({
    url: `pdfs/${payload.id}/forcedownload`,
    method: 'POST',
    invoiceApi: true,
  })
  checkStatus(response)
}
