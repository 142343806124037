//** @jsxImportSource @emotion/react */
import { FC, useState, useRef } from 'react'

import useWidth from 'hooks/useWidth'
import useFetch from 'hooks/useFetch'
import PdfControls from './PdfControls'
import PdfDocument from './PdfDocument'
import texture from 'assets/images/pdf/texture.png'

interface Props {
  id: number
  width?: number
  delta?: number
  className?: string
}

const Pdf: FC<Props> = ({ id, width, delta = 0, className = '' }) => {
  const containerRef = useRef(null)
  const containerWidth = useWidth(containerRef.current)
  const pdfWidth = width
    ? width - delta
    : containerWidth
    ? containerWidth - delta
    : null

  const { data, loading, error, fileName } = useFetch(
    `pdfs/${id}`,
    'arrayBuffer'
  )
  const [scale, setScale] = useState(1)

  return (
    <div
      ref={containerRef}
      css={{
        width: pdfWidth || `calc(100% + ${delta}px)`,
        height: '100%',
      }}
      className={className}
    >
      <PdfControls
        scale={scale}
        setScale={setScale}
        fileName={fileName}
        data={data}
      />
      <div
        css={{
          backgroundColor: '#404040',
          backgroundImage: `url(${texture})`,
          width: '100%',
          height: 'calc(100% - 32px)',
          overflow: 'auto',
        }}
      >
        {error ? (
          <div css={{ padding: '1rem', color: '#fff' }}>{error}</div>
        ) : pdfWidth ? (
          <PdfDocument
            data={data}
            scale={scale}
            width={pdfWidth}
            fetching={loading}
          />
        ) : null}
      </div>
    </div>
  )
}

export default Pdf
