//** @jsxImportSource @emotion/react */
import { FC } from 'react'
import { Input } from 'reactstrap'
import Datetime from 'react-datetime'
import moment from 'moment'

const formatDate = (date: string) => {
  const m = moment(date, moment.ISO_8601)
  return m.isValid() ? m.format('DD/MM/YYYY') : date
}

const CellDateInput: FC<any> = ({
  field,
  form: { errors, setFieldValue, setFieldTouched },
  ...props
}) => {
  return (
    <Datetime
      locale="fr"
      closeOnSelect
      timeFormat={false}
      dateFormat="DD/MM/YYYY"
      viewMode="days"
      value={formatDate(field.value) || ''}
      onChange={(x: any) => {
        setFieldValue(
          field.name,
          typeof x === 'string' ? x : x.format('YYYY-MM-DD')
        )
      }}
      // onBlur={() => {
      //   setFieldTouched(field.name)
      // }}
      inputProps={{
        name: field.name,
      }}
      renderInput={(props: any) => (
        <Input
          {...props}
          bsSize="sm"
          invalid={!!errors[field.name]}
          css={{
            padding: '2px 5px',
            borderRadius: 0,
            height: 25,
            marginTop: 3,
          }}
        />
      )}
      {...props}
    />
  )
}

export default CellDateInput
