//** @jsxImportSource @emotion/react */
import { FC } from 'react'

interface Props {
  id: string
  style?: object
  size?: number
  color?: string
}

const Icon: FC<Props> = (props) => (
  <svg
    className={`icon-${props.id}`}
    css={(theme: any) => ({
      width: props.size,
      height: props.size,
      verticalAlign: 'baseline',
      position: 'relative',
      ...props.style,
      ...(props.color && {
        color: theme.colors[props.color],
      }),
    })}
  >
    <use xlinkHref={`#icon-${props.id}`}></use>
  </svg>
)

Icon.defaultProps = {
  size: 18,
}

export default Icon
