//** @jsxImportSource @emotion/react */
import { useReducer, useCallback } from 'react'
import { useSelector } from 'react-redux'

import { superFetch } from 'helpers/apiConf'
import { Alert } from 'library'
import { getSelection } from 'store/invoices/selection'
import Dropzone from './Dropzone'
import ImportResult from './ImportResult'

const isSuccess: (status: number) => boolean = (status) =>
  status >= 200 && status < 300

const initialState = {
  data: null,
  loading: false,
  error: null,
}

interface State {
  data: any | null
  loading: boolean
  error: string | null
}

type Action =
  | { type: 'request' }
  | { type: 'success'; data: any }
  | { type: 'failure'; msg: string }

interface Result {
  name: string
  parsingResult: {
    templateName: string
    errorType: string
    errorMessage: string
  }
}

function reducer(state: State, action: Action): State {
  switch (action.type) {
    case 'request':
      return {
        data: null,
        loading: true,
        error: null,
      }
    case 'success':
      return {
        data: action.data,
        loading: false,
        error: null,
      }
    case 'failure':
      return {
        data: null,
        loading: false,
        error: action.msg,
      }
    default:
      return state
  }
}

export const ImportPdf = () => {
  const { provider, client } = useSelector(getSelection)

  const [{ data, loading, error }, dispatch] = useReducer(
    reducer,
    initialState
  )

  const onDrop = useCallback(
    (acceptedFiles: any[]) => {
      const formData = new FormData()
      for (const file of acceptedFiles) {
        formData.append('file', file, file.name)
      }

      const fetch = async () => {
        try {
          dispatch({ type: 'request' })
          const res = await superFetch({
            url: `pdfs?customer=${client}&supplier=${provider}`,
            method: 'POST',
            invoiceApi: true,
            body: formData,
            contentType: null,
            stringify: false,
          })
          const { status, type, statusText } = res
          if (!isSuccess(status))
            throw new Error(
              `${status}${type && ` (${type})`}${statusText &&
                ` - ${statusText}`}`
            )

          const data = await res.json()

          dispatch({ type: 'success', data })
        } catch (error) {
          dispatch({ type: 'failure', msg: String(error) })
        }
      }
      fetch()
    },
    [client, provider]
  )

  const results = data
    ? data.map(
        ({
          name,
          parsingResult: { templateName, errorType, errorMessage },
        }: Result) => ({ name, templateName, errorType, errorMessage })
      )
    : null

  return (
    <div>
      <h5 style={{ marginBottom: '2rem' }}>PDF</h5>

      <Dropzone
        onDrop={onDrop}
        style={{ margin: '0 0 2rem' }}
        loading={loading}
        mimeTypes={[
          'application/pdf',
          'application/vnd.ms-excel',
          'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
        ]}
      />

      {error && <Alert>{error}</Alert>}
      {results && <ImportResult results={results} />}
    </div>
  )
}