import { css } from '@emotion/react'
// import { Theme } from 'styles/theme'

const global = () => css`
  ::-webkit-scrollbar {
    width: 0.6rem;
    height: 0.6rem;
  }

  ::-webkit-scrollbar-thumb {
    background: #fff;
    /* border-radius: 10px; */
    box-shadow: inset 0 0 2px rgba(0, 0, 0, 0.2);
  }

  ::-webkit-scrollbar-track {
    background: rgba(0, 0, 0, 0.2);
    box-shadow: inset 0 0 3px rgba(0, 0, 0, 0.2);
  }

  [class^='icon-'],
  [class*=' icon-'] {
    height: 20px;
    width: 20px;
    display: inline-block;
    fill: currentColor;
  }

  body {
    font-family: Muli, 'Helvetica Neue', Arial, sans-serif;
    font-size: 16px;
    background-color: #f3f7f9;
    color: #61687f;
  }

  main {
    display: grid;
    grid-template-columns: 100%;
    grid-gap: 20px;
    padding: 20px;
  }

  h5 {
    margin-bottom: 1rem;
  }
`

export default global