//** @jsxImportSource @emotion/react */
import { Fragment } from 'react'
import { Switch, Route, Redirect, NavLink } from 'react-router-dom'
import { Nav, NavItem } from 'reactstrap'

import PageLayout from 'components/PageLayout'
import FacImport from 'components/factures/import/Import'
import FacSuivi from 'components/factures/suivi/Suivi'
import FacPdf from 'components/factures/pdf/Pdfs'
import FacDetails from 'components/factures/details/Details'
import FacAdmin from 'components/factures/admin/Admin'
import Notifications from 'components/Notifications'
import Crawler from 'pages/Crawler'
import { Icon } from 'library'
import { FaSpider as CrawlerIcon } from 'react-icons/fa'

const AppLayout = () => {
  return (
    <Fragment>
      <div
        css={{
          display: 'flex',
          position: 'fixed',
          width: '100%',
        }}
      >
        <div
          css={{
            width: 65,
            height: '100vh',
            backgroundColor: '#2E313D',
            flex: 'none',
          }}
        >
          <Nav vertical>
            <NavItem
              css={{
                a: {
                  textDecoration: 'none',
                  display: 'block',
                  padding: '16px 16px',
                  color: 'rgba(255, 255, 255, .75)',
                  textAlign: 'center',
                  '&:hover': {
                    color: '#fff',
                  },
                  '&.active': {
                    color: '#fff',
                    backgroundColor: '#373A49',
                  },
                },
              }}
            >
              <NavLink to="/factures" activeClassName="active">
                <Icon
                  id="pdf"
                  size={24}
                  style={{
                    top: 3,
                  }}
                />
              </NavLink>
            </NavItem>
          </Nav>
        </div>
        <div
          css={{
            flex: 1,
          }}
        >
          <Switch>
            <Route
              exact
              path="/"
              render={() => <Redirect to="/factures/import" push />}
            />
            <Route
              path="/factures"
              render={({ match }) => (
                <PageLayout
                  url={match.url}
                  path={match.path}
                  redirect="import"
                  routes={[
                    {
                      id: 'import',
                      label: 'Import',
                      icon: 'upload',
                      component: FacImport,
                    },
                    {
                      id: 'pdf',
                      label: 'PDFs',
                      icon: 'pdf',
                      component: FacPdf,
                    },
                    {
                      id: 'suivi',
                      label: 'Suivi',
                      icon: 'dashboard',
                      component: FacSuivi,
                    },
                    {
                      id: 'crawler',
                      label: 'Crawler',
                      icon: <CrawlerIcon />,
                      component: Crawler,
                    },
                    {
                      id: 'details/:id',
                      label: 'Details',
                      icon: 'edit',
                      component: FacDetails,
                    },
                    {
                      id: 'admin',
                      label: 'Administration',
                      icon: 'parameters',
                      component: FacAdmin,
                    },
                  ]}
                />
              )}
            />
          </Switch>
        </div>
      </div>
      <Notifications />
    </Fragment>
  )
}

export default AppLayout
