//** @jsxImportSource @emotion/react */
import { FC } from 'react'
import { useSelector } from 'react-redux'
import { Link } from 'react-router-dom'

import { Icon } from 'library'
import { getPrevNext } from 'store/invoices/invoices'
import InvoiceStatus from './InvoiceStatus'

interface Props {
  id: number
}

const InvoiceHeader: FC<Props> = ({ id }) => {
  const { prev, next } = useSelector(getPrevNext(id))

  return (
    <header
      css={{
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        marginBottom: 20,
      }}
    >
      {prev && prev !== id ? (
        <Link
          to={`/factures/details/${prev}`}
          className="btn btn-light"
          css={{
            fontSize: 14,
            svg: {
              position: 'relative',
              top: 2,
              marginRight: '.6rem',
            },
          }}
        >
          <Icon id="bigarrow-left" size={14} />
          Facture précédente
        </Link>
      ) : (
        <div />
      )}

      <InvoiceStatus />

      {next && next !== id ? (
        <Link
          to={`/factures/details/${next}`}
          className="btn btn-light"
          css={{
            fontSize: 14,
            svg: {
              position: 'relative',
              top: 2,
              marginLeft: '.6rem',
            },
          }}
        >
          Facture suivante
          <Icon id="bigarrow-right" size={14} />
        </Link>
      ) : (
        <div />
      )}
    </header>
  )
}

export default InvoiceHeader
