import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { Form, FormGroup, Input, Label, Button } from 'reactstrap'
import Notifications from 'react-notification-system-redux'

import { changePassword, postSuccess, postError } from 'store/user'

const notifySuccess = Notifications.success({
  title: 'Your profile information has been saved successfully !',
  position: 'bl',
  autoDismiss: 4,
})

const notifyError = Notifications.error({
  title: 'Something went wrong !',
  position: 'bl',
  autoDismiss: 4,
})

const Security = () => {
  const { t } = useTranslation()

  const dispatch = useDispatch()
  const changePasswordSuccess = useSelector(postSuccess)
  const changePasswordError = useSelector(postError)

  const [currentPassword, updatePassword] = useState('')
  const [newPassword, updateNewPassword] = useState('')
  const [newPasswordConfirmation, updateNewPasswordConfirmation] = useState('')

  useEffect(() => {
    if (changePasswordSuccess) dispatch(notifySuccess)
  }, [changePasswordSuccess, dispatch])

  useEffect(() => {
    if (changePasswordError) dispatch(notifyError)
  }, [changePasswordError, dispatch])

  return (
    <Form
      className="user-infos user-security"
      onSubmit={(e) => {
        e.preventDefault()
        dispatch(
          changePassword({
            Password: currentPassword,
            NewPassword: newPassword,
            NewPasswordConfirm: newPasswordConfirmation,
          })
        )
      }}
    >
      <div className="panel-flex">
        <FormGroup className="text-input">
          <Input
            id="currentPassword"
            value={currentPassword}
            type="password"
            onChange={(e) => updatePassword(e.target.value)}
            className={currentPassword === '' ? '' : 'has-value'}
          />
          <Label for="currentPassword">
            {t('user.security.currentPassword')}
          </Label>
        </FormGroup>
        <FormGroup className="text-input">
          <Input
            id="newPassword"
            value={newPassword}
            type="password"
            onChange={(e) => updateNewPassword(e.target.value)}
            className={newPassword === '' ? '' : 'has-value'}
          />
          <Label for="newPassword">{t('user.security.newPassword')}</Label>
        </FormGroup>
        <FormGroup className="text-input">
          <Input
            id="newPasswordConfirm"
            value={newPasswordConfirmation}
            type="password"
            onChange={(e) => updateNewPasswordConfirmation(e.target.value)}
            className={newPasswordConfirmation === '' ? '' : 'has-value'}
          />
          <Label for="newPasswordConfirm">
            {t('user.security.newPasswordConfirmation')}
          </Label>
        </FormGroup>
      </div>

      <div className="panel-footer d-flex">
        <div className="ml-auto">
          <div className="push-right">
            <Button
              type="submit"
              color="primary"
              disabled={
                currentPassword === '' ||
                newPassword === '' ||
                newPasswordConfirmation === ''
              }
              style={{ cursor: 'default' }}
            >
              {t('user.userInfo.save')}
            </Button>
          </div>
        </div>
      </div>
    </Form>
  )
}

export default Security
