import { useDispatch, useSelector } from 'react-redux'
import isEqual from 'lodash/isEqual'

import { setErrorCodes, getErrorCodes } from 'store/invoices/selection'
import { Select, FormGroup } from 'library'
import { errorsInvoice } from 'labels'


// '01' : 'Identifiant client manquant'
// '02' : 'Code fournisseur manquant'
// '03' : 'Total HT manquant'
// '04' : 'Date d''émission de facture manquante'
// '05' : 'Le total HT est différent de la somme des lignes de facture'
// '06' : 'Problème sur une ligne de facture'
// '07' : 'Type de contrat manquant'
// '08' : 'Compte de facturation introuvable'
// '09' : 'Plusieurs comptes de facturation pour cette facture'
// '10' : 'Pas de modèle de facturation sur le template'
const options = [
  { value: [], label: 'Tous' }, 
  ...['01', '02', '03', '04', '05', '06', '07', '08', '09', '10'].map((id) => ({
    value: [id],
    label: errorsInvoice[id],
  })),
]

const StatusErrorSelect = () => {
  const dispatch = useDispatch()
  const selected = useSelector(getErrorCodes)

  return (
    <FormGroup label="Code erreur facture">
      <Select
        value={options.filter((option) => isEqual(option.value, selected))}
        options={options}
        isLoading={false}
        onChange={(option: any) => {
          dispatch(setErrorCodes(option.value))
        }}
      />
    </FormGroup>
  )
}

export default StatusErrorSelect
