import { createReducer } from 'typesafe-actions'

import { State, Action } from './types'
import {
  fetchPdfs,
  deletePdf,
  deletePdfs,
  reparsePdfs,
  updatePdfStatus,
  evaluatePdf,
  setPageIndex,
  setPageSize,
} from './actions'

const initialState = {
  total: 0,
  errors: 0,
  items: null,
  loading: false,
  evaluating: null,
  error: null,
  pageSize: 15,
  pageIndex: 0,
  parsingResult: null,
}

export default createReducer<State, Action>(initialState)
  .handleAction(fetchPdfs.request, (state) => ({
    ...state,
    loading: true,
    error: null,
  }))
  .handleAction(fetchPdfs.success, (state, action) => ({
    ...state,
    loading: false,
    error: null,
    total: action.payload.total,
    items: action.payload.items,
    errors: action.payload.errors,
  }))
  .handleAction(fetchPdfs.failure, (state, action) => ({
    ...state,
    loading: false,
    error: action.payload.message,
    total: 0,
    errors: 0,
    items: null,
  }))
  .handleAction(deletePdf.request, (state) => ({
    ...state,
    loading: true,
    error: null,
  }))
  .handleAction(deletePdf.success, (state) => ({
    ...state,
    loading: false,
    error: null,
  }))
  .handleAction(deletePdf.failure, (state, action) => ({
    ...state,
    loading: false,
    error: action.payload.message,
  }))
  .handleAction(deletePdfs.request, (state) => ({
    ...state,
    loading: true,
    error: null,
  }))
  .handleAction(deletePdfs.success, (state) => ({
    ...state,
    loading: false,
    error: null,
  }))
  .handleAction(deletePdfs.failure, (state, action) => ({
    ...state,
    loading: false,
    error: action.payload.message,
  }))
  .handleAction(reparsePdfs.request, (state) => ({
    ...state,
    loading: true,
    error: null,
  }))
  .handleAction(reparsePdfs.success, (state) => ({
    ...state,
    loading: false,
    error: null,
  }))
  .handleAction(reparsePdfs.failure, (state, action) => ({
    ...state,
    loading: false,
    error: action.payload.message,
  }))
  .handleAction(evaluatePdf.request, (state, action) => ({
    ...state,
    evaluating: action.payload,
  }))
  .handleAction(evaluatePdf.success, (state, action) => ({
    ...state,
    evaluating: null,
    parsingResult: action.payload,
  }))
  .handleAction(evaluatePdf.failure, (state) => ({
    ...state,
    evaluating: null,
  }))
  .handleAction(updatePdfStatus.request, (state) => ({
    ...state,
    loading: true,
    error: null,
  }))
  .handleAction(updatePdfStatus.success, (state) => ({
    ...state,
    loading: false,
    error: null,
  }))
  .handleAction(updatePdfStatus.failure, (state, action) => ({
    ...state,
    loading: false,
    error: action.payload.message,
  }))
  .handleAction(setPageSize, (state, action) => ({
    ...state,
    pageSize: action.payload,
  }))
  .handleAction(setPageIndex, (state, action) => ({
    ...state,
    pageIndex: action.payload,
  }))
