export enum Energy {
  Electricite = 'ELE',
  Gaz= 'GAZ',
}

export type Country = {
  id: number
  code: string
  libelle: string
}

export type Crawler = {
  id: number
  customerId: number
  customer: Customer
  supplierId: number
  supplier: Supplier
  executions: Array<CrawlerExecution> | null
}

export type CrawlerExecution = {
  id: number
  crawlerId: number
  dateDebut: string
  dateFin: string
  statut: EnumCrawlerStatutExecution
  message: string | null
  factures: Array<CrawlerFacture> | null
}

export type CrawlerFacture = {
  id: number
  executionId: number
  dateEmission: string
  dateExecution: string
  numeroFacture: string | null
  statut: EnumStatutRecuperationFacture
}

export enum EnumCrawlerStatutExecution {
  OK = 0,
  KO = -1,
}

export enum EnumStatutRecuperationFacture {
  OK = 0,
  KO = -1,
}

export type Customer = {
  id: number
  name: string
  platform: string
}

export type Supplier = {
  id: number
  name: string
  code: string
}
