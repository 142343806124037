import { FC } from 'react'
import { QueryClient, QueryClientProvider, MutationCache } from 'react-query'
import { useDispatch } from 'react-redux'
import Notifications from 'react-notification-system-redux'

export const QueryProvider: FC = ({ children }) => {
  const dispatch = useDispatch()

  const queryClient = new QueryClient({
    defaultOptions: {
      queries: {
        retry: 0,
        suspense: true,
        useErrorBoundary: true,
        refetchOnWindowFocus: true,
        refetchOnMount: true,
      },
      mutations: {
        retry: 0,
        useErrorBoundary: false,
      },
    },
    mutationCache: new MutationCache({
      onError: (error: any) => {
        dispatch(
          Notifications.error({
            title: error.message || error.Message,
            position: 'bl',
            autoDismiss: 5,
          })
        )
      },
    }),
  })

  return (
    <QueryClientProvider client={queryClient}>{children}</QueryClientProvider>
  )
}
