import { useEffect, useRef } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import Notifications from 'react-notification-system-redux'
import { getDeleting, getDeleteError } from 'store/invoices/invoices'

const notifyError = Notifications.error({
  title: 'Échec de la suppression',
  position: 'bl',
  autoDismiss: 5,
})

const notifySuccess = Notifications.success({
  title: 'Facture supprimée',
  position: 'bl',
  autoDismiss: 5,
})

export default function useInvoiceDeletionNotif() {
  const dispatch = useDispatch()
  const deleting = useSelector(getDeleting)
  const deleteError = useSelector(getDeleteError)
  const prevDeleting = useRef(deleting)

  useEffect(() => {
    if (deleteError) {
      dispatch(notifyError)
    } else if (prevDeleting.current && !deleting) {
      dispatch(notifySuccess)
    }
    prevDeleting.current = deleting
  }, [deleteError, deleting, dispatch])
}
