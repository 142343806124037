import { combineReducers } from 'redux'
import { combineEpics } from 'redux-observable'

import iat, { iatEpic, IatAction } from './iat'
import pdl, { pdlEpic, PdlAction } from './pdl'
import providers, { providersEpic } from './providers'
import clients, { clientsEpic } from './clients'
import invoices, { invoicesEpic, InvoicesAction } from './invoices'
import { reducer as pdfs, epic as pdfsEpic, Action as PdfsAction } from './pdfs'
import invoice, { invoiceEpic, InvoiceAction } from './invoice'
import selection, { selectionEpic, SelectionAction } from './selection'
import crawler, { CrawlerAction } from './crawler'

export default combineReducers({
  iat,
  pdl,
  providers,
  invoices,
  pdfs,
  clients,
  selection,
  invoice,
  crawler,
})

export const invoicesRootEpic = combineEpics(
  iatEpic,
  pdlEpic,
  providersEpic,
  invoicesEpic,
  pdfsEpic,
  clientsEpic,
  invoiceEpic,
  selectionEpic
)

export type InvoicesRootAction =
  | InvoicesAction
  | PdfsAction
  | SelectionAction
  | InvoiceAction
  | IatAction
  | PdlAction
  | CrawlerAction
