import { from, of, pipe } from 'rxjs'
import { filter, switchMap, map, catchError } from 'rxjs/operators'
import { combineEpics } from 'redux-observable'
import { createStructuredSelector } from 'reselect'
import {
  createReducer,
  ActionType,
  isActionOf,
  createAsyncAction,
} from 'typesafe-actions'

import { superFetch, checkStatus } from 'helpers/apiConf'
import { RootState, RootAction } from 'store'

// Types
interface Client {
  id: number
  label: string
  platform: string
}

interface Clients {
  [id: number]: Client
}

export interface ClientsState {
  items: Clients | null
  loading: boolean
  error: string | null
}

// Actions
export const fetchClients = createAsyncAction(
  '@data-mgmt/invoices/clients/FETCH_CLIENTS_REQUEST',
  '@data-mgmt/invoices/clients/FETCH_CLIENTS_SUCCESS',
  '@data-mgmt/invoices/clients/FETCH_CLIENTS_FAILURE'
)<undefined, Clients, Error>()

const actions = { fetchClients }
export type ClientsAction = ActionType<typeof actions>

// Reducer
const initialState: ClientsState = {
  items: null,
  loading: false,
  error: null,
}

export default createReducer<ClientsState, ClientsAction>(initialState)
  .handleAction(fetchClients.request, (state) => ({
    ...state,
    loading: true,
    error: null,
  }))
  .handleAction(fetchClients.success, (_, action) => ({
    loading: false,
    error: null,
    items: action.payload,
  }))
  .handleAction(fetchClients.failure, (_, action) => ({
    loading: false,
    error: action.payload.message,
    items: {},
  }))

// Selectors
export const getItems = (state: RootState) => state.invoices.clients.items
export const getLoading = (state: RootState) => state.invoices.clients.loading
export const getError = (state: RootState) => state.invoices.clients.error

interface SelectorProps {
  clients: Clients | null
  loading: boolean
  error: string | null
}

export const getClients = createStructuredSelector<RootState, SelectorProps>({
  clients: getItems,
  loading: getLoading,
  error: getError,
})

export const getFirstClient = (state: RootState) => {
  const items = getItems(state)
  return !items ? null : Number(Object.keys(items)[0]) || null
}

export const getClientLabel = (id: number) => (state: any) => {
  const items = getItems(state)
  const client = items && items[id]
  return client ? client.label : null
}

// API
interface ResponseItem {
  id: number
  plateforme: string
  nom: string
}

const normalize = (data: ResponseItem[]) =>
  data.reduce(
    (acc, item) => ({
      ...acc,
      [item.id]: {
        id: item.id,
        label: item.nom,
        plateforme: item.plateforme,
      },
    }),
    {}
  )

const api = {
  fetchClients: async () => {
    const payload = {
      url: 'accounts',
      invoiceApi: true,
    }
    const response = await superFetch(payload)
    checkStatus(response)
    const data = await response.json()
    return normalize(data)
  },
}

// Epics
export const clientsEpic = combineEpics<RootAction, RootAction, RootState>(
  (action$) =>
    action$.pipe(
      filter(isActionOf(fetchClients.request)),
      switchMap(() =>
        from(api.fetchClients()).pipe(
          map(fetchClients.success),
          catchError<any, any>(pipe(fetchClients.failure, of))
        )
      )
    )
)
