import { setToken, setPlatform } from '../helpers/apiConf'
import { Middleware } from 'redux'

const accessTokenMiddleware: Middleware = ({ getState }) => (next) => (
  action
) => {
  const state = getState()
  const token =
    state.oidc &&
    state.oidc.user &&
    state.oidc.user !== null &&
    state.oidc.user.access_token
  const platform =
    state.oidc &&
    state.oidc.user &&
    state.oidc.user !== null &&
    state.oidc.user.profile.pla

  if (token) {
    setToken(token)
  }
  if (platform) {
    setPlatform(platform)
  }

  return next(action)
}

export default accessTokenMiddleware
