import React, { FC } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Input } from 'reactstrap'

import { setEndDate, getEndDate } from 'store/invoices/selection'
import { FormGroup } from 'library'

interface Props {
  mandatory?: boolean
}

const EndDate: FC<Props> = ({ mandatory = false }) => {
  const dispatch = useDispatch()
  const value = useSelector(getEndDate)
  const isInvalid = mandatory && !value

  return (
    <FormGroup label="Date de fin">
      <Input
        type="date"
        value={value || ''}
        onChange={(e) => {
          dispatch(setEndDate(e.target.value))
        }}
        style={{
          fontSize: 14,
          border: isInvalid ? '1px solid red' : '1px solid #d7dee4',
        }}
      />
    </FormGroup>
  )
}

export default EndDate
