import { createStore, applyMiddleware, compose } from 'redux'
import { createEpicMiddleware } from 'redux-observable'
import { loadUser } from 'redux-oidc'
import thunk from 'redux-thunk'
import throttle from 'lodash/throttle'

import accessTokenMiddleware from 'middlewares/accessToken'
import userManager from 'userManager'
import { rootEpic, rootReducer } from './store'
import { loadState, saveState } from 'helpers/localStorage'
import { getEid } from 'store/selectors'

const epicMiddleware = createEpicMiddleware()

const configureStore = () => {
  const middleware = [epicMiddleware, thunk, accessTokenMiddleware]

  const persistedState = loadState()

  const composeEnhancers =
    (window && (window as any).__REDUX_DEVTOOLS_EXTENSION_COMPOSE__) || compose

  const store = createStore(
    rootReducer,
    persistedState,
    composeEnhancers(applyMiddleware(...middleware))
  )

  epicMiddleware.run(rootEpic as any)

  loadUser(store, userManager)

  let prevEid = localStorage.getItem('prevEid')
  store.subscribe(() => {
    const state = store.getState()

    const eid = getEid(state)
    if (!!eid && prevEid !== eid) {
      localStorage.setItem('prevEid', eid)
      prevEid = eid
      store.dispatch({ type: 'RESET_ALL' })
    }
  })

  store.subscribe(
    throttle(() => {
      const state = store.getState()

      const filteredState = {
        oidc: state.oidc,
        user: state.user,
        invoices: {
          selection: {
            ...state.invoices.selection,
            invoice: null,
          },
          crawler: state.invoices.crawler,
        },
      }
      saveState(filteredState)
    }, 1000)
  )

  return store
}

export default configureStore
