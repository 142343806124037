import { useState, useRef, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import debounce from 'lodash/debounce'
import { Input } from 'reactstrap'

import { setInvoiceNumber, getInvoiceNumber } from 'store/invoices/selection'
import { FormGroup } from 'library'

const InvoiceNumberInput = () => {
  const dispatch = useDispatch()
  const invoiceNumber = useSelector(getInvoiceNumber)
  const [value, setValue] = useState(invoiceNumber)
  const debounced = useRef(
    debounce((x: any) => {
      dispatch(setInvoiceNumber(x))
    }, 400)
  )

  useEffect(() => {
    if (invoiceNumber !== value) {
      debounced.current(value)
    }
  }, [invoiceNumber, value])

  return (
    <FormGroup label="Numéro de facture">
      <Input
        type="search"
        value={value || ''}
        onInput={(e: any) => setValue(e.target.value)}
        onChange={() => {}}
        style={{ fontSize: 14 }}
      />
    </FormGroup>
  )
}

export default InvoiceNumberInput
