//** @jsxImportSource @emotion/react */
import { FC } from 'react'
import { Modal, ModalHeader, ModalBody } from 'reactstrap'

import { Pdf } from 'library'

interface Props {
  isOpen: boolean
  toggle: any
  pdfId: number
  pdfName: string
}

const PdfsModal: FC<Props> = ({
  isOpen = false,
  toggle,
  pdfId,
  pdfName = '',
}) => {
  return (
    <Modal
      isOpen={isOpen}
      toggle={toggle}
      centered
      size="lg"
      contentClassName="pdf-modal"
    >
      {pdfId && (
        <div>
          <ModalHeader
            toggle={toggle}
            css={{
              padding: '.5rem 1rem',
              alignItems: 'center',
              '.modal-title': {
                fontSize: 12,
                fontWeight: 'bold',
              },
            }}
          >
            {pdfName}
          </ModalHeader>
          <ModalBody>
            <Pdf id={pdfId} width={798} />
          </ModalBody>
        </div>
      )}
    </Modal>
  )
}

export default PdfsModal
