export const errorsInvoice: any = {
  '00': 'A Intégrer',
  '01': 'Identifiant client manquant',
  '02': 'Code fournisseur manquant',
  '03': 'Total HT manquant',
  '04': "Date d'émission de facture manquante",
  '05': 'Le total HT est différent de la somme des lignes de facture',
  '06': 'Problème sur une ligne de facture',
  '07': 'Type de contrat manquant',
  '08': 'Compte de facturation introuvable',
  '09': 'Plusieurs comptes de facturation pour cette facture',
  '10': 'Pas de modèle de facturation sur le template',
}

export const errorsInvoiceLine: any = {
  '00': 'Valide',
  '01': 'Nom de compteur manquant ou inconnu dans EMCM',
  '02': 'Date de début de période manquante',
  '03': 'Date de fin de période manquante',
  '04': 'La date de début de période est suppérieure à la date de fin',
  '05': 'Libellé de ligne manquant',
  '06': "Numéro d'IAT manquant",
  '07': 'Unité de consommation absente',
}
export const statuses: any = {
  1: 'À télécharger',
  2: "En attente d'intégration",
  3: 'À télécharger',
  4: 'À télécharger',
  5: 'À télécharger',
  6: 'Erreur de téléchargement',
  7: 'Erreur de parsing',
  8: 'Intégré dans FAC',
  9: 'A Reparser',
  AE: 'À enrichir',
  EE: "Erreur d'enrichissement",
  AI: 'À intégrer',
  EI: "Erreur d'intégration",
  I: 'Intégrée dans EMCM',
}
