//** @jsxImportSource @emotion/react */
import { FC } from 'react'

interface Props {
  title?: string
  header?: React.ReactElement | null
  style?: object
}

const Section: FC<Props> = ({ title, children, header, style }) => {
  return (
    <section
      className="shadow-sm"
      css={{
        padding: 30,
        backgroundColor: '#fff',
        ...style,
      }}
    >
      {(title || header) && (
        <div
          css={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'middle',
            marginBottom: 14,
            h3: {
              fontSize: 16,
              fontWeight: 'bold',
              marginBottom: 20,
            },
          }}
        >
          {title && <h3>{title}</h3>}
          {header && <div>{header}</div>}
        </div>
      )}
      {children}
    </section>
  )
}

export default Section
