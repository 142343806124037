import { useDispatch, useSelector } from 'react-redux'
import { Input } from 'reactstrap'

import {
  setEndDate,
  getEndDate,
  getIsPeriod,
  setIsPeriod,
} from 'store/invoices/crawler'
import { FormGroup } from 'library'

const EndDate = () => {
  const dispatch = useDispatch()
  const value = useSelector(getEndDate)
  const isPeriod = useSelector(getIsPeriod)

  return (
    <FormGroup
      label={
        <span>
          <span>Fin</span>
          <input
            type="checkbox"
            checked={isPeriod ?? false}
            onChange={() => {
              dispatch(setIsPeriod(!isPeriod))
            }}
            style={{
              verticalAlign: 'middle',
              marginLeft: '0.8rem',
            }}
          />
        </span>
      }
    >
      <Input
        type="date"
        value={value || ''}
        disabled={!isPeriod}
        onChange={(e) => {
          dispatch(setEndDate(e.target.value))
        }}
        style={{
          fontSize: 14,
          border: '1px solid #d7dee4',
        }}
      />
    </FormGroup>
  )
}

export default EndDate
