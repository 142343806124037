import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import isEqual from 'lodash/isEqual'

import { setPdfStatus, getPdfStatus } from 'store/invoices/selection'
import { Select, FormGroup } from 'library'
import { statuses } from 'labels'

const options = [
  { value: ['1', '2', '3', '4', '5', '6', '7', '8', '9'], label: 'Tous' },
  { value: ['1', '3', '4', '5'], label: 'À télécharger' },
  ...['2', '6', '7', '8', '9'].map((id) => ({ value: [id], label: statuses[id] })),
]

const StatusSelect = () => {
  const dispatch = useDispatch()
  const selected = useSelector(getPdfStatus)

  useEffect(() => {
    if (!selected) dispatch(setPdfStatus(options[0].value))
  }, [dispatch, selected])

  return (
    <FormGroup label="Statut">
      <Select
        value={options.filter((option) => isEqual(option.value, selected))}
        options={options}
        isLoading={false}
        onChange={(option: any) => {
          dispatch(setPdfStatus(option.value))
        }}
      />
    </FormGroup>
  )
}

export default StatusSelect
