//** @jsxImportSource @emotion/react */
import React, { Fragment, FC } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { Formik, Form, Field, ErrorMessage, connect } from 'formik'
import * as Yup from 'yup'
import { RowRenderProps } from 'react-table'
import TableInput from './TableInput'
import { Theme } from '@emotion/react'

import { Table, Alert, CellButton } from 'library'
import {
  getPdl,
  fetchPdl,
  postPdl,
  deletePdl,
  getNewItem,
  deleteNewItem,
} from 'store/invoices/pdl'
import { getAdminProvider, getAdminClient } from 'store/invoices/selection'

interface FormValues {
  id: number
  label: string
  pdl: number
}

const Submit = connect((props: any) => {
  return (
    <CellButton
      type="submit"
      disabled={props.formik.isSubmitting}
      text="Valider"
      iconId="ok"
      width={110}
    />
  )
})

const Error: FC = ({ children }) => (
  <span
    css={(theme: Theme) => ({
      color: theme.colors.danger,
      marginLeft: '.5rem',
      fontSize: 12,
    })}
  >
    {children}
  </span>
)

const PdlTable = () => {
  const dispatch = useDispatch()
  const { pdl, loading, error } = useSelector(getPdl)
  const provider = useSelector(getAdminProvider)
  const client = useSelector(getAdminClient)
  const inputRef = React.useRef<any>(null)
  const [newItem, setNewItem] = React.useState<any>(null)
  const newPdl = useSelector(getNewItem)
  const [page, setPage] = React.useState(0)
  // const [pages, setPages] = React.useState(0)

  React.useEffect(() => {
    if (newPdl) {
      setNewItem(newPdl)
    }
    dispatch(deleteNewItem())
  }, [dispatch, newPdl])

  React.useEffect(() => {
    if (provider !== null && client !== null && pdl === null)
      dispatch(fetchPdl.request())
  }, [client, dispatch, pdl, provider])

  const res: any =
    pdl &&
    newItem &&
    Object.values(pdl).find((item: any) => item.label === newItem.label)

  const defaultValues = !newItem
    ? { label: '', pdl: '', id: 0 }
    : { label: newItem.label, iat: '', id: 0 }

  const [editPdl, setEditPdl] = React.useState<number | null>(null)
  React.useEffect(() => {
    if (newItem) setEditPdl(res ? res.id : 0)
  }, [newItem, res])

  React.useEffect(() => {
    if (editPdl !== null && inputRef.current !== null) inputRef.current.focus()
  }, [editPdl])

  const data: any[] =
    pdl === null
      ? []
      : [...(editPdl === 0 ? [defaultValues] : []), ...Object.values(pdl)]

  const initialValues =
    data.find((item) => item.id === editPdl) || defaultValues

  const columns = [
    {
      Header: 'PDL Facture',
      accessor: 'label',
      Cell: (row: RowRenderProps) => {
        const { id } = row.original
        return editPdl === id ? (
          <span>
            <Field name="label" type="text" component={TableInput} />
            <ErrorMessage name="label" component={Error} />
          </span>
        ) : (
          <div onClick={() => setEditPdl(id)}>{row.value}</div>
        )
      },
    },
    {
      Header: 'PDL EMCM',
      accessor: 'pdl',
      Cell: (row: RowRenderProps) => {
        const { id } = row.original
        return editPdl === id ? (
          <span>
            <Field
              name="pdl"
              type="text"
              innerRef={inputRef}
              component={TableInput}
            />
            <ErrorMessage name="pdl" component={Error} />
          </span>
        ) : (
          <div onClick={() => setEditPdl(id)}>{row.value}</div>
        )
      },
    },
    {
      Header: (
        <div style={{ textAlign: 'right' }}>
          <CellButton
            text="Ajouter"
            iconId="add"
            onClick={() => {
              setEditPdl(0)
              setPage(0)
              // if (pages !== 0) setPage(pages - 1)
            }}
            disabled={editPdl === 0 || !provider || !client}
          />
        </div>
      ),
      accessor: 'id',
      width: 250,
      filterable: false,
      sortable: false,
      Cell: (row: RowRenderProps) => {
        const { id } = row.original
        return (
          <div>
            {editPdl === id ? (
              <Fragment>
                <Submit />
                <CellButton
                  text="Annuler"
                  iconId="cross"
                  onClick={() => {
                    setEditPdl(null)
                    setNewItem(null)
                  }}
                  width={110}
                />
              </Fragment>
            ) : (
              <Fragment>
                <CellButton
                  disabled={editPdl !== null}
                  text="Modifier"
                  iconId="edit"
                  onClick={() => setEditPdl(id)}
                  width={110}
                />
                <CellButton
                  disabled={editPdl !== null}
                  text="Supprimer"
                  iconId="trash"
                  onClick={() => {
                    dispatch(deletePdl.request(id))
                    setNewItem(null)
                    setEditPdl(null)
                  }}
                  width={110}
                />
              </Fragment>
            )}
          </div>
        )
      },
    },
  ]

  const SignupSchema = Yup.object().shape({
    label: Yup.string()
      .min(1, 'Trop court')
      .max(50, 'Trop long')
      .notOneOf(
        data
          .map((x) => x.label)
          .filter(
            (label) =>
              editPdl !== null &&
              pdl &&
              pdl &&
              (!pdl[editPdl] || label !== pdl[editPdl].label)
          ),
        'Existe déjà'
      )
      .required('Requis'),
    pdl: Yup.string()
      .min(1, 'Trop court')
      .max(50, 'Trop long')
      .required('Requis'),
  })

  return (
    <>
      {error && <Alert>{error}</Alert>}
      <Formik
        enableReinitialize
        initialValues={initialValues}
        validationSchema={SignupSchema}
        onSubmit={async (values: FormValues, actions: any) => {
          setNewItem(null)
          setEditPdl(null)
          if (client && provider)
            dispatch(postPdl.request({ ...values, update: values.id !== 0 }))
          actions.setSubmitting(false)
          actions.resetForm()
        }}
        render={() => (
          <Form>
            <Table
              columns={columns}
              data={data}
              loading={loading}
              showPagination={true}
              showPageSizeOptions={true}
              showPageJump={true}
              filterable={true}
              defaultPageSize={10}
              minRows={5}
              page={page}
              onPageChange={(i) => {
                setPage(i)
                setEditPdl(null)
              }}
              // getProps={(state: any) => {
              //   setPages(state.pages)
              //   return {}
              // }}
            />
          </Form>
        )}
      />
    </>
  )
}

export default PdlTable
