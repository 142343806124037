import { from, of, pipe } from 'rxjs'
import {
  filter,
  switchMap,
  map,
  catchError,
  withLatestFrom,
} from 'rxjs/operators'
import { combineEpics } from 'redux-observable'
import { createStructuredSelector } from 'reselect'
import keyBy from 'lodash/keyBy'
import {
  ActionType,
  isActionOf,
  createAsyncAction,
  createReducer,
} from 'typesafe-actions'
import get from 'lodash/get'

import { superFetch, checkStatus } from 'helpers/apiConf'
import { getInvoice as getInvoiceSelection } from 'store/invoices/selection'
import { RootState, RootAction } from 'store'
import { evaluatePdf } from 'store/invoices/pdfs'

// Types
interface Line {
  id: number
}

interface Lines {
  [id: number]: Line
}

interface Invoice {
  id: number
  statut: string
  idClientEmcm: number
  codeFournisseur: string
  pdfId?: number
  lignes: Lines
}

export interface InvoiceState {
  data: Invoice | null
  loading: boolean
  error: string | null
}

// Action
interface UpdatePayload {
  id: number
  payload: any
}

interface UpdateLinesPayload {
  id: number
  body: any
}

interface UpdateLinePayload {
  id: number
  body: any
}

interface AddLinePayload {
  id: number
  body: any
}

interface DeleteLinePayload {
  invoiceId: number
  lineId: number
}

interface DeleteLinesPayload {
  invoiceId: number
  lineIds: number[]
}

export const fetchInvoice = createAsyncAction(
  '@data-mgmt/invoices/invoice/FETCH_INVOICE_REQUEST',
  '@data-mgmt/invoices/invoice/FETCH_INVOICE_SUCCESS',
  '@data-mgmt/invoices/invoice/FETCH_INVOICE_FAILURE'
)<number | null, Invoice, Error>()

export const updateInvoice = createAsyncAction(
  '@data-mgmt/invoices/invoice/UPDATE_INVOICE_REQUEST',
  '@data-mgmt/invoices/invoice/UPDATE_INVOICE_SUCCESS',
  '@data-mgmt/invoices/invoice/UPDATE_INVOICE_FAILURE'
)<UpdatePayload, undefined, Error>()

export const updateInvoiceLines = createAsyncAction(
  '@data-mgmt/invoices/invoice/UPDATE_INVOICE_LINES_REQUEST',
  '@data-mgmt/invoices/invoice/UPDATE_INVOICE_LINES_SUCCESS',
  '@data-mgmt/invoices/invoice/UPDATE_INVOICE_LINES_FAILURE'
)<UpdateLinesPayload, undefined, Error>()

export const updateInvoiceLine = createAsyncAction(
  '@data-mgmt/invoices/invoice/UPDATE_INVOICE_LINE_REQUEST',
  '@data-mgmt/invoices/invoice/UPDATE_INVOICE_LINE_SUCCESS',
  '@data-mgmt/invoices/invoice/UPDATE_INVOICE_LINE_FAILURE'
)<UpdateLinePayload, undefined, Error>()

export const addInvoiceLine = createAsyncAction(
  '@data-mgmt/invoices/invoice/ADD_INVOICE_LINE_REQUEST',
  '@data-mgmt/invoices/invoice/ADD_INVOICE_LINE_SUCCESS',
  '@data-mgmt/invoices/invoice/ADD_INVOICE_LINE_FAILURE'
)<AddLinePayload, undefined, Error>()

export const deleteInvoiceLine = createAsyncAction(
  '@data-mgmt/invoices/invoice/DELETE_INVOICE_LINE_REQUEST',
  '@data-mgmt/invoices/invoice/DELETE_INVOICE_LINE_SUCCESS',
  '@data-mgmt/invoices/invoice/DELETE_INVOICE_LINE_FAILURE'
)<DeleteLinePayload, undefined, Error>()

export const deleteInvoiceLines = createAsyncAction(
  '@data-mgmt/invoices/invoice/DELETE_INVOICE_LINES_REQUEST',
  '@data-mgmt/invoices/invoice/DELETE_INVOICE_LINES_SUCCESS',
  '@data-mgmt/invoices/invoice/DELETE_INVOICE_LINES_FAILURE'
)<DeleteLinesPayload, undefined, Error>()

const actions = {
  fetchInvoice,
  updateInvoice,
  updateInvoiceLines,
  updateInvoiceLine,
  addInvoiceLine,
  deleteInvoiceLine,
  deleteInvoiceLines,
}
export type InvoiceAction = ActionType<typeof actions>

// Reducer
const initialState = {
  data: null,
  loading: false,
  error: null,
}

export default createReducer<InvoiceState, InvoiceAction>(initialState)
  .handleAction(fetchInvoice.request, (state) => ({
    ...state,
    loading: true,
    error: null,
  }))
  .handleAction(fetchInvoice.success, (state, action) => ({
    ...state,
    loading: false,
    error: null,
    data: action.payload,
  }))
  .handleAction(fetchInvoice.failure, (state, action) => ({
    ...state,
    loading: false,
    error: action.payload.message,
    data: null,
  }))
  .handleAction(
    [
      updateInvoice.request,
      updateInvoiceLines.request,
      updateInvoiceLine.request,
      addInvoiceLine.request,
      deleteInvoiceLine.request,
      deleteInvoiceLines.request,
    ],
    (state) => ({
      ...state,
      loading: true,
      error: null,
    })
  )
  .handleAction(
    [
      updateInvoice.success,
      updateInvoiceLines.success,
      updateInvoiceLine.success,
      addInvoiceLine.success,
      deleteInvoiceLine.success,
      deleteInvoiceLines.success,
    ],
    (state) => ({
      ...state,
      loading: false,
      error: null,
    })
  )
  .handleAction(
    [
      updateInvoice.failure,
      updateInvoiceLines.failure,
      updateInvoiceLine.failure,
      addInvoiceLine.failure,
      deleteInvoiceLine.failure,
      deleteInvoiceLines.failure,
    ],
    (state, action) => ({
      ...state,
      error: action.payload.message,
      data: null,
    })
  )

// Selectors
interface SelectorProps {
  invoice: Invoice | null
  loading: boolean
  error: string | null
}

export const getInvoice = createStructuredSelector<RootState, SelectorProps>({
  invoice: (state: RootState) => state.invoices.invoice.data,
  loading: (state: RootState) => state.invoices.invoice.loading,
  error: (state: RootState) => state.invoices.invoice.error,
})

export const getInvoiceClient = (state: RootState) =>
  get(state, 'invoices.invoice.data.idClientEmcm', null)
export const getInvoiceProvider = (state: RootState) =>
  get(state, 'invoices.invoice.data.codeFournisseur', null)

// API
const api = {
  fetchInvoice: async (id: number | null) => {
    if (id === null) return {}

    const response = await superFetch({
      url: `invoices/${id}`,
      invoiceApi: true,
    })

    checkStatus(response)
    const data = await response.json()
    return {
      ...data,
      lignes: keyBy(data.lignes, 'id'),
    }
  },
  updateInvoice: async (payload: any) => {
    const response = await superFetch({
      url: `invoices/${payload.id}`,
      method: 'PUT',
      invoiceApi: true,
      body: payload.payload,
    })

    checkStatus(response)
    return response.json()
  },
  updateInvoiceLines: async (payload: UpdateLinesPayload) => {
    const { id, body } = payload
    const response = await superFetch({
      url: `invoices/${id}/lignes`,
      method: 'PUT',
      invoiceApi: true,
      body,
    })

    checkStatus(response)
  },
  updateInvoiceLine: async (payload: UpdateLinePayload) => {
    const { id, body } = payload
    const response = await superFetch({
      url: `invoices/${id}/lignes/${body.id}`,
      method: 'PUT',
      invoiceApi: true,
      body,
    })

    checkStatus(response)
  },
  addInvoiceLine: async (payload: AddLinePayload) => {
    const { id, body } = payload
    const response = await superFetch({
      url: `invoices/${id}/lignes`,
      method: 'POST',
      invoiceApi: true,
      body,
    })

    checkStatus(response)
  },
  deleteInvoiceLine: async (invoiceId: number, lineId: number) => {
    const response = await superFetch({
      url: `invoices/${invoiceId}/lignes/${lineId}`,
      method: 'DELETE',
      invoiceApi: true,
    })

    checkStatus(response)
  },
  deleteInvoiceLines: async (invoiceId: number, lineIds: number[]) => {
    const response = await superFetch({
      url: `invoices/${invoiceId}/lignes`,
      method: 'DELETE',
      invoiceApi: true,
      body: lineIds,
    })

    checkStatus(response)
  },
}

// Epics
export const invoiceEpic = combineEpics<RootAction, RootAction, RootState>(
  (action$) =>
    action$.pipe(
      filter(isActionOf(fetchInvoice.request)),
      switchMap((action) =>
        from(api.fetchInvoice(action.payload)).pipe(
          map(fetchInvoice.success),
          catchError<any, any>(pipe(fetchInvoice.failure, of))
        )
      )
    ),
  (action$) =>
    action$.pipe(
      filter(isActionOf(updateInvoice.request)),
      switchMap((action) =>
        from(api.updateInvoice(action.payload)).pipe(
          map(updateInvoice.success),
          catchError<any, any>(pipe(updateInvoice.failure, of))
        )
      )
    ),
  (action$) =>
    action$.pipe(
      filter(isActionOf(updateInvoiceLine.request)),
      switchMap((action) =>
        from(api.updateInvoiceLine(action.payload)).pipe(
          map(updateInvoiceLine.success),
          catchError<any, any>(pipe(updateInvoiceLine.failure, of))
        )
      )
    ),
  (action$) =>
    action$.pipe(
      filter(isActionOf(updateInvoiceLines.request)),
      switchMap((action) =>
        from(api.updateInvoiceLines(action.payload)).pipe(
          map(updateInvoiceLines.success),
          catchError<any, any>(pipe(updateInvoiceLines.failure, of))
        )
      )
    ),

  (action$) =>
    action$.pipe(
      filter(isActionOf(addInvoiceLine.request)),
      switchMap((action) =>
        from(api.addInvoiceLine(action.payload)).pipe(
          map(addInvoiceLine.success),
          catchError<any, any>(pipe(addInvoiceLine.failure, of))
        )
      )
    ),
  (action$) =>
    action$.pipe(
      filter(isActionOf(deleteInvoiceLine.request)),
      switchMap((action) =>
        from(
          api.deleteInvoiceLine(action.payload.invoiceId, action.payload.lineId)
        ).pipe(
          map(deleteInvoiceLine.success),
          catchError<any, any>(pipe(deleteInvoiceLine.failure, of))
        )
      )
    ),
  (action$) =>
    action$.pipe(
      filter(isActionOf(deleteInvoiceLines.request)),
      switchMap((action) =>
        from(
          api.deleteInvoiceLines(
            action.payload.invoiceId,
            action.payload.lineIds
          )
        ).pipe(
          map(deleteInvoiceLines.success),
          catchError<any, any>(pipe(deleteInvoiceLines.failure, of))
        )
      )
    ),
  (action$, state$) =>
    action$.pipe(
      filter(
        isActionOf([
          updateInvoice.success,
          updateInvoiceLine.success,
          updateInvoiceLines.success,
          addInvoiceLine.success,
          deleteInvoiceLine.success,
          deleteInvoiceLines.success,
          evaluatePdf.success,
        ])
      ),
      withLatestFrom(state$),
      map(([_, state]) => fetchInvoice.request(getInvoiceSelection(state)))
    )
)
