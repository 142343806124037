//** @jsxImportSource @emotion/react */
import { useState } from 'react'
import { Button } from 'reactstrap'
import { CallbackComponent } from 'redux-oidc'
import { Redirect } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

import userManager from 'userManager'
import { Loader } from 'library'

const Callback = () => {
  const { t } = useTranslation()
  const [toHome, setToHome] = useState(false)

  if (toHome) return <Redirect to="/" push />

  return (
    <CallbackComponent
      userManager={userManager}
      successCallback={() => setToHome(true)}
      errorCallback={(error) =>
        console.warn('Login CallbackError', error.message)
      }
    >
      <div
        css={{
          position: 'absolute',
          width: '100vw',
          height: '100vh',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <Loader />

        <div css={{ marginTop: 5 }}>
          <Button color="link" onClick={() => (window.location.href = '/')}>
            {t('access.redirection')}
          </Button>
        </div>
      </div>
    </CallbackComponent>
  )
}

export default Callback
