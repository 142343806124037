//** @jsxImportSource @emotion/react */
import { FC, Fragment, useRef } from 'react'

import usePage from './usePage'
import useRenderPage from './useRenderPage'
const PdfPage: FC<any> = ({ document, index, scale, containerWidth }) => {
  const canvasRef = useRef<any>(null)
  const textRef = useRef<any>(null)

  const { page, pageWidth } = usePage(document, index)
  const { width, height } = useRenderPage({
    page,
    canvas: canvasRef.current,
    textLayer: textRef.current,
    containerWidth,
    pageWidth,
    scale,
    destroyed: document.loadingTask.destroyed,
  })

  return (
    <div
      css={{
        position: 'relative',
        width: width ? width : '100%',
        height: height ? height : 600,
        '>*': {
          position: 'absolute',
          top: 0,
          width: '100%',
          height: '100%',
        },
        '.text-layer > span': {
          color: 'transparent',
          position: 'absolute',
          whiteSpace: 'pre',
          cursor: 'text',
          transformOrigin: '0% 0%',
        },
      }}
    >
      <Fragment>
        <canvas ref={canvasRef} />
        <div ref={textRef} className="text-layer" />
      </Fragment>
    </div>
  )
}

export default PdfPage
