//** @jsxImportSource @emotion/react */
import { useSelector } from 'react-redux'

import { getInvoice } from 'store/invoices/invoice'
import { Icon } from 'library'
import { statuses as labels } from 'labels'

type Props = {
  curr: string
  wip: string
  ko?: string
  ok: string
}

const Step = ({ curr, wip, ko, ok }: Props) => {
  return (
    <div
      css={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        fontSize: 11,
        textTransform: 'uppercase',
        margin: '0 1rem',
      }}
    >
      <div
        css={{
          border: '1px solid #61687F',
          borderRadius: '50%',
          width: 32,
          height: 32,
          marginBottom: '0.5rem',
          backgroundColor: '#fff',
          position: 'relative',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        {curr === ok ? (
          <Icon id="check-full" color="success" />
        ) : curr === ko ? (
          <Icon id="warning" color="danger" />
        ) : curr === wip ? (
          <Icon id="clock-o" color="warning" />
        ) : null}
      </div>
      {labels[wip]}
    </div>
  )
}

const InvoiceHeader = () => {
  const { invoice } = useSelector(getInvoice)
  if (!invoice) return null
  return (
    <div
      css={{
        display: 'flex',
        justifyContent: 'center',
        position: 'relative',
        padding: '0 1rem',
      }}
    >
      <div
        css={{
          borderTop: '1px solid #61687F',
          position: 'absolute',
          width: '100%',
          top: 16,
        }}
      />
      <Step curr={invoice.statut} wip="AE" ko="EE" ok="AI" />
      <Step curr={invoice.statut} wip="AI" ko="EI" ok="I" />
      <Step curr={invoice.statut} wip="I" ok="I" />
    </div>
  )
}

export default InvoiceHeader
