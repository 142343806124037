//** @jsxImportSource @emotion/react */
import { useMemo } from 'react'
import { RowRenderProps } from 'react-table'
import { useSearchFacturesQuery } from 'queries/crawler'
import { Table } from 'library'
import { EnumStatutRecuperationFacture } from 'api/types'
import { BtnDeleteFacture } from './BtnDeleteFacture'
import { BtnCreateFacture } from 'components/crawler/BtnCreateFacture'

type Props = {
  execId: number
  dev?: boolean
}

export const Factures = ({ execId, dev = false }: Props) => {
  const { data: factures, isFetching } = useSearchFacturesQuery(execId)

  const tableData = useMemo(() => {
    if (!factures) return []
    return factures.map((fac) => ({
      ...fac,
      statutLabel: EnumStatutRecuperationFacture[fac.statut],
    }))
  }, [factures])

  const columns = useMemo(
    () => [
      {
        Header: 'Numéro',
        accessor: 'numeroFacture',
      },
      {
        Header: 'Statut',
        accessor: 'statutLabel',
        width: 120,
      },
      ...(dev
        ? [
            {
              sortable: false,
              width: 55,
              Header: ' ',
              Cell: (row: RowRenderProps) => (
                <BtnDeleteFacture execId={execId} facId={row.original.id} />
              ),
            },
          ]
        : []),
    ],
    [dev, execId]
  )

  return (
    <div css={{ '> * + *': { marginTop: '1.5rem' } }}>
      <Table
        columns={columns}
        data={tableData}
        loading={isFetching}
        filterable={false}
        sortable={true}
        defaultPageSize={10}
        pageSizeOptions={[10, 15, 20]}
      />

      {dev && (
        <div>
          <BtnCreateFacture execId={execId} />
        </div>
      )}
    </div>
  )
}
