import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useCountriesQuery } from 'queries/countries'
import { setCountry, getCountry } from 'store/invoices/selection'
import { Select, FormGroup } from 'library'

export const CountrySelect = ({
  inline = false,
  width,
}: {
  inline?: boolean
  width?: number
}) => {
  const { data: countries, isFetching } = useCountriesQuery()
  const dispatch = useDispatch()
  const selected = useSelector(getCountry)

  const options = countries?.map((country) => ({
    value: country.code,
    label: <span><span className={`mr-2 flag-icon flag-icon-${country.code.toLowerCase()}`} /><span>{country.libelle}</span></span>,
  })) ?? []

  useEffect(() => {
    if (!countries) return
    if (!selected) {
      dispatch(setCountry('FR'))
    } else if (countries.every((c) => c.code !== selected)) {
      dispatch(setCountry(null))
    }
  }, [countries, dispatch, selected])

  return (
    <FormGroup label="Pays" inline={inline}>
      <Select
        value={options.filter((option) => option.value === selected)}
        options={options}
        isLoading={isFetching}
        width={width}
        onChange={(option: any) => {
          dispatch(setCountry(option ? option.value : null))
        }}
        placeholder=""
      />
    </FormGroup>
  )
}