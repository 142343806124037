//** @jsxImportSource @emotion/react */
import { useReducer, useCallback } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import Notifications from 'react-notification-system-redux'

import { superFetch } from 'helpers/apiConf'
import { Alert } from 'library'
import { getSelection } from 'store/invoices/selection'
import Dropzone from './Dropzone'
// import ImportResult from './ImportResult'
import { CountrySelect } from './CountrySelect'
import { EnergySelect } from './EnergySelect'

const isSuccess: (status: number) => boolean = (status) =>
  status >= 200 && status < 300

const initialState = {
  data: null,
  loading: false,
  error: null,
}

interface State {
  data: any | null
  loading: boolean
  error: string | null
}

type Action =
  | { type: 'request' }
  | { type: 'success'; data: any }
  | { type: 'failure'; msg: string }

function reducer(state: State, action: Action): State {
  switch (action.type) {
    case 'request':
      return {
        data: null,
        loading: true,
        error: null,
      }
    case 'success':
      return {
        data: action.data,
        loading: false,
        error: null,
      }
    case 'failure':
      return {
        data: null,
        loading: false,
        error: action.msg,
      }
    default:
      return state
  }
}

const useNotify = () => {
  const dispatch = useDispatch()
  const success = (title: string, message?: string) => dispatch(
    Notifications.success({
      title,
      message,
      position: 'bl',
      autoDismiss: 8,
    })
  )
  const error = (title: string, message?: string) => dispatch(
    Notifications.error({
      title,
      message,
      position: 'bl',
      autoDismiss: 8,
    })
  )
  return { success, error }
}

export const ImportXml = () => {
  const notify = useNotify()
  const { provider, client, country, energy } = useSelector(getSelection)

  const [{ loading, error }, dispatch] = useReducer(
    reducer,
    initialState
  )

  const onDrop = useCallback(
    (acceptedFiles: any[], rejectedFiles: any[]) => {
      if (client === null || provider === null || country === null) return

      const formData = new FormData()
      formData.append('customerId', client.toString())
      formData.append('supplierCode', provider)
      formData.append('pays', country)
      formData.append('commodite', energy)
      for (const file of acceptedFiles) {
        formData.append('file', file, file.name)
      }

      const fetch = async () => {
        try {
          dispatch({ type: 'request' })
          const res = await superFetch({
            url: `invoices/files/upload`,
            method: 'POST',
            invoiceApi: true,
            body: formData,
            contentType: null,
            stringify: false,
          })
          const { status, type, statusText } = res
          if (!isSuccess(status))
            throw new Error(
              `${status}${type && ` (${type})`}${statusText &&
                ` - ${statusText}`}`
            )

          const data = await res.json()

          dispatch({ type: 'success', data })

          if (acceptedFiles.length > 1) {
            notify.success(`${acceptedFiles.length} fichiers envoyés`, acceptedFiles.map((file) => file.name).join(', '))
          } else if (acceptedFiles.length === 1) {
            notify.success(`1 fichier envoyé`, acceptedFiles[0].name)
          }

          if (rejectedFiles.length > 1) {
            notify.error(`Fichiers non valides`, rejectedFiles.map((file) => file.name).join(', '))
          } else if (rejectedFiles.length === 1) {
            notify.error(`Fichier non valide`, rejectedFiles[0].name)
          }
        } catch (error) {
          dispatch({ type: 'failure', msg: String(error) })
        }
      }
      fetch()
    },
    [client, country, energy, notify, provider]
  )

  return (
    <div>
      <div css={{ display: 'flex', justifyContent: 'space-between', alignItems: 'flex-start' }}>
        <h5 style={{ marginBottom: '2rem' }}>Autres fichiers</h5>
        <div css={{ display: 'flex', '>*+*': { marginLeft: '2rem' }}}>
          <span><CountrySelect inline width={200} /></span>
          <span><EnergySelect inline width={200} /></span>
        </div>
      </div>

      <Dropzone
        onDrop={onDrop}
        style={{ margin: '0 0 2rem' }}
        loading={loading}
        mimeTypes={[
          'text/xml',
          'application/xml',
        ]}
      />

      {error && <Alert>{error}</Alert>}
    </div>
  )
}