import React, { FC } from 'react'
import { FormGroup, Input, Label } from 'reactstrap'

interface Props {
  id: string
  label: string
  value: string
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void
  type?: any
  required?: boolean
}

const UserInfosInput: FC<Props> = ({
  id,
  label,
  onChange,
  value,
  type = 'text',
  required = false,
}) => (
  <FormGroup className="text-input">
    <Input
      className={value === '' ? '' : 'has-value'}
      id={id}
      defaultValue={value}
      onChange={onChange}
      required={required}
      type={type}
    />
    <Label for={id}>
      {label}
      {required ? '*' : ''}
    </Label>
  </FormGroup>
)

export default UserInfosInput
