import { useDispatch, useSelector } from 'react-redux'
import isEqual from 'lodash/isEqual'

import { setLineErrorCodes, getLineErrorCodes } from 'store/invoices/selection'
import { Select, FormGroup } from 'library'
import { errorsInvoiceLine } from 'labels'


// '00': 'Valide',
// '01': 'Nom de compteur manquant ou inconnu dans EMCM',
// '02': 'Date de début de période manquante',
// '03': 'Date de fin de période manquante',
// '04': 'La date de début de période est suppérieure à la date de fin',
// '05': 'Libellé de ligne manquant',
// '06': "Numéro d'IAT manquant",
// '07': 'Unité de consommation absente',
const options = [
  { value: [], label: 'Tous' }, 
  ...['00', '01', '02', '03', '04', '05', '06', '07'].map((id) => ({
    value: [id],
    label: errorsInvoiceLine[id],
  })),
]

const StatusLineErrorSelect = () => {
  const dispatch = useDispatch()
  const selected = useSelector(getLineErrorCodes)

  return (
    <FormGroup label="Code Erreur Ligne">
      <Select
        value={options.filter((option) => isEqual(option.value, selected))}
        options={options}
        isLoading={false}
        onChange={(option: any) => {
          dispatch(setLineErrorCodes(option.value))
        }}
      />
    </FormGroup>
  )
}

export default StatusLineErrorSelect
