//** @jsxImportSource @emotion/react */
import { FC } from 'react'
import { FormGroup, Label, FormFeedback, Input } from 'reactstrap'
import Datetime from 'react-datetime'

const InvoiceDateInput: FC<any> = ({
  field,
  form: { touched, errors, setFieldValue, setFieldTouched },
  label,
  ...props
}) => {
  return (
    <FormGroup css={{ display: 'flex', alignItems: 'center', marginBottom: 0 }}>
      <Label for={props.name} css={{ width: 130, marginBottom: 0 }}>
        {label}
      </Label>

      <Datetime
        locale="fr"
        closeOnSelect
        timeFormat={false}
        dateFormat="DD/MM/YYYY"
        viewMode="days"
        value={field.value}
        onChange={(x: any) => {
          setFieldValue(
            field.name,
            typeof x === 'string' ? x : x.format('DD/MM/YYYY')
          )
        }}
        onBlur={() => {
          setFieldTouched(field.name)
        }}
        inputProps={{
          name: field.name,
        }}
        renderInput={(props: any) => (
          <Input
            {...props}
            bsSize="sm"
            invalid={!!errors[field.name]}
            css={{ width: 170 }}
          />
        )}
        {...props}
      />

      {touched[field.name] && errors[field.name] && (
        <FormFeedback
          css={{ width: 90, margin: '0 0 0 10px', display: 'block' }}
        >
          {errors[field.name]}
        </FormFeedback>
      )}
    </FormGroup>
  )
}

export default InvoiceDateInput
