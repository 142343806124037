//** @jsxImportSource @emotion/react */
import React, { FC } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { FormGroup, Label, FormFeedback } from 'reactstrap'

import { Select } from 'library'
import { getProviders, fetchProviders } from 'store/invoices/providers'

const InvoiceProviderSelect: FC<any> = ({
  field,
  form: { touched, errors, setFieldValue },
  label,
  ...props
}) => {
  const dispatch = useDispatch()
  const { providers, loading } = useSelector(getProviders)

  React.useEffect(() => {
    if (!providers && !loading) dispatch(fetchProviders.request())
  }, [dispatch, loading, providers])

  const options = React.useMemo(
    () =>
      !providers
        ? []
        : Object.values(providers).map((p: any) => ({
            value: p.id,
            label: p.label,
          })),
    [providers]
  )

  return (
    <FormGroup css={{ display: 'flex', alignItems: 'center', marginBottom: 0 }}>
      <Label for={props.name} css={{ width: 130, marginBottom: 0 }}>
        {label}
      </Label>

      <Select
        {...field}
        {...props}
        value={options.filter((option) => option.value === field.value)}
        options={options}
        isLoading={loading}
        onChange={(option: any) => {
          setFieldValue(field.name, option.value)
        }}
        width={170}
        fontSize={12}
        inputId={field.name}
        invalid={!!errors[field.name]}
        placeholder=""
      />

      {touched[field.name] && errors[field.name] && (
        <FormFeedback
          css={{ width: 90, margin: '0 0 0 10px', display: 'block' }}
        >
          {errors[field.name]}
        </FormFeedback>
      )}
    </FormGroup>
  )
}

export default InvoiceProviderSelect
