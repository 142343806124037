import React, { FC } from 'react'
import { Alert as ReactstrapAlert } from 'reactstrap'

interface Props {
  style?: object
}

const Alert: FC<Props> = ({ style = {}, children }) => {
  return (
    <ReactstrapAlert
      color="danger"
      style={{
        fontSize: 14,
        padding: '12.5px 17.5px 10.5px',
        borderRadius: '.2rem',
        ...style,
      }}
    >
      {children}
    </ReactstrapAlert>
  )
}

export default Alert
