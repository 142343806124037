import { createStructuredSelector } from 'reselect'
import { from, of, pipe } from 'rxjs'
import {
  filter,
  map,
  switchMap,
  mapTo,
  withLatestFrom,
  catchError,
} from 'rxjs/operators'
import { combineEpics } from 'redux-observable'
import {
  createAction,
  createReducer,
  ActionType,
  isActionOf,
  createAsyncAction,
} from 'typesafe-actions'

import { superFetch, checkStatus } from 'helpers/apiConf'
import { getAdminProvider, setAdminProvider } from 'store/invoices/selection'
import { RootState, RootAction } from 'store'

// Types
interface Iat {
  id: number
  label: string
  iat: number
}

interface Iats {
  [key: string]: Iat
}

interface NewItem {
  label: string
  iat: number
}

export interface IatState {
  items: Iats | null
  loading: boolean
  error: string | null
  newItem: NewItem | null
}

interface PostPayload {
  id: number
  label: string
  iat: number
  update: boolean
}

export const fetchIat = createAsyncAction(
  '@data-mgmt/invoices/iat/FETCH_IAT_REQUEST',
  '@data-mgmt/invoices/iat/FETCH_IAT_SUCCESS',
  '@data-mgmt/invoices/iat/FETCH_IAT_FAILURE'
)<undefined, Iats, Error>()

export const postIat = createAsyncAction(
  '@data-mgmt/invoices/iat/POST_IAT_REQUEST',
  '@data-mgmt/invoices/iat/POST_IAT_SUCCESS',
  '@data-mgmt/invoices/iat/POST_IAT_FAILURE'
)<PostPayload, undefined, Error>()

export const deleteIat = createAsyncAction(
  '@data-mgmt/invoices/iat/DELETE_IAT_REQUEST',
  '@data-mgmt/invoices/iat/DELETE_IAT_SUCCESS',
  '@data-mgmt/invoices/iat/DELETE_IAT_FAILURE'
)<number, undefined, Error>()

export const setNewItem = createAction(
  '@data-mgmt/invoices/iat/SET_NEW_ITEM',
  (action) => (newItem: NewItem) => action(newItem)
)

export const deleteNewItem = createAction(
  '@data-mgmt/invoices/iat/DELETE_NEW_ITEM',
  (action) => () => action()
)

const actions = { fetchIat, postIat, deleteIat, setNewItem, deleteNewItem }
export type IatAction = ActionType<typeof actions>

// Reducer
const initialState = {
  items: null,
  loading: false,
  error: null,
  newItem: null,
}

export default createReducer<IatState, IatAction>(initialState)
  .handleAction(fetchIat.request, (state) => ({
    ...state,
    items: state.items || {},
    loading: true,
    error: null,
  }))
  .handleAction(fetchIat.success, (state, action) => ({
    ...state,
    items: action.payload,
    loading: false,
    error: null,
  }))
  .handleAction(fetchIat.failure, (state, action) => ({
    ...state,
    items: {},
    loading: false,
    error: action.payload.message,
  }))
  .handleAction([postIat.request, deleteIat.request], (state) => ({
    ...state,
    loading: true,
    error: null,
  }))
  .handleAction([postIat.success, deleteIat.success], (state) => ({
    ...state,
    loading: false,
    error: null,
  }))
  .handleAction([postIat.failure, deleteIat.failure], (state, action) => ({
    ...state,
    loading: false,
    error: action.payload.message,
  }))
  .handleAction(setNewItem, (state, action) => ({
    ...state,
    newItem: action.payload,
  }))
  .handleAction(deleteNewItem, (state) => ({
    ...state,
    newItem: null,
  }))

// Selectors
export const getItems = (state: RootState) => state.invoices.iat.items
export const getLoading = (state: RootState) => state.invoices.iat.loading
export const getError = (state: RootState) => state.invoices.iat.error
export const getNewItem = (state: RootState) => state.invoices.iat.newItem

interface SelectorProps {
  iat: Iats | null
  loading: boolean
  error: string | null
}

export const getIat = createStructuredSelector<RootState, SelectorProps>({
  iat: getItems,
  loading: getLoading,
  error: getError,
})

// API
interface ResponseItem {
  id: number
  iat: number
  libelle: string
}

const normalize = (data: ResponseItem[]) =>
  data.reduce(
    (acc, item) => ({
      ...acc,
      [item.id]: {
        id: item.id,
        iat: item.iat,
        label: item.libelle,
      },
    }),
    {}
  )

const api = {
  fetchIat: async (provider: string | null) => {
    const response = await superFetch({
      url: `iat?supplier=${provider}`,
      invoiceApi: true,
    })
    checkStatus(response)
    const data = await response.json()
    return normalize(data)
  },
  postIat: async (provider: string | null, payload: PostPayload) => {
    const { id, label, iat, update } = payload
    const response = await superFetch({
      url: update ? `iat/${id}` : 'iat',
      invoiceApi: true,
      method: update ? 'PUT' : 'POST',
      body: {
        Id: id,
        Fournisseur: provider,
        Libelle: label,
        Iat: iat,
      },
    })
    checkStatus(response)
    return response
  },
  deleteIat: async (id: number) => {
    const response = await superFetch({
      url: `iat/${id}`,
      invoiceApi: true,
      method: 'DELETE',
    })
    checkStatus(response)
    return response
  },
}

// Epics
export const iatEpic = combineEpics<RootAction, RootAction, RootState>(
  (action$, state$) =>
    action$.pipe(
      filter(isActionOf(fetchIat.request)),
      withLatestFrom(state$),
      switchMap(([_, state]) =>
        from(api.fetchIat(getAdminProvider(state))).pipe(
          map(fetchIat.success),
          catchError<any, any>(pipe(fetchIat.failure, of))
        )
      )
    ),
  (action$, state$) =>
    action$.pipe(
      filter(isActionOf(postIat.request)),
      withLatestFrom(state$),
      switchMap(([action, state]) =>
        from(api.postIat(getAdminProvider(state), action.payload)).pipe(
          map(postIat.success),
          catchError<any, any>(pipe(postIat.failure, of))
        )
      )
    ),
  (action$) =>
    action$.pipe(
      filter(isActionOf(deleteIat.request)),
      switchMap((action) =>
        from(api.deleteIat(action.payload)).pipe(
          map(deleteIat.success),
          catchError<any, any>(pipe(deleteIat.failure, of))
        )
      )
    ),
  (action$) =>
    action$.pipe(
      filter(
        isActionOf([setAdminProvider, postIat.success, deleteIat.success])
      ),
      mapTo(fetchIat.request())
    )
)
