//** @jsxImportSource @emotion/react */
import { HeaderButton } from 'library'
import { useSelector, useDispatch } from 'react-redux'
import { Alert } from 'reactstrap'

import CSVButton from 'components/CSVButton/CSVButton'
import { getInvoices, reparseFilterInvoices } from 'store/invoices/invoices'
import { errorsInvoice, statuses } from 'labels'

const InvoicesStats = () => {
  const dispatch = useDispatch()
  const { invoices, loading, error, errors, total } = useSelector(getInvoices)

  if (error) return <Alert color="danger">{error}</Alert>
  if (loading || !invoices) return null

  const count = Object.values(invoices).length
  const data = !invoices ? [] : Object.values(invoices)

  const csvColumns = [
    'ID facture',
    'Fournisseur',
    'Client',
    'Numéro',
    'Compteur',
    'Date émission',
    'Total HT',
    'Date création',
    'Date modification',
    'Template PDF',
    'Code statut',
    'Statut',
    'Code erreur',
    'Erreur',
  ]
  
  const csvData = [
    csvColumns,
    ...data.map((row) => [
      row.id,
      row.codeFournisseur,
      row.idClientEmcm,
      row.numero,
      row.compteur,
      row.dateEmission,
      row.totalHT,
      row.dateCreation,
      row.dateMaj,
      row.templatePdf,
      row.statut,
      statuses[row.statut],
      row.errorCode,
      errorsInvoice[row.errorCode],
    ]),
  ]

  return (
    <Alert
      color="info"
      css={{
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        lineHeight: 1.6,
        margin: 0,
      }}
    >
      <div
        css={{
          display: 'flex',
          '>*:not(:first-of-type)': {
            marginLeft: '1rem',
          },
        }}
      >
        <div>
          <strong>{count}</strong> / <strong>{total}</strong> factures, dont{' '}
          <strong>{errors}</strong> en erreur
        </div>
      </div>
      <HeaderButton
        color="info"
        text="Reparser le filtre"
        iconId="refresh"
        onClick={async () => {
          try {
            await dispatch(reparseFilterInvoices.request())
          } catch (error) {
            console.log(error)
          }
        }}
      /><CSVButton filename={'edata-export.csv'} data={csvData} />
    </Alert>
  )
}

export default InvoicesStats
