//** @jsxImportSource @emotion/react */
import React, { Fragment, FC } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { Formik, Form, Field, ErrorMessage, connect } from 'formik'
import * as Yup from 'yup'
import { RowRenderProps } from 'react-table'
import TableInput from './TableInput'
import { Theme } from '@emotion/react'

import { Table, Alert, CellButton } from 'library'
import {
  getIat,
  fetchIat,
  postIat,
  deleteIat,
  getNewItem,
  deleteNewItem,
} from 'store/invoices/iat'
import { getAdminProvider } from 'store/invoices/selection'

interface FormValues {
  id: number
  label: string
  iat: number
}

const Submit = connect((props: any) => {
  return (
    <CellButton
      type="submit"
      disabled={props.formik.isSubmitting}
      text="Valider"
      iconId="ok"
      width={110}
    />
  )
})

const Error: FC = ({ children }) => (
  <span
    css={(theme: Theme) => ({
      color: theme.colors.danger,
      marginLeft: '.5rem',
      fontSize: 12,
    })}
  >
    {children}
  </span>
)

const IatTable = () => {
  const dispatch = useDispatch()
  const { iat, loading, error } = useSelector(getIat)
  const provider = useSelector(getAdminProvider)
  const inputRef = React.useRef<any>(null)
  const [newItem, setNewItem] = React.useState<any>(null)
  const newIat = useSelector(getNewItem)
  const [page, setPage] = React.useState(0)
  // const [pages, setPages] = React.useState(0)

  React.useEffect(() => {
    if (newIat) {
      setNewItem(newIat)
    }
    dispatch(deleteNewItem())
  }, [dispatch, iat, newIat])

  React.useEffect(() => {
    if (provider !== null && iat === null) {
      dispatch(fetchIat.request())
    }
  }, [dispatch, iat, provider])

  const res: any =
    iat &&
    newItem &&
    Object.values(iat).find((item: any) => item.label === newItem.label)

  const defaultValues = !newItem
    ? { label: '', iat: '', id: 0 }
    : {
        label: newItem.label,
        iat: newItem.iat ? newItem.iat.toString() : '',
        id: 0,
      }

  const [editIat, setEditIat] = React.useState<number | null>(null)
  React.useEffect(() => {
    if (newItem) {
      setEditIat(res ? res.id : 0)
    }
  }, [newItem, res])

  React.useEffect(() => {
    if (editIat !== null && inputRef.current !== null) {
      inputRef.current.focus()
    }
  }, [editIat])

  const data: any[] =
    iat === null
      ? []
      : [...(editIat === 0 ? [defaultValues] : []), ...Object.values(iat)]

  const initialValues =
    data.find((item) => item.id === editIat) || defaultValues

  const columns = [
    {
      Header: 'Descriptif ligne facture',
      accessor: 'label',
      Cell: (row: RowRenderProps) => {
        const { id } = row.original
        return editIat === id ? (
          <span>
            <Field name="label" type="texte" component={TableInput} />
            <ErrorMessage name="label" component={Error} />
          </span>
        ) : (
          <div onClick={() => setEditIat(id)}>{row.value}</div>
        )
      },
    },
    {
      Header: 'IAT',
      accessor: 'iat',
      Cell: (row: RowRenderProps) => {
        const { id } = row.original
        return editIat === id ? (
          <span>
            <Field
              name="iat"
              type="number"
              min={1}
              innerRef={inputRef}
              component={TableInput}
            />
            <ErrorMessage name="iat" component={Error} />
          </span>
        ) : (
          <div onClick={() => setEditIat(id)}>{row.value}</div>
        )
      },
    },
    {
      Header: (
        <div style={{ textAlign: 'right' }}>
          <CellButton
            text="Ajouter"
            iconId="add"
            onClick={() => {
              setEditIat(0)
              setPage(0)
            }}
            disabled={editIat === 0 || !provider}
          />
        </div>
      ),
      accessor: 'id',
      width: 250,
      filterable: false,
      sortable: false,
      Cell: (row: RowRenderProps) => {
        const { id } = row.original
        return (
          <div>
            {editIat === id ? (
              <Fragment>
                <Submit />
                <CellButton
                  text="Annuler"
                  iconId="cross"
                  onClick={() => {
                    setEditIat(null)
                    setNewItem(null)
                  }}
                  width={110}
                />
              </Fragment>
            ) : (
              <Fragment>
                <CellButton
                  disabled={editIat !== null}
                  text="Modifier"
                  iconId="edit"
                  onClick={() => setEditIat(id)}
                  width={110}
                />
                <CellButton
                  disabled={editIat !== null}
                  text="Supprimer"
                  iconId="trash"
                  onClick={() => {
                    dispatch(deleteIat.request(id))
                    setNewItem(null)
                    setEditIat(null)
                  }}
                  width={110}
                />
              </Fragment>
            )}
          </div>
        )
      },
    },
  ]

  const SignupSchema = Yup.object().shape({
    label: Yup.string()
      .min(1, 'Trop court')
      .max(200, 'Trop long')
      .notOneOf(
        data
          .filter((x) => x.id !== editIat)
          .map((x) => x.label)
          .filter(
            (label) =>
              editIat !== null &&
              iat &&
              (!iat[editIat] || label !== iat[editIat].label)
          ),
        'Existe déjà'
      )
      .required('Requis'),
    iat: Yup.number().required('Requis'),
  })

  return (
    <>
      {error && <Alert>{error}</Alert>}
      <Formik
        enableReinitialize
        initialValues={initialValues}
        validationSchema={SignupSchema}
        onSubmit={async (values: FormValues, actions: any) => {
          setNewItem(null)
          setEditIat(null)
          if (provider)
            dispatch(postIat.request({ ...values, update: values.id !== 0 }))
          actions.setSubmitting(false)
          actions.resetForm()
        }}
        render={() => (
          <Form>
            <Table
              columns={columns}
              data={data}
              loading={loading}
              showPagination={true}
              showPageSizeOptions={true}
              showPageJump={true}
              filterable={true}
              defaultPageSize={10}
              minRows={5}
              page={page}
              onPageChange={(i) => {
                setPage(i)
                setEditIat(null)
              }}
              // getProps={(state: any) => {
              //   setPages(state.pages)
              //   return {}
              // }}
            />
          </Form>
        )}
      />
    </>
  )
}

export default IatTable
