//** @jsxImportSource @emotion/react */
import { useEffect, useMemo } from 'react'
import moment from 'moment'
import maxBy from 'lodash/maxBy'
import { RowRenderProps, RowInfo } from 'react-table'
import { useSearchExecutionsQuery } from 'queries/crawler'
import { Table } from 'library'
import { EnumCrawlerStatutExecution } from 'api/types'
import { BtnDeleteExecution } from './BtnDeleteExecution'
import { BtnCreateExecution } from 'components/crawler/BtnCreateExecution'

type Props = {
  value: number | null
  onChange: (value: number | null) => void
  dev?: boolean
}

export const Executions = ({ value, onChange, dev = false }: Props) => {
  const { data: executions, isFetching } = useSearchExecutionsQuery()

  const tableData = useMemo(() => {
    if (typeof executions !== 'object') return []
    return executions.map((execution) => {
      const d1 = moment(execution.dateDebut).format('DD/MM/YYYY')
      const d2 = moment(execution.dateFin).format('DD/MM/YYYY')
      return {
        ...execution,
        facturesCount: execution.factures?.length ?? 0,
        statutLabel: EnumCrawlerStatutExecution[execution.statut],
        period: d1 === d2 ? d1 : `${d1} \u2192 ${d2}`,
      }
    })
  }, [executions])

  const columns = useMemo(
    () => [
      {
        Header: 'Factures',
        accessor: 'facturesCount',
        width: 120,
      },
      {
        Header: "Date d'éxecution",
        accessor: 'dateDebut',
        Cell: (row: RowRenderProps) => <span>{row.original.period}</span>,
        width: 210,
      },
      {
        Header: 'Statut',
        accessor: 'statutLabel',
        width: 120,
      },
      {
        Header: 'Erreur',
        accessor: 'message',
      },
      ...(dev
        ? [
            {
              sortable: false,
              width: 55,
              Header: ' ',
              Cell: (row: RowRenderProps) => (
                <BtnDeleteExecution id={row.original.id} />
              ),
            },
          ]
        : []),
    ],
    [dev]
  )

  // select first execution
  useEffect(() => {
    if (value !== null) return
    if (!executions || executions.length === 0) return
    const first = maxBy(executions, 'dateDebut')
    if (!first) return
    onChange(first.id)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [executions, value])

  // unselect if value not found
  useEffect(() => {
    if (value === null) return
    if (!executions || executions.some((e) => e.id === value)) return
    onChange(null)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [executions, value])

  return (
    <div css={{ '> * + *': { marginTop: '1.5rem' } }}>
      <Table
        columns={columns}
        data={tableData}
        loading={isFetching}
        filterable={false}
        sortable={true}
        defaultPageSize={10}
        pageSizeOptions={[10, 15, 20]}
        defaultSorted={[
          {
            id: 'dateDebut',
            desc: true,
          },
        ]}
        getTrProps={(state: unknown, rowInfo: RowInfo | undefined) => {
          return {
            onClick: () => {
              onChange(rowInfo?.original.id)
            },
            style: {
              cursor: 'pointer',
              boxShadow:
                rowInfo?.original.id === value
                  ? 'inset 0 0 0 1px var(--green)'
                  : 'none',
            },
          }
        }}
      />

      {dev && (
        <div>
          <BtnCreateExecution />
        </div>
      )}
    </div>
  )
}
