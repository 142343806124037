import { useEffect, useState } from 'react'

const usePage = (document: any, index: number) => {
  const [page, setPage] = useState<any>(null)
  const [pageWidth, setPageWidth] = useState<any>(null)

  useEffect(() => {
    let page: any = null

    const getPage = async () => {
      try {
        // console.log(`pdf: loading page #${index}`)
        page = await document.getPage(index)
        setPage(page)
        const unscaledViewport = page.getViewport({ scale: 1 })
        setPageWidth(unscaledViewport.width)
      } catch (error) {
        console.log(error)
      }
    }

    getPage()

    return () => {
      if (page && !page.destroyed) {
        // console.log(`pdf: cleanup page #${index}`)
        page.cleanup(true)
      }
    }
  }, [index, document])

  return { page, pageWidth }
}

export default usePage
