//** @jsxImportSource @emotion/react */
import React, { FC } from 'react'
import { useDropzone, DropzoneRootProps } from 'react-dropzone'
import { useSelector } from 'react-redux'

import { getSelection } from 'store/invoices/selection'
import { Loader } from 'library'

const baseStyle = {
  flex: 1,
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  height: 200,
  padding: '20px',
  borderWidth: 2,
  borderRadius: 2,
  borderColor: '#eeeeee',
  borderStyle: 'dashed',
  backgroundColor: '#fafafa',
  color: '#61747F',
  outline: 'none',
  transition: 'border .24s ease-in-out',
}

const activeStyle = {
  borderColor: '#2196f3',
}

const acceptStyle = {
  borderColor: '#00e676',
}

const rejectStyle = {
  borderColor: '#ff1744',
}

interface Props {
  onDrop: any
  style?: object
  loading: boolean
  mimeTypes: Array<string>
}

const Dropzone: FC<Props> = ({ onDrop, style: userStyle, loading, mimeTypes }) => {
  const { provider, client } = useSelector(getSelection)

  const {
    // acceptedFiles,
    getRootProps,
    getInputProps,
    isDragActive,
    isDragAccept,
    isDragReject,
  } = useDropzone({
    accept: mimeTypes.join(','),
    onDrop,
  })

  const style = React.useMemo(
    () => ({
      ...baseStyle,
      ...(isDragActive ? activeStyle : {}),
      ...(isDragAccept ? acceptStyle : {}),
      ...(isDragReject ? rejectStyle : {}),
      ...userStyle,
    }),
    [isDragAccept, isDragActive, isDragReject, userStyle]
  )

  const disabled = loading || !provider || !client

  return (
    <div {...getRootProps({ style: style as DropzoneRootProps })}>
      <input disabled={disabled} {...getInputProps()} />
      {loading ? (
        <Loader />
      ) : (
        <div style={{ opacity: disabled ? 0.5 : 1 }}>
          Déposez vos fichiers ici, ou cliquez pour sélectionner des fichiers.
        </div>
      )}
    </div>
  )
}

export default Dropzone
