//** @jsxImportSource @emotion/react */
import React, { FC } from 'react'
import ReactTable, { TableProps } from 'react-table'
import selectTableHOC from 'react-table/lib/hoc/selectTable'

import { useTranslation } from 'react-i18next'
import { Loader } from 'library'

const translations = (t: (path: string) => string) => ({
  rowsText: t('reactTable.rowsText'),
  ofText: t('reactTable.ofText'),
  previousText: t('reactTable.previousText'),
  nextText: t('reactTable.nextText'),
  loadingText: t('reactTable.loadingText'),
  noDataText: t('reactTable.noDataText'),
})

const LoadingComponent = ({ loading = true }) => {
  if (!loading) return null
  return (
    <div className="-loading -active">
      <div className="-loading-inner">
        <Loader />
      </div>
    </div>
  )
}

interface Props {
  sm?: boolean
}

export const Table: FC<Props & Partial<TableProps>> = (props) => {
  const { sm = false } = props
  const { t } = useTranslation()
  const showHeader =
    props.showPagination || props.showPageSizeOptions || props.showPageJump
  return (
    <ReactTable
      className="-striped"
      LoadingComponent={LoadingComponent}
      NoDataComponent={() => null}
      defaultFilterMethod={(filter, row) => {
        const id = filter.pivotId || filter.id
        return row[id] !== undefined
          ? String(row[id]).includes(filter.value)
          : true
      }}
      css={{
        fontSize: sm ? 12 : 14,
        background: '#fff',
        '.-sort-asc': {
          boxShadow: 'inset 0 3px 0 0 #d7dee4 !important',
        },
        '.-sort-desc': {
          boxShadow: 'inset 0 -3px 0 0 #d7dee4 !important',
        },
        '.-loading-inner': {
          transform: `translateY(${showHeader ? '-50%' : 0}) !important`,
        },
        '.rt-table': {
          overflow: sm ? 'visible' : 'hidden',
        },
        '.rt-tbody': {
          overflow: sm ? 'visible' : 'hidden',
        },
        '.rt-tr-group': {
          borderBottom: '0 !important',
        },
        '.rt-td, .rt-th>div': {
          textAlign: 'left',
          padding: '0 12px !important',
          verticalAlign: 'middle',
          lineHeight: sm ? '32px' : '34px',
          height: sm ? 30 : 32,
          input: {
            lineHeight: 'normal',
          },
        },
        '.rt-td': {
          padding: sm ? '0 4px !important' : '0 12px !important',
          overflow: sm ? 'visible' : 'hidden',
        },
        '.rt-thead.-header': {
          textTransform: 'uppercase',
          fontSize: sm ? 11.5 : 12,
          fontWeight: 700,
          color: 'rgb(140, 153, 165)',
          letterSpacing: 0.5,
          boxShadow: '0 2px 15px 0 hsla(200, 33, 50, 0.2) !important',
          '.rt-th': {
            padding: '0 !important',
          },
        },
        '.rt-expandable, .rt-expander::after': {
          cursor: 'default !important',
          userSelect: 'none',
        },
        '&.-striped .rt-tr.-odd': {
          background: 'rgba(243, 247, 249, .5) !important',
        },
      }}
      {...translations(t)}
      {...props}
    />
  )
}

const ReactSelectTable = selectTableHOC(ReactTable)

interface SelectTableProps {
  keyField: string
  selection: string[]
  setSelection: (selection: string[]) => void
}

export const SelectTable: FC<Props &
  Partial<TableProps> &
  SelectTableProps> = ({ selection, setSelection, ...props }) => {
  const ref = React.useRef<any>(null)
  const [selectAll, setSelectAll] = React.useState(false)

  const toggleSelection = React.useCallback(
    (key: string, shift: any, row: any) => {
      const keyIndex = selection.indexOf(key)
      if (keyIndex >= 0) {
        setSelection([
          ...selection.slice(0, keyIndex),
          ...selection.slice(keyIndex + 1),
        ])
      } else {
        setSelection([...selection, key])
      }
    },
    [selection, setSelection]
  )

  const toggleAll = React.useCallback(() => {
    if (!selectAll) {
      const wrappedInstance = ref.current.getWrappedInstance()
      const currentRecords = wrappedInstance.getResolvedState().sortedData
      setSelection(
        currentRecords.map(
          (item: any) => `select-${item._original[props.keyField]}`
        )
      )
    } else {
      setSelection([])
    }
    setSelectAll(!selectAll)
  }, [props.keyField, selectAll, setSelection])

  const { sm = false } = props
  const { t } = useTranslation()
  const showHeader =
    props.showPagination || props.showPageSizeOptions || props.showPageJump

  return (
    <ReactSelectTable
      className="-striped"
      LoadingComponent={LoadingComponent}
      NoDataComponent={() => null}
      defaultFilterMethod={(filter, row) => {
        const id = filter.pivotId || filter.id
        return row[id] !== undefined
          ? String(row[id]).includes(filter.value)
          : true
      }}
      css={{
        fontSize: sm ? 12 : 14,
        background: '#fff',
        '.-sort-asc': {
          boxShadow: 'inset 0 3px 0 0 #d7dee4 !important',
        },
        '.-sort-desc': {
          boxShadow: 'inset 0 -3px 0 0 #d7dee4 !important',
        },
        '.-loading-inner': {
          transform: `translateY(${showHeader ? '-50%' : 0}) !important`,
        },
        '.rt-table': {
          overflow: sm ? 'visible' : 'hidden',
        },
        '.rt-tbody': {
          overflow: sm ? 'visible' : 'hidden',
        },
        '.rt-tr-group': {
          borderBottom: '0 !important',
        },
        '.rt-td, .rt-th>div': {
          textAlign: 'left',
          padding: '0 12px !important',
          verticalAlign: 'middle',
          lineHeight: sm ? '32px' : '34px',
          height: sm ? 30 : 32,
          input: {
            lineHeight: 'normal',
          },
        },
        '.rt-td': {
          padding: sm ? '0 4px !important' : '0 12px !important',
          overflow: sm ? 'visible' : 'hidden',
        },
        '.rt-thead.-header': {
          textTransform: 'uppercase',
          fontSize: sm ? 11.5 : 12,
          fontWeight: 700,
          color: 'rgb(140, 153, 165)',
          letterSpacing: 0.5,
          boxShadow: '0 2px 15px 0 hsla(200, 33, 50, 0.2) !important',
          '.rt-th': {
            padding: '0 !important',
          },
        },
        '.rt-expandable, .rt-expander::after': {
          cursor: 'default !important',
          userSelect: 'none',
        },
        '&.-striped .rt-tr.-odd': {
          background: 'rgba(243, 247, 249, .5) !important',
        },
      }}
      {...translations(t)}
      ref={ref}
      toggleSelection={toggleSelection}
      selectAll={selectAll}
      selectType="checkbox"
      toggleAll={toggleAll}
      isSelected={(key) => selection.includes(`select-${key}`)}
      {...props}
    />
  )
}
