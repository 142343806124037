//** @jsxImportSource @emotion/react */
import { useEffect } from 'react'
import { useDispatch } from 'react-redux'

import { Section } from 'library'

import ProviderSelect from '../filters/ProviderSelect'
import ClientSelect from '../filters/ClientSelect'
import StatusSelect from '../filters/StatusSelect'
import StartDate from '../filters/StartDate'
import EndDate from '../filters/EndDate'
import PdlInput from '../filters/PdlInput'
import FileTypeSelect from '../filters/FileTypeSelect'

import useInvoiceDeletionNotif from './useInvoiceDeletionNotif'
import InvoicesTable from './InvoicesTable'
import InvoicesStats from './InvoicesStats'

import { fetchInvoices } from 'store/invoices/invoices'
import CreatedDate from '../filters/CreatedDate'
import UpdatedDate from '../filters/UpdatedDate'
import InvoiceNumberInput from '../filters/InvoiceNumberInput'
import TemplateNameInput from '../filters/TemplateNameInput'
import StatusErrorSelect from '../filters/StatusErrorSelect'
import StatusLineErrorSelect from '../filters/StatusLineErrorSelect'

const Suivi = () => {
  useInvoiceDeletionNotif()
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(fetchInvoices.request())
  }, [dispatch])

  return (
    <Section>
      <div
        css={{
          display: 'grid',
          gridTemplateColumns: '100%',
          gridGap: 15,
        }}
      >
        <header
          css={{
            display: 'grid',
            gridTemplateColumns: 'repeat(7, minmax(150px, 220px))',
            gridGap: 20,
          }}
        >
          <ProviderSelect showAllOption />
          <ClientSelect showAllOption />
          <StartDate mandatory />
          <EndDate mandatory />
          <StatusSelect />
          <PdlInput />
          <FileTypeSelect />

          <CreatedDate />
          <UpdatedDate />
          <InvoiceNumberInput />
          <TemplateNameInput />
          <StatusErrorSelect />
          <StatusLineErrorSelect />
        </header>

        <InvoicesStats />
        <InvoicesTable />
      </div>
    </Section>
  )
}

export default Suivi
