import { useDeleteFactureMutation } from 'queries/crawler'
import { CellButton } from 'library'

type Props = {
  execId: number
  facId: number
}

export const BtnDeleteFacture = ({ execId, facId }: Props) => {
  const { mutate, isLoading } = useDeleteFactureMutation(execId, facId)
  return (
    <CellButton
      iconId="trash"
      color="danger"
      style={{ margin: 0 }}
      disabled={isLoading}
      onClick={(e) => {
        e.stopPropagation()
        mutate()
      }}
    />
  )
}
