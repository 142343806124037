import { useDispatch, useSelector } from 'react-redux'
import isEqual from 'lodash/isEqual'

import { setStatus, getStatus } from 'store/invoices/selection'
import { Select, FormGroup } from 'library'
import { statuses } from 'labels'

const options = [
  { value: [], label: 'Tous' },
  ...['AE', 'EE', 'AI', 'EI', 'I'].map((id) => ({
    value: [id],
    label: statuses[id],
  })),
]

const StatusSelect = () => {
  const dispatch = useDispatch()
  const selected = useSelector(getStatus)

  return (
    <FormGroup label="Statut">
      <Select
        value={options.filter((option) => isEqual(option.value, selected))}
        options={options}
        isLoading={false}
        onChange={(option: any) => {
          dispatch(setStatus(option.value))
        }}
      />
    </FormGroup>
  )
}

export default StatusSelect
