//** @jsxImportSource @emotion/react */
import { useEffect } from 'react'
import { useDispatch } from 'react-redux'

import { Section } from 'library'

import ProviderSelect from '../filters/ProviderSelect'
import ClientSelect from '../filters/ClientSelect'
import StartDate from '../filters/StartDate'
import EndDate from '../filters/EndDate'
import PdfStatusSelect from '../filters/PdfStatusSelect'
import PdlInput from '../filters/PdlInput'
import InvoiceNumberInput from '../filters/InvoiceNumberInput'
import CreatedDate from '../filters/CreatedDate'
import UpdatedDate from '../filters/UpdatedDate'

// import useParsingNotif from './useParsingNotif'
import PdfsTable from './PdfsTable'
import PdfsStats from './PdfsStats'

import { fetchPdfs } from 'store/invoices/pdfs'

const Pdfs = () => {
  // useParsingNotif()
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(fetchPdfs.request())
  }, [dispatch])

  return (
    <Section>
      <div
        css={{
          display: 'grid',
          gridTemplateColumns: '100%',
          gridGap: 20,
        }}
      >
        <header
          css={{
            display: 'grid',
            gridTemplateColumns: 'repeat(6, minmax(160px, 220px))',
            gridGap: 20,
          }}
        >
          <ProviderSelect showAllOption />
          <ClientSelect showAllOption />
          <StartDate />
          <EndDate />
          <PdfStatusSelect />
          <PdlInput />
          <CreatedDate />
          <UpdatedDate />
          <InvoiceNumberInput />
        </header>

        <PdfsStats />
        <PdfsTable />
      </div>
    </Section>
  )
}

export default Pdfs
