import React, { FC } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { setFileType, getFileType } from 'store/invoices/selection'
import { Select, FormGroup } from 'library'

const options = [
  { label: 'Tous', value: null },
  { label: 'PDF', value: 'PDF' },
  { label: 'XLS', value: 'XLS' },
]

interface Props {
  inline?: boolean
  width?: number
}

const FileTypeSelect: FC<Props> = ({ inline, width }) => {
  const dispatch = useDispatch()
  const selected = useSelector(getFileType)

  return (
    <FormGroup label="Type de facture" inline={inline}>
      <Select
        value={options.filter((option) => option.value === selected)}
        options={options}
        width={width}
        onChange={(option: any) => {
          dispatch(setFileType(option ? option.value : 0))
        }}
        placeholder=""
      />
    </FormGroup>
  )
}

export default FileTypeSelect
