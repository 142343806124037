//** @jsxImportSource @emotion/react */

import { Section } from 'library'
import ProviderSelect from '../filters/ProviderSelect'
import ClientSelect from '../filters/ClientSelect'
import { ImportPdf } from './ImportPdf'
import { ImportXml } from './ImportXml'

export default function Upload() {
  return (
    <>
      <Section>
        <header
          css={{
            display: 'flex',
            '>*:not(:first-of-type)': { marginLeft: '2rem' },
            'marginBottom': '3rem',
          }}
        >
          <ProviderSelect inline width={200} />
          <ClientSelect inline width={200} />
        </header>

        <div css={{
          display: 'flex',
          '>*': {
            width: '50%',
            '&:not(:first-of-type)': { marginLeft: '2rem' }
          },
        }}>
          <ImportPdf />
          <ImportXml />
        </div>
      </Section>
    </>
  )
}
