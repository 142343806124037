//** @jsxImportSource @emotion/react */
import { ComponentProps } from 'react'
import { Icon } from 'library'
import { Button } from 'reactstrap'

type TableBtnProps = Pick<ComponentProps<'button'>, 'onClick'> & {
  text?: React.ReactChild
  iconId?: string
  type?: 'button' | 'submit' | 'reset' | undefined
  disabled?: boolean
  width?: number | string
  color?: string
  title?: string
  style?: object
}

export const CellButton = ({
  text,
  iconId,
  onClick = () => {},
  type,
  width = 'unset',
  disabled = false,
  color = 'light',
  title,
  style = {},
}: TableBtnProps) => {
  return (
    <div css={{ width, display: 'inline-block' }}>
      <Button
        disabled={disabled}
        color={color}
        onClick={onClick}
        type={type}
        title={title}
        css={{
          backgroundColor: color === 'light' ? 'transparent' : undefined,
          border: 0,
          padding: '2.4px 6.4px 1.6px',
          margin: '0 .3rem',
          fontSize: 12,
          '&:disabled': {
            opacity: 0.2,
          },
          svg: {
            position: 'relative',
            top: 2,
            marginRight: text ? '.4rem' : 0,
          },
          ...style,
        }}
      >
        {iconId && <Icon id={iconId} size={14} />}
        {text && text}
      </Button>
    </div>
  )
}

export const HeaderButton = ({
  text,
  iconId,
  onClick = () => {},
  type = 'button',
  disabled = false,
  color = 'primary',
  iconRight = false,
  style = {},
}: {
  text: string
  iconId?: string
  onClick?: () => void
  disabled?: boolean
  type?: 'button' | 'submit' | 'reset' | undefined
  color?: string
  iconRight?: boolean
  style?: object
}) => {
  const ButtonIcon: JSX.Element | null = iconId ? (
    <Icon id={iconId} size={14} />
  ) : null

  return (
    <Button
      disabled={disabled}
      color={color}
      onClick={onClick}
      type={type}
      css={{
        fontSize: 14,
        '&:disabled': {
          opacity: 0.2,
        },
        svg: {
          position: 'relative',
          top: 2,
          margin: iconRight ? '0 0 0 .4rem' : '0 .4rem 0 0',
        },
        ...style,
      }}
    >
      {!iconRight && ButtonIcon}
      {text}
      {iconRight && ButtonIcon}
    </Button>
  )
}
