import React, { FC } from 'react'
import { connect } from 'react-redux'
import { Switch, Route } from 'react-router-dom'
import get from 'lodash/get'

import userManager from 'userManager'
import Callback from 'components/Callback'
import SilentRedirectPage from 'components/SilentRedirectPage'
import AccessDenied from 'components/AccessDenied'
import Logout from 'components/AccessDenied'
import AppLayout from 'components/AppLayout'
import { RootState } from 'store'

interface User {
  expired: boolean
  profile: {
    role: string[] | string
  }
}

const isExpired = (user: User) => !user || !!user.expired
const isDenied = (user: User) => {
  const role = get(user, ['profile', 'role'], false)
  if (!role) return true
  const requiredRoles = ['superuser', 'read:esite', 'admin:esite']
  return Array.isArray(role)
    ? !role.some((r) => requiredRoles.includes(r))
    : !requiredRoles.includes(role)
}

interface Props {
  user: User
  isLoadingUser: boolean
}

const Root: FC<Props> = (props) => {
  const { user, isLoadingUser } = props
  const [isRedirecting, setIsRedirecting] = React.useState(false)

  React.useEffect(() => {
    const isCallback = window.location.pathname.startsWith('/callback')
    const isSilentRenew = window.location.pathname.startsWith('/silent_renew')
    if (
      !isCallback &&
      !isSilentRenew &&
      !user &&
      !isLoadingUser &&
      !isRedirecting
    ) {
      setTimeout(() => {
        setIsRedirecting(true)
        userManager.signinRedirect()
      })
    }
  }, [user, isLoadingUser, isRedirecting])

  return isExpired(user) ? (
    <Switch>
      <Route exact path="/silent_renew" component={SilentRedirectPage} />
      <Route path="/" component={Callback} />
    </Switch>
  ) : isDenied(user) ? (
    <Switch>
      <Route path="/" component={AccessDenied} />
    </Switch>
  ) : (
    <Switch>
      <Route exact path="/silent_renew" component={SilentRedirectPage} />
      <Route path="/logout" component={Logout} />
      <Route path="/callback" component={Callback} />
      <Route render={() => <AppLayout />} />
    </Switch>
  )
}

const mapState = (state: RootState) => ({
  isLoadingUser: state.oidc.isLoadingUser,
  user: state.oidc.user,
})

export default connect(mapState)(Root as any)
