import { UserState } from 'redux-oidc'
import getOr from 'lodash/fp/getOr'
import get from 'lodash/fp/get'
import negate from 'lodash/fp/negate'
import castArray from 'lodash/fp/castArray'
import compose from 'lodash/fp/compose'

interface State {
  oidc: UserState
  user: {
    userInfo: {
      LanId: number
      IsAdmin: boolean
      CodePays: number
    }
    userPostError: string
    userPostSuccess: boolean
  }
}

export const getRoles = compose(getOr([], 'oidc.user.profile.role'), castArray)

export const getUser = getOr(false, 'oidc.user')
export const getPlatform = get('oidc.user.profile.pla')
export const getEid = get('oidc.user.profile.eid')
export const getName = getOr(false, 'oidc.user.profile.name')
export const getUor = getOr(false, 'oidc.user.profile.uor')
export const isLoggedIn = negate(getOr(true, 'oidc.user.expired'))
export const getLanId = getOr(false, 'user.userInfo.lanId')

const lngIsoStr: (lanId: number) => string = (lanId) =>
  lanId === 2 ? 'en' : 'fr'

export const lanId = (state: State) => {
  if (state.user && state.user.userInfo && state.user.userInfo.LanId)
    return state.user.userInfo.LanId
  if (state.oidc && state.oidc.user === null) return 1
  return (
    (state.oidc &&
      state.oidc.user &&
      state.oidc.user.profile &&
      state.oidc.user.profile.lan) ||
    1
  )
}

export const lngIso = compose(lngIsoStr, lanId)
