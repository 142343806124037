import moment from 'moment'
import { createStructuredSelector } from 'reselect'
import { createAction, createReducer, ActionType } from 'typesafe-actions'
import { RootState } from 'store'

/* State */

type CrawlerState = {
  supplier: string | null
  customer: number | null
  startDate: string
  endDate: string | null
  isPeriod: boolean
}

const initialState: CrawlerState = {
  supplier: null,
  customer: null,
  startDate: moment()
    .subtract(6, 'month')
    .format('YYYY-MM-DD'),
  endDate: moment().format('YYYY-MM-DD'),
  isPeriod: false,
}

/* Actions */

export const setSupplier = createAction(
  '@data-mgmt/invoices/crawler/SET_SUPPLIER',
  (action) => (id: string) => action(id)
)
export const setCustomer = createAction(
  '@data-mgmt/invoices/crawler/SET_CUSTOMER',
  (action) => (id: number) => action(id)
)
export const setStartDate = createAction(
  '@data-mgmt/invoices/crawler/SET_START_DATE',
  (action) => (date: string) => action(date)
)
export const setEndDate = createAction(
  '@data-mgmt/invoices/crawler/SET_END_DATE',
  (action) => (date: string | null) => action(date)
)
export const setIsPeriod = createAction(
  '@data-mgmt/invoices/crawler/SET_IS_PERIOD',
  (action) => (isPeriod: boolean) => action(isPeriod)
)

const actions = {
  setSupplier,
  setCustomer,
  setStartDate,
  setEndDate,
  setIsPeriod,
}

export type CrawlerAction = ActionType<typeof actions>

/* Reducer */

export default createReducer<CrawlerState, CrawlerAction>(initialState)
  .handleAction(setSupplier, (state, action) => ({
    ...state,
    supplier: action.payload,
  }))
  .handleAction(setCustomer, (state, action) => ({
    ...state,
    customer: action.payload,
  }))
  .handleAction(setStartDate, (state, action) => ({
    ...state,
    startDate: action.payload,
  }))
  .handleAction(setEndDate, (state, action) => ({
    ...state,
    endDate: action.payload,
  }))
  .handleAction(setIsPeriod, (state, action) => ({
    ...state,
    isPeriod: action.payload,
  }))

/* Selectors */

export const getSupplier = (state: RootState) => state.invoices.crawler.supplier
export const getCustomer = (state: RootState) => state.invoices.crawler.customer
export const getStartDate = (state: RootState) =>
  state.invoices.crawler.startDate
export const getEndDate = (state: RootState) => state.invoices.crawler.endDate
export const getIsPeriod = (state: RootState) => state.invoices.crawler.isPeriod

export const getOptions = createStructuredSelector<
  RootState,
  Pick<
    CrawlerState,
    'supplier' | 'customer' | 'startDate' | 'endDate' | 'isPeriod'
  >
>({
  supplier: getSupplier,
  customer: getCustomer,
  startDate: getStartDate,
  endDate: getEndDate,
  isPeriod: getIsPeriod,
})
