import { Theme } from '@emotion/react'

const theme: Theme = {
  colors: {
    primary: '#00c793',
    secondary: '#61687f',
    success: '#00c793',
    info: '#17a2b8',
    warning: '#ffc107',
    danger: '#ff4669',
    light: '#f8f9fa',
    dark: '#2e313d',
  },
}

export default theme
