import { useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { Redirect } from 'react-router-dom'
import moment from 'moment'
import { RowRenderProps } from 'react-table'
import { Trans } from 'react-i18next'

import { SelectTable, CellButton, HeaderButton } from 'library'
import { getProviderLabel } from 'store/invoices/providers'
import { getClientLabel } from 'store/invoices/clients'
import {
  getInvoices,
  fetchInvoices,
  setPageSize,
  getPageSize,
  setPageIndex,
  setPageSort,
  getPageIndex,
  deleteInvoices,
} from 'store/invoices/invoices'
import { StatusCell } from './Status'
import FileIcon from '../FileIcon'
import { errorsInvoice } from 'labels'

const formatDate = (date: string, format: string = 'DD/MM/YYYY') => {
  const m = moment(date)
  return m.isValid() ? m.format(format) : null
}

const ProviderCell = ({ value }: RowRenderProps) => {
  const label = useSelector(getProviderLabel(value))
  return <div>{label}</div>
}

const ClientCell = ({ value }: RowRenderProps) => {
  const label = useSelector(getClientLabel(value))
  return <div>{label}</div>
}

const InvoicesTable = () => {
  const [selection, setSelection] = useState<string[]>([])
  const [redirect, setRedirect] = useState<string | null>(null)
  const dispatch = useDispatch()
  const { invoices, loading, total } = useSelector(getInvoices)
  const pageSize = useSelector(getPageSize)
  const pageIndex = useSelector(getPageIndex)

  useEffect(() => {
    if (!invoices) dispatch(fetchInvoices.request())
  }, [dispatch, invoices])

  if (redirect) return <Redirect push to={redirect} />

  const columns = [
    {
      accessor: 'pdfId',
      width: 50,
      Cell: (row: RowRenderProps) => <FileIcon isPdf={!!row.value} />,
    },
    {
      Header: 'Fournisseur',
      accessor: 'codeFournisseur',
      width: 120,
      Cell: ProviderCell,
    },
    {
      Header: 'Client',
      accessor: 'idClientEmcm',
      width: 190,
      Cell: ClientCell,
    },
    {
      Header: 'Numéro',
      width: 200,
      accessor: 'numero',
    },
    {
      filterable: false,
      Header: 'Compteur',
      accessor: 'compteur',
    },
    {
      filterable: false,
      Header: 'Émission',
      width: 120,
      accessor: 'dateEmission',
      Cell: (row: RowRenderProps) => <span>{formatDate(row.value)}</span>,
    },
    {
      filterable: true,
      Header: 'Total HT',
      width: 80,
      accessor: 'totalHT',
      Cell: (row: RowRenderProps) => <span>{row.value}</span>,
    },
    {
      filterable: false,
      Header: 'Création',
      width: 150,
      accessor: 'dateCreation',
      Cell: (row: RowRenderProps) => (
        <span>{formatDate(row.value, 'DD/MM/YYYY HH:mm:ss')}</span>
      ),
    },
    {
      filterable: false,
      Header: 'Date Maj',
      width: 150,
      accessor: 'dateMaj',
      Cell: (row: RowRenderProps) => (
        <span>{formatDate(row.value, 'DD/MM/YYYY HH:mm:ss')}</span>
      ),
    },
    {
      filterable: false,
      Header: 'Template',
      width: 150,
      accessor: 'templatePdf',
    },
    {
      filterable: false,
      maxWidth: 220,
      Header: 'État',
      accessor: 'statut',
      Cell: StatusCell,
    },
    {
      filterable: false,
      maxWidth: 220,
      Header: 'Erreur',
      accessor: 'errorCode',
      Cell: (row: RowRenderProps) => (
        <span>{errorsInvoice[row.value]}</span>
      ),
    },
    {
      filterable: false,
      width: 80,
      Cell: (row: RowRenderProps) => {
        const { id } = row.original
        return (
          <div>
            <CellButton
              iconId="edit"
              title="Accéder au détail"
              style={{ margin: 0 }}
              onClick={() => setRedirect(`/factures/details/${id}`)}
            />
          </div>
        )
      },
    }
  ]

  const data = !invoices ? [] : Object.values(invoices)

  return (
    <>
      <SelectTable
        columns={columns}
        manual
        data={data}
        page={pageIndex}
        pages={Math.ceil(total / pageSize)}
        loading={loading}
        showPagination={true}
        showPageSizeOptions={true}
        showPageJump={true}
        filterable={false}
        sortable={true}
        minRows={10}
        defaultPageSize={pageSize}
        pageSizeOptions={[10, 15, 20, 25, 50, 75, 100, 500]}
        onPageSizeChange={(pageSize, pageIndex) => {
          dispatch(setPageSize(pageSize))
          dispatch(setPageIndex(pageIndex))
          dispatch(fetchInvoices.request())
        }}
        onPageChange={(pageIndex) => {
          dispatch(setPageIndex(pageIndex))
          dispatch(fetchInvoices.request())
        }}
        onSortedChange={(newSorted, column, additive) => {
          dispatch(
            setPageSort(newSorted[0].id, newSorted[0].desc === false ? 1 : 0)
          )
          dispatch(fetchInvoices.request())
        }}
        style={{ fontSize: 12 }}
        keyField="id"
        selection={selection}
        setSelection={setSelection}
      />

      {selection.length > 0 && (
        <div style={{ marginTop: '1rem', lineHeight: 1 }}>
          <Trans i18nKey="factures.suppression" count={selection.length}>
            Supprimer les <strong>{{ count: selection.length }}</strong>{' '}
            factures
          </Trans>
          <HeaderButton
            iconId="trash"
            text="Supprimer"
            color="danger"
            style={{ marginLeft: '1rem', padding: '0.25rem 0.5rem' }}
            onClick={async () => {
              try {
                const ids = selection.map((item) =>
                  Number(item.replace('select-', ''))
                )
                await dispatch(deleteInvoices.request(ids))
                setSelection([])
              } catch (error) {
                console.log(error)
              }
            }}
          />
        </div>
      )}
    </>
  )
}

export default InvoicesTable
