//** @jsxImportSource @emotion/react */
import { FC, useCallback } from 'react'

import downloadFile from '../../helpers/downloadFile'
import { formatCSV } from '../../helpers/csv'
import { HeaderButton } from 'library'

const CSVButton: FC<any> = (
  { filename = 'export.csv', data, className, style = {}, disabled = false, ... props }) => {
  const handleClick = useCallback(
    () => downloadFile(filename, formatCSV(data), { type: 'text/csv' }),
    [filename, data],
  )

  return (
    <HeaderButton
        color="info"
        text="Export CSV"
        iconId="open"
        onClick={handleClick}
      />
  )
}

export default CSVButton
