import { from, of, pipe } from 'rxjs'
import {
  filter,
  switchMap,
  flatMap,
  map,
  mapTo,
  catchError,
  withLatestFrom,
  debounceTime,
} from 'rxjs/operators'
import { combineEpics } from 'redux-observable'
import { createStructuredSelector, createSelector } from 'reselect'
import keyBy from 'lodash/keyBy'
import {
  createAction,
  createReducer,
  ActionType,
  isActionOf,
  createAsyncAction,
} from 'typesafe-actions'

import { superFetch, checkStatus } from 'helpers/apiConf'
import { RootState, RootAction } from 'store'

import {
  setProvider,
  setClient,
  setStartDate,
  setEndDate,
  setStatus,
  setPdl,
  setCreatedDate,
  setUpdatedDate,
  getSelection,
  getStatus,
  setInvoice,
  setFileType,
  setInvoiceNumber,
  setTemplateName,
  setErrorCodes,
  setLineErrorCodes,
} from './selection'

// Types
interface Invoice {
  id: number
  number: number
}

export interface Invoices {
  [id: number]: Invoice
}

export interface InvoicesState {
  total: number
  errors: number
  items: Invoices | null
  loading: boolean
  error: string | null
  pageSize: number
  pageIndex: number
  pageSort: string | null
  sortDirection: number | 0
  deleting: boolean
  deleteError: string | null
  reparsing: boolean
  reparseError: string | null
}

// Actions
interface FetchResponse {
  items: Invoices
  total: number
  errors: number
}

export const fetchInvoices = createAsyncAction(
  '@data-mgmt/invoices/invoices/FETCH_INVOICES_REQUEST',
  '@data-mgmt/invoices/invoices/FETCH_INVOICES_SUCCESS',
  '@data-mgmt/invoices/invoices/FETCH_INVOICES_FAILURE'
)<undefined, FetchResponse, Error>()

export const setPageSort = createAction(
  '@data-mgmt/invoices/invoices/SET_INVOICES_PAGE_SORT',
  (action) => (pageSort: string, sortDirection: number) =>
    action({ pageSort, sortDirection })
)

export const setPageIndex = createAction(
  '@data-mgmt/invoices/invoices/SET_INVOICES_PAGE_INDEX',
  (action) => (pageIndex: number) => action(pageIndex)
)

export const setPageSize = createAction(
  '@data-mgmt/invoices/invoices/SET_INVOICES_PAGE_SIZE',
  (action) => (pageSize: number) => action(pageSize)
)

export const deleteInvoice = createAsyncAction(
  '@data-mgmt/invoices/invoices/DELETE_INVOICE_REQUEST',
  '@data-mgmt/invoices/invoices/DELETE_INVOICE_SUCCESS',
  '@data-mgmt/invoices/invoices/DELETE_INVOICE_FAILURE'
)<number, number, Error>()

export const deleteInvoices = createAsyncAction(
  '@data-mgmt/invoices/invoices/DELETE_INVOICES_REQUEST',
  '@data-mgmt/invoices/invoices/DELETE_INVOICES_SUCCESS',
  '@data-mgmt/invoices/invoices/DELETE_INVOICES_FAILURE'
)<number[], undefined, Error>()

export const reparseInvoices = createAsyncAction(
  '@data-mgmt/invoices/invoices/REPARSE_INVOICES_REQUEST',
  '@data-mgmt/invoices/invoices/REPARSE_INVOICES_SUCCESS',
  '@data-mgmt/invoices/invoices/REPARSE_INVOICES_FAILURE'
)<number[], undefined, Error>()

export const reparseFilterInvoices = createAsyncAction(
  '@data-mgmt/invoices/invoices/REPARSE_FILTER_INVOICES_REQUEST',
  '@data-mgmt/invoices/invoices/REPARSE_FILTER_INVOICES_SUCCESS',
  '@data-mgmt/invoices/invoices/REPARSE_FILTER_INVOICES_FAILURE'
)<undefined, undefined, Error>()

export const reparseFilterPdfs = createAsyncAction(
  '@data-mgmt/invoices/invoices/REPARSE_FILTER_PDFS_REQUEST',
  '@data-mgmt/invoices/invoices/REPARSE_FILTER_PDFS_SUCCESS',
  '@data-mgmt/invoices/invoices/REPARSE_FILTER_PDFS_FAILURE'
)<undefined, undefined, Error>()

const actions = {
  fetchInvoices,
  setPageSize,
  setPageIndex,
  setPageSort,
  deleteInvoice,
  deleteInvoices,
  reparseInvoices,
  reparseFilterInvoices,
  reparseFilterPdfs,
}
export type InvoicesAction = ActionType<typeof actions>

// Reducer
const initialState = {
  total: 0,
  errors: 0,
  items: null,
  loading: false,
  error: null,
  pageSize: 15,
  pageIndex: 0,
  pageSort: null,
  sortDirection: 0,
  deleting: false,
  reparsing: false,
  deleteError: null,
  reparseError: null,
}

export default createReducer<InvoicesState, InvoicesAction>(initialState)
  .handleAction(fetchInvoices.request, (state) => ({
    ...state,
    loading: true,
    error: null,
  }))
  .handleAction(fetchInvoices.success, (state, action) => ({
    ...state,
    loading: false,
    error: null,
    total: action.payload.total,
    items: action.payload.items,
    errors: action.payload.errors,
  }))
  .handleAction(fetchInvoices.failure, (state, action) => ({
    ...state,
    loading: false,
    error: action.payload.message,
    total: 0,
    errors: 0,
    items: null,
  }))
  .handleAction(deleteInvoice.request, (state) => ({
    ...state,
    deleting: true,
    deleteError: null,
  }))
  .handleAction(deleteInvoice.failure, (state, action) => ({
    ...state,
    deleting: false,
    deleteError: action.payload.message,
  }))
  .handleAction(deleteInvoice.success, (state, action) => {
    if (state.items == null) return state
    const { [action.payload]: _, ...items } = state.items
    return {
      ...state,
      items,
      deleting: false,
      deleteError: null,
    }
  })
  .handleAction(deleteInvoices.request, (state) => ({
    ...state,
    deleting: true,
    deleteError: null,
  }))
  .handleAction(deleteInvoices.failure, (state, action) => ({
    ...state,
    deleting: false,
    deleteError: action.payload.message,
  }))
  .handleAction(deleteInvoices.success, (state) => ({
    ...state,
    deleting: false,
    deleteError: null,
  }))
  // REPARSING
  .handleAction(reparseInvoices.request, (state) => ({
    ...state,
    reparsing: true,
    reparseError: null,
  }))
  .handleAction(reparseInvoices.failure, (state, action) => ({
    ...state,
    reparsing: false,
    reparseError: action.payload.message,
  }))
  .handleAction(reparseInvoices.success, (state) => ({
    ...state,
    reparsing: false,
    reparseError: null,
  }))
  // REPARSING FILTER INVOICES
  .handleAction(reparseFilterInvoices.request, (state) => ({
    ...state,
    reparsing: true,
    reparseError: null,
  }))
  .handleAction(reparseFilterInvoices.failure, (state, action) => ({
    ...state,
    reparsing: false,
    reparseError: action.payload.message,
  }))
  .handleAction(reparseFilterInvoices.success, (state) => ({
    ...state,
    reparsing: false,
    reparseError: null,
  }))
  // REPARSING FILTER PDFS
  .handleAction(reparseFilterPdfs.request, (state) => ({
    ...state,
    reparsing: true,
    reparseError: null,
  }))
  .handleAction(reparseFilterPdfs.failure, (state, action) => ({
    ...state,
    reparsing: false,
    reparseError: action.payload.message,
  }))
  .handleAction(reparseFilterPdfs.success, (state) => ({
    ...state,
    reparsing: false,
    reparseError: null,
  }))
  // NAVIGATION
  .handleAction(setPageSize, (state, action) => ({
    ...state,
    pageSize: action.payload,
  }))
  .handleAction(setPageIndex, (state, action) => ({
    ...state,
    pageIndex: action.payload,
  }))
  .handleAction(setPageSort, (state, action) => ({
    ...state,
    pageSort: action.payload.pageSort,
    sortDirection: action.payload.sortDirection,
  }))

// Selectors
export const getItems = (state: RootState) => state.invoices.invoices.items
export const getPageSize = (state: RootState) =>
  state.invoices.invoices.pageSize
export const getPageIndex = (state: RootState) =>
  state.invoices.invoices.pageIndex
export const getPageSort = (state: RootState) =>
  state.invoices.invoices.pageSort
export const getSortDirection = (state: RootState) =>
  state.invoices.invoices.sortDirection

interface SelectorProps {
  invoices: Invoices | null
  total: number
  errors: number
  loading: boolean
  error: string | null
}

export const getInvoices = createStructuredSelector<RootState, SelectorProps>({
  invoices: getItems,
  total: (state) => state.invoices.invoices.total,
  errors: (state) => state.invoices.invoices.errors,
  loading: (state) => state.invoices.invoices.loading,
  error: (state) => state.invoices.invoices.error,
})

export const getInvoice = (id: number | null) =>
  createSelector(getItems, (items: any) => (!items || !id ? null : items[id]))

export const getPrevNext = (id: number) => {
  return createSelector(getItems, (invoices: any) => {
    if (!invoices) return { prev: null, next: null }

    const items = Object.values(invoices) as any
    const index = items.findIndex((item: any) => item.id === id)
    const found = index !== -1
    if (!found) return { prev: null, next: null }

    return {
      prev: items[index === 0 ? items.length - 1 : index - 1].id,
      next: items[index === items.length - 1 ? 0 : index + 1].id,
    }
  })
}

export const getDeleting = (state: RootState) =>
  state.invoices.invoices.deleting

export const getDeleteError = (state: RootState) =>
  state.invoices.invoices.deleteError

// API
// 'provider' | 'client' | 'startDate' | 'endDate' | 'pdl'  | 'status' | 'errorCodes' | 'lineErrorCodes' | 'createdDate' | 'updatedDate' | 'invoiceNumber' | 'templateName' | 'sortField' | 'sortDirection' | 'fileType'
interface Payload {
  provider: string | null
  client: number | null
  startDate: string | null
  endDate: string | null
  pdl: string | null
  status: string[]
  errorCodes: string[]
  lineErrorCodes: string[]
  createdDate: string | null
  updatedDate: string | null
  invoiceNumber: string | null
  templateName: string | null
  sortField: string | null
  sortDirection: number | 0
  fileType: string | null
}

const api = {
  fetchInvoices: async (
    pageIndex: number,
    pageSize: number,
    payload: Payload,
  ) => {
    if (payload.startDate === '' || payload.endDate === '')
      return {
        total: 0,
        errors: 0,
        items: [],
      }

    if (payload.createdDate === '')
      payload.createdDate = null
    
    if (payload.updatedDate === '')
      payload.updatedDate = null

      const response = await superFetch({
      url: `invoices/search?offset=${pageIndex * pageSize}&limit=${pageSize}`,
      method: 'POST',
      body: {
        Supplier: payload.provider,
        Account: payload.client,
        Debut: payload.startDate,
        Fin: payload.endDate,
        InvoiceType: payload.fileType,
        Status: payload.status,
        ErrorCodes: payload.errorCodes,
        LineErrorCodes: payload.lineErrorCodes,
        DateCreation: payload.createdDate,
        DateMaj: payload.updatedDate,
        InvoiceNumber: payload.invoiceNumber,
        TemplateName: payload.templateName,
        SortField: payload.sortField,
        SortDirection: payload.sortDirection,
        ...(payload.pdl && { Meter: payload.pdl }),
      },
      invoiceApi: true,
    })

    checkStatus(response)
    const data = await response.json()

    return {
      total: data.page.total,
      errors: data.page.errors,
      items: keyBy(data.items, 'id'),
    }
  },
  deleteInvoice: async (id: number) => {
    const response = await superFetch({
      url: `invoices/${id}`,
      method: 'DELETE',
      invoiceApi: true,
    })

    checkStatus(response)
    return id
  },
  deleteInvoices: async (ids: number[]) => {
    const response = await superFetch({
      url: `invoices`,
      method: 'DELETE',
      invoiceApi: true,
      body: ids,
    })

    checkStatus(response)
  },
  reparseInvoices: async (ids: number[]) => {
    const response = await superFetch({
      url: `pdfs/reparse`,
      method: 'POST',
      invoiceApi: true,
      body: ids,
    })

    checkStatus(response)
  },
  reparseFilterInvoices: async (payload: Payload) => {
    const response = await superFetch({
      url: `pdfs/reparse/invoices`,
      method: 'POST',
      invoiceApi: true,
      body: {
        Supplier: payload.provider,
        Account: payload.client,
        Debut: payload.startDate,
        Fin: payload.endDate,
        InvoiceType: payload.fileType,
        Status: payload.status,
        ErrorCodes: payload.errorCodes,
        LineErrorCodes: payload.lineErrorCodes,
        DateCreation: payload.createdDate,
        DateMaj: payload.updatedDate,
        InvoiceNumber: payload.invoiceNumber,
        TemplateName: payload.templateName,
        SortField: payload.sortField,
        SortDirection: payload.sortDirection,
        ...(payload.pdl && { Meter: payload.pdl }),
      },
    })

    checkStatus(response)
  },
  reparseFilterPdfs: async (payload: Payload, status: string[]) => {
    const response = await superFetch({
      url: `pdfs/reparse/pdfs`,
      method: 'POST',
      invoiceApi: true,
      body: {
        Supplier: payload.provider,
        Account: payload.client,
        Debut: payload.startDate,
        Fin: payload.endDate,
        InvoiceType: payload.fileType,
        Status: status,
        DateCreation: payload.createdDate,
        DateMaj: payload.updatedDate,
        InvoiceNumber: payload.invoiceNumber,
        TemplateName: payload.templateName,
        SortField: payload.sortField,
        SortDirection: payload.sortDirection,
        ...(payload.pdl && { Meter: payload.pdl }),
      },
    })

    checkStatus(response)
  },
}

// Epics
export const invoicesEpic = combineEpics<RootAction, RootAction, RootState>(
  (action$, state$) =>
    action$.pipe(
      filter(isActionOf(fetchInvoices.request)),
      withLatestFrom(state$),
      switchMap(([_, state]) =>
        from(
          api.fetchInvoices(
            getPageIndex(state),
            getPageSize(state),
            getSelection(state)
          )
        ).pipe(
          map(fetchInvoices.success),
          catchError<any, any>(pipe(fetchInvoices.failure, of))
        )
      )
    ),
  (action$) =>
    action$.pipe(
      filter(
        isActionOf([
          setProvider,
          setClient,
          setStartDate,
          setEndDate,
          setStatus,
          setErrorCodes,
          setLineErrorCodes,
          setPdl,
          setCreatedDate,
          setUpdatedDate,
          setInvoiceNumber,
          setTemplateName,
          setFileType,
        ])
      ),
      debounceTime(200),
      flatMap(() => of(setPageIndex(0), fetchInvoices.request()))
    ),
  (action$) =>
    action$.pipe(
      filter(isActionOf([fetchInvoices.success, fetchInvoices.failure])),
      mapTo(setInvoice(null))
    ),
  (action$) =>
    action$.pipe(
      filter(isActionOf(deleteInvoice.request)),
      switchMap((action) =>
        from(api.deleteInvoice(action.payload)).pipe(
          map(deleteInvoice.success),
          catchError<any, any>(pipe(deleteInvoice.failure, of))
        )
      )
    ),
  (action$) =>
    action$.pipe(
      filter(isActionOf(deleteInvoices.request)),
      switchMap((action) =>
        from(api.deleteInvoices(action.payload)).pipe(
          map(deleteInvoices.success),
          catchError<any, any>(pipe(deleteInvoices.failure, of))
        )
      )
    ),
  //  (action$) =>
  //    action$.pipe(
  //      filter(isActionOf([reparseInvoices.success])),
  //      mapTo<any, any>(fetchInvoices.request())
  //    ),
  (action$) =>
    action$.pipe(
      filter(isActionOf(reparseInvoices.request)),
      switchMap((action) =>
        from(api.reparseInvoices(action.payload)).pipe(
          map(reparseInvoices.success),
          catchError<any, any>(pipe(reparseInvoices.failure, of))
        )
      )
    ),

  (action$, state$) =>
    action$.pipe(
      filter(isActionOf(reparseFilterInvoices.request)),
      withLatestFrom(state$),
      switchMap(([_, state]) =>
        from(
          api.reparseFilterInvoices(getSelection(state))
        ).pipe(
          map(reparseFilterInvoices.success),
          catchError<any, any>(pipe(reparseFilterInvoices.failure, of))
        )
      )
    ),

  (action$, state$) =>
    action$.pipe(
      filter(isActionOf(reparseFilterPdfs.request)),
      withLatestFrom(state$),
      switchMap(([_, state]) =>
        from(api.reparseFilterPdfs(getSelection(state), getStatus(state))).pipe(
          map(reparseFilterPdfs.success),
          catchError<any, any>(pipe(reparseFilterPdfs.failure, of))
        )
      )
    )
)
