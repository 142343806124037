import { Icon } from 'library'
import { statuses } from 'labels'
import { RowRenderProps } from 'react-table'

const isSuccess = (status: string) => ['ALL'].includes(status)

const isError = (status: string) => ['EE', 'EI'].includes(status)

const CellIcon = (props: { id: string; color: string }) => (
  <Icon size={14} style={{ top: 2, marginRight: 10 }} {...props} />
)

const SuccessIcon = () => <CellIcon id="check-full" color="success" />

const ErrorIcon = () => <CellIcon id="warning" color="danger" />

export const StatusCell = (props: RowRenderProps) => {
  const {
    value,
    original: { errorMessage },
  } = props

  return (
    <div
      style={{ textAlign: 'left' }}
      {...(errorMessage && { title: errorMessage })}
    >
      {isSuccess(value) && <SuccessIcon />}
      {isError(value) && <ErrorIcon />}
      {statuses[value] || ''}
    </div>
  )
}
