import { useState, useRef, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import debounce from 'lodash/debounce'
import { Input } from 'reactstrap'

import { setTemplateName, getTemplateName } from 'store/invoices/selection'
import { FormGroup } from 'library'

const TemplateNameInput = () => {
  const dispatch = useDispatch()
  const templateName = useSelector(getTemplateName)
  const [value, setValue] = useState(templateName)
  const debounced = useRef(
    debounce((x: any) => {
      dispatch(setTemplateName(x))
    }, 400)
  )

  useEffect(() => {
    if (templateName !== value) {
      debounced.current(value)
    }
  }, [templateName, value])

  return (
    <FormGroup label="Template">
      <Input
        type="search"
        value={value || ''}
        onInput={(e: any) => setValue(e.target.value)}
        onChange={() => {}}
        style={{ fontSize: 14 }}
      />
    </FormGroup>
  )
}

export default TemplateNameInput
