import { useQuery, useMutation, useQueryClient } from 'react-query'
import { useSelector } from 'react-redux'
import isNil from 'lodash/isNil'
import isEmpty from 'lodash/isEmpty'
import uniqBy from 'lodash/uniqBy'
import map from 'lodash/map'
import { getOptions } from 'store/invoices/crawler'
import * as api from 'api/crawler'

import { UseQueryOptions } from 'react-query'
import { SearchExecutionsParams } from 'api/crawler'
import { Crawler, CrawlerExecution, CrawlerFacture } from 'api/types'

/* Crawlers */

export function useSearchCrawlersQuery(
  options: Pick<UseQueryOptions, 'suspense'> = {}
) {
  return useQuery({
    queryKey: ['crawlers'],
    queryFn: () => api.searchCrawlers(),
    keepPreviousData: true,
    select: (data) => ({
      list: data,
      customers: uniqBy(map(data, 'customer'), 'id'),
      suppliers: uniqBy(map(data, 'supplier'), 'id'),
    }),
    ...options,
  })
}

export function useCreateCrawlerMutation() {
  const client = useQueryClient()
  return useMutation(
    (crawler: Partial<Crawler>) => api.createCrawler(crawler),
    {
      onSuccess: () => {
        client.invalidateQueries(['crawlers'])
      },
    }
  )
}

export function useDeleteCrawlerMutation(crawlerId: number) {
  const client = useQueryClient()
  return useMutation(() => api.deleteCrawler(crawlerId), {
    onSuccess: () => {
      client.invalidateQueries(['crawlers'])
      client.invalidateQueries(['executions'])
      client.invalidateQueries(['factures'])
    },
  })
}

/* Executions */

export function useSearchExecutionsQuery() {
  const { isPeriod, ...options } = useSelector(getOptions)
  const params = options
  if (!isPeriod) params.endDate = params.startDate

  return useQuery({
    queryKey: ['executions', params],
    queryFn: () => api.searchExecutions(params as SearchExecutionsParams),
    enabled:
      !isNil(params.customer) &&
      !isNil(params.supplier) &&
      !isEmpty(params.startDate) &&
      !isEmpty(params.endDate),
    keepPreviousData: true,
  })
}

export function useGetExecutionQuery(execId: number) {
  return useQuery({
    queryKey: ['execution', execId],
    queryFn: () => api.getExecution(execId),
    keepPreviousData: true,
  })
}

export function useCreateExecutionMutation() {
  const client = useQueryClient()
  return useMutation(
    (execution: Partial<CrawlerExecution>) => api.createExecution(execution),
    {
      onSuccess: () => {
        client.invalidateQueries(['executions'])
      },
    }
  )
}

export function useDeleteExecutionMutation(execId: number) {
  const client = useQueryClient()
  return useMutation(() => api.deleteExecution(execId), {
    onSuccess: () => {
      client.invalidateQueries(['executions'])
      client.invalidateQueries(['execution', execId])
    },
  })
}

/* Factures */
export function useSearchFacturesQuery(execId: number) {
  return useQuery({
    queryKey: ['factures', execId],
    queryFn: () => api.searchFactures(execId),
    keepPreviousData: true,
  })
}

export function useCreateFactureMutation(execId: number) {
  const client = useQueryClient()
  return useMutation(
    (facture: Partial<CrawlerFacture>) => api.createFacture(facture),
    {
      onSuccess: () => {
        client.invalidateQueries(['executions'])
        client.invalidateQueries(['execution', execId])
        client.invalidateQueries(['factures', execId])
      },
    }
  )
}

export function useDeleteFactureMutation(execId: number, facId: number) {
  const client = useQueryClient()
  return useMutation(() => api.deleteFacture(facId), {
    onSuccess: () => {
      client.invalidateQueries(['executions'])
      client.invalidateQueries(['execution', execId])
      client.invalidateQueries(['factures', execId])
    },
  })
}
