//** @jsxImportSource @emotion/react */
import React, { FC } from 'react'
import { useDispatch } from 'react-redux'
import { Formik, Form, FastField } from 'formik'
import { Input, UncontrolledTooltip } from 'reactstrap'
import { withRouter } from 'react-router-dom'
import { RowRenderProps } from 'react-table'
import { Trans } from 'react-i18next'

import { SelectTable, Icon, CellButton } from 'library'
import {
  updateInvoiceLines,
  deleteInvoiceLine,
  deleteInvoiceLines,
} from 'store/invoices/invoice'
import { setNewItem } from 'store/invoices/iat'
import { errorsInvoiceLine } from 'labels'
import CellDateInput from './CellDateInput'

const CellInput: FC<any> = ({
  field,
  form: { errors },
  formatter = (x: any) => x,
  textAlign = 'left',
  ...props
}) => {
  return (
    <Input
      type="text"
      title={field.value}
      bsSize="sm"
      name={field.name}
      value={formatter(field.value) || ''}
      onChange={field.onChange}
      // onBlur={field.onBlur}
      {...props}
      invalid={!!errors[field.name]}
      style={{
        borderRadius: 0,
        height: 25,
        padding: '2px 5px',
        marginTop: 3,
        textAlign,
      }}
    />
  )
}

const CellSelect: FC<any> = ({
  field,
  form: { errors },
  formatter = (x: any) => x,
  ...props
}) => {
  return (
    <Input
      type="select"
      bsSize="sm"
      name={field.name}
      value={field.value || ''}
      onChange={field.onChange}
      {...props}
      invalid={!!errors[field.name]}
      style={{ borderRadius: 0, height: 25, padding: '2px 5px', marginTop: 3 }}
    >
      <option value=""></option>
      <option value="kWh">kWh</option>
      <option value="MWh">MWh</option>
      <option value="kW">kW</option>
      <option value="c.j">c.j</option>
      <option value="p.j">p.j</option>
      <option value="c€/c.j">c€/c.j</option>
      <option value="c€/p.j">c€/p.j</option>
      <option value="c€/kWh">c€/kWh</option>
      <option value="c€/kW">c€/kW</option>
      <option value="€/mois">€/mois</option>
      <option value="%">%</option>
    </Input>
  )
}

const TextCell = (row: any) => (
  <FastField
    name={`${row.original.id}.${row.column.id}`}
    component={CellInput}
  />
)

const NumberCell = (row: any) => (
  <div>
    <FastField
      name={`${row.original.id}.${row.column.id}`}
      component={CellInput}
      textAlign="right"
      type="number"
    />
  </div>
)

const DateCell: FC<any> = (row: any) => (
  <FastField
    name={`${row.original.id}.${row.column.id}`}
    component={CellDateInput}
  />
)

const UniteConsoCell = (row: any) => (
  <div css={{ textAlign: 'right' }}>
    <FastField
      name={`${row.original.id}.${row.column.id}`}
      component={CellSelect}
    />
  </div>
)

const IatCell = withRouter((props: any) => {
  const dispatch = useDispatch()
  return (
    <div
      css={{
        display: 'flex',
        input: {
          width: 'calc(100% - 30px)',
        },
      }}
    >
      <FastField
        name={`${props.original.id}.${props.column.id}`}
        component={CellInput}
      />

      <CellButton
        iconId="parameters"
        onClick={() => {
          dispatch(
            setNewItem({ label: props.original.libelle, iat: props.value })
          )
          props.history.push('/factures/admin')
        }}
        style={{ margin: 0 }}
      />
    </div>
  )
})

const CustomForm: FC<any> = (props) => {
  const dispatch = useDispatch()
  const [selection, setSelection] = React.useState<string[]>([])
  const { invoice, loading, id: invoiceId, edit, setEdit } = props

  const columns = [
    {
      Header: 'Description',
      accessor: 'libelle',
      Cell: TextCell,
      minWidth: 180,
      maxWidth: 400,
      sortable: false,
      resizable: false,
    },
    {
      Header: 'Début',
      accessor: 'dateDebutPeriode',
      width: 90,
      Cell: DateCell,
      resizable: false,
      sortable: false,
    },
    {
      Header: 'Fin',
      accessor: 'dateFinPeriode',
      width: 90,
      Cell: DateCell,
      resizable: false,
      sortable: false,
    },
    {
      Header: 'Conso',
      accessor: 'conso',
      minWidth: 80,
      maxWidth: 150,
      Cell: NumberCell,
      resizable: false,
      sortable: false,
    },
    {
      Header: 'Unité',
      accessor: 'uniteConso',
      width: 75,
      Cell: UniteConsoCell,
      resizable: false,
      sortable: false,
    },
    {
      Header: 'Montant',
      accessor: 'montantHT',
      minWidth: 80,
      maxWidth: 150,
      Cell: NumberCell,
      resizable: false,
      sortable: false,
    },
    {
      Header: 'IAT',
      accessor: 'iatEmcmId',
      minWidth: 100,
      maxWidth: 140,
      Cell: IatCell,
      resizable: false,
      sortable: false,
    },
    {
      accessor: 'errorCode',
      width: 35,
      resizable: false,
      sortable: false,
      Cell: (row: RowRenderProps) => {
        const iconId = `icon${row.original.id}`
        return row.value && row.value !== '00' ? (
          <>
            <div style={{ textAlign: 'center' }} id={iconId}>
              <Icon size={14} id="warning" style={{ top: 2 }} color="danger" />
            </div>
            <UncontrolledTooltip
              placement="top"
              target={iconId}
              autohide={false}
              flip={true}
              fade={false}
            >
              {['01', '06'].includes(row.value) && (
                <Icon
                  id="parameters"
                  size={14}
                  style={{ verticalAlign: 'center', marginRight: 5 }}
                />
              )}
              {errorsInvoiceLine[row.value] || 'Non valide'}
            </UncontrolledTooltip>
          </>
        ) : (
          <div style={{ textAlign: 'center' }}>
            <Icon
              size={14}
              id="check-full"
              style={{ top: 2 }}
              color="success"
            />
          </div>
        )
      },
    },
    {
      accessor: 'id',
      width: 60,
      resizable: false,
      sortable: false,
      Cell: (row: RowRenderProps) => {
        const dispatch = useDispatch()
        return (
          <div style={{ textAlign: 'center' }}>
            <CellButton
              type="reset"
              iconId="trash"
              style={{ margin: 0 }}
              onClick={() => {
                dispatch(
                  deleteInvoiceLine.request({ invoiceId, lineId: row.value })
                )
              }}
              disabled={row.original.id === 0}
            />
          </div>
        )
      },
    },
  ]

  const newLine = (id: number) => ({
    libelle: null,
    dateDebutPeriode: null,
    dateFinPeriode: null,
    conso: null,
    uniteConso: null,
    montantHT: null,
    iatEmcmId: null,
    errorCode: null,
    id,
  })

  const data = invoice
    ? [
        ...Object.values(invoice.lignes),
        ...Array.from({ length: edit }, (_, i) => newLine(-(i + 1))),
      ]
    : []

  return (
    <>
      <Form>
        <SelectTable
          columns={columns}
          data={data}
          loading={loading}
          showPagination={false}
          showPageSizeOptions={false}
          showPageJump={false}
          sortable={false}
          pageSize={data.length}
          sm={true}
          keyField="id"
          selection={selection}
          setSelection={setSelection}
        />

        <footer
          css={{
            marginTop: 15,
            display: 'flex',
            alignItems: 'flex-start',
            justifyContent: 'space-between',
          }}
        >
          <div css={{ display: 'flex', flexDirection: 'column' }}>
            {selection.length > 0 && (
              <CellButton
                iconId="trash"
                type="button"
                text={
                  <Trans i18nKey="lignes.suppression" count={selection.length}>
                    Supprimer les <strong>{{ count: selection.length }}</strong>{' '}
                    lignes
                  </Trans>
                }
                style={{ marginBottom: '.6rem' }}
                onClick={async () => {
                  try {
                    const ids = selection.map((item) =>
                      Number(item.replace('select-', ''))
                    )
                    dispatch(
                      deleteInvoiceLines.request({
                        invoiceId,
                        lineIds: ids,
                      })
                    )
                  } catch (error) {
                    console.log(error)
                  }
                }}
                disabled={loading}
              />
            )}

            <CellButton
              text="Ajouter une ligne"
              iconId="add"
              type="button"
              onClick={() => {
                setEdit((edit: number) => edit + 1)
              }}
              disabled={loading}
            />
          </div>

          <CellButton
            text="Modifier"
            iconId="check"
            type="submit"
            disabled={loading || props.isSubmitting || !props.isValid}
          />
        </footer>
      </Form>
    </>
  )
}

const InvoiceLinesForm: FC<{
  invoice: any
  loading: boolean
}> = ({ invoice, loading }) => {
  const [edit, setEdit] = React.useState(0)
  const cellId = React.useRef<number | null>(null)
  const dispatch = useDispatch()

  if (!invoice) return null

  const { id, lignes } = invoice

  return (
    <Formik
      enableReinitialize
      initialValues={lignes}
      onSubmit={async (values: any, actions: any) => {
        dispatch(
          updateInvoiceLines.request({ id, body: Object.values(values) })
        )
        actions.setSubmitting(false)
        // actions.resetForm()
      }}
      render={(props) => (
        <CustomForm
          {...props}
          invoice={invoice}
          loading={loading}
          cellId={cellId}
          id={id}
          edit={edit}
          setEdit={setEdit}
        />
      )}
    />
  )
}

export default InvoiceLinesForm
