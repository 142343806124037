import { from, of, pipe } from 'rxjs'
import { filter, switchMap, map, catchError } from 'rxjs/operators'
import { combineEpics } from 'redux-observable'
import { createStructuredSelector } from 'reselect'
import {
  createReducer,
  ActionType,
  isActionOf,
  createAsyncAction,
} from 'typesafe-actions'

import { superFetch, checkStatus } from 'helpers/apiConf'
import { RootState, RootAction } from 'store'

// Types
interface Provider {
  id: string
  label: string
}

interface Providers {
  [id: string]: Provider
}

export interface ProvidersState {
  items: Providers | null
  loading: boolean
  error: string | null
}

// Actions
export const fetchProviders = createAsyncAction(
  '@data-mgmt/invoices/providers/FETCH_PROVIDERS_REQUEST',
  '@data-mgmt/invoices/providers/FETCH_PROVIDERS_SUCCESS',
  '@data-mgmt/invoices/providers/FETCH_PROVIDERS_FAILURE'
)<undefined, Providers, Error>()

const actions = { fetchProviders }
export type ProvidersAction = ActionType<typeof actions>

// Reducer
const initialState = {
  items: null,
  loading: false,
  error: null,
}

export default createReducer<ProvidersState, ProvidersAction>(initialState)
  .handleAction(fetchProviders.request, (state) => ({
    ...state,
    loading: true,
    error: null,
  }))
  .handleAction(fetchProviders.success, (_, action) => ({
    loading: false,
    error: null,
    items: action.payload,
  }))
  .handleAction(fetchProviders.failure, (_, action) => ({
    loading: false,
    error: action.payload.message,
    items: {},
  }))

// Selectors
export const getItems = (state: RootState) => state.invoices.providers.items
export const getLoading = (state: RootState) => state.invoices.providers.loading
export const getError = (state: RootState) => state.invoices.providers.error

interface SelectorProps {
  providers: Providers | null
  loading: boolean
  error: string | null
}

export const getProviders = createStructuredSelector<RootState, SelectorProps>({
  providers: getItems,
  loading: getLoading,
  error: getError,
})

export const getFirstProvider = (state: RootState) => {
  const items = getItems(state)
  return !items ? null : Object.values(items)[0].id || null
}

export const getProviderLabel = (id: string) => (state: any) => {
  const items = getItems(state)
  const provider = items && items[id]
  return provider ? provider.label : null
}

// API
interface ResponseItem {
  code: string
  nom: string
}

const normalize = (data: ResponseItem[]) =>
  data.reduce(
    (acc, item) => ({
      ...acc,
      [item.code]: {
        id: item.code,
        label: item.nom,
      },
    }),
    {}
  )

const api = {
  fetchProviders: async () => {
    const payload = {
      url: 'fournisseurs',
      invoiceApi: true,
    }
    const response = await superFetch(payload)
    checkStatus(response)
    const data = await response.json()
    return normalize(data)
  },
}

// Epics
export const providersEpic = combineEpics<RootAction, RootAction, RootState>(
  (action$) =>
    action$.pipe(
      filter(isActionOf(fetchProviders.request)),
      switchMap(() =>
        from(api.fetchProviders()).pipe(
          map(fetchProviders.success),
          catchError<any, any>(pipe(fetchProviders.failure, of))
        )
      )
    )
)
