import v4 from 'uuid/v4'
import { ThunkAction } from 'redux-thunk'
import { AnyAction } from 'redux'

import i18n, { lngIso } from 'i18n'
import { getPlatform } from 'store/selectors'
import types from './types'
import * as api from './api'

type ThunkResult<R> = ThunkAction<R, object, undefined, AnyAction>

export const logout: (u: any) => ThunkResult<any> = (connectedUser) => (
  dispatch
) => {
  dispatch({
    type: types.USER_LOGOUT_REQUEST,
  })

  return api.logout(connectedUser.id_token)
}

export const fetchUserInfo: () => ThunkResult<any> = () => async (
  dispatch,
  getState
) => {
  dispatch({
    type: types.USER_INFO_REQUEST,
  })

  const platform = getPlatform(getState())

  try {
    const response = await api.userInfos(platform)
    if (response.status !== 200)
      throw new Error('Something went wrong : ' + response.status)
    const user = await response.json()
    dispatch({
      type: types.USER_INFO_SUCCESS,
      user,
    })
  } catch (error: any) {
    dispatch({
      type: types.USER_INFO_FAILURE,
      message: error.Message || 'Something went wrong.',
    })
  }
}

export const postUserForm: (u: any) => ThunkResult<any> = (usr) => async (
  dispatch,
  getState
) => {
  dispatch({
    type: types.USER_FORM_POST_REQUEST,
  })

  const platform = getPlatform(getState())

  try {
    const response = await api.postUser(usr, platform)
    if (response.status === 200) {
      const user = await response.json()
      dispatch({
        type: types.USER_FORM_POST_SUCCESS,
        user,
      })
      i18n.changeLanguage(lngIso(response.LanId))
      document.body.classList.remove('no-scroll')
    } else {
      const error = await response.json()
      dispatch({
        type: types.USER_FORM_POST_FAILURE,
        message: error.Message,
      })
    }
  } catch (error: any) {
    dispatch({
      type: types.USER_FORM_POST_FAILURE,
      message: error.Message || 'Something went wrong.',
    })
  }
}

export const changePassword: (p: any) => ThunkResult<any> = (
  passwordChange
) => async (dispatch, getState) => {
  const uuid = v4()
  dispatch({
    type: types.CHANGE_PASSWORD_REQUEST,
    uuid,
  })

  const platform = getPlatform(getState())
  try {
    const response = await api.changePassword(uuid, passwordChange, platform)
    if (response.status === 200) {
      dispatch({
        type: types.CHANGE_PASSWORD_SUCCESS,
      })
    } else {
      const error = await response.json()
      dispatch({
        type: types.CHANGE_PASSWORD_FAILURE,
        message: error.Message || 'Something went wrong.',
      })
    }
  } catch (error: any) {
    dispatch({
      type: types.CHANGE_PASSWORD_FAILURE,
      message: error.Message || 'Something went wrong.',
    })
  }
}
