import * as env from './env'

type Token = null | string

let token: Token = null
export const setToken: (t: Token) => void = (newToken) => (token = newToken)
let userPlatform = 'MUT'
export const setPlatform: (s: string) => void = (newPlatform) =>
  (userPlatform = newPlatform)

interface Params {
  url: string
  method?: string
  body?: any
  uuid?: null | string
  contentType?: string | null
  stringify?: boolean
  platform?: string
  invoiceApi?: boolean
}

/**
 * Wrapper d'appel HTTP pour gérer le cas de la déconnexion en 401 lors de l'expiration du cookie de refresh.
 * Le content-type est 'application/json'
 * Pour l'envoie de cookie le header 'credentials' est à 'include',
 *
 * @param {string} url Chemin relatif de l'API. Cf. <code>apiUrl</code> - Mandatory
 * @param {string} method Chemin relatif de l'API. Cf. <code>apiUrl</code> - Optional - Default 'GET'
 * @param {object} body Objet à serialiser dans le body - Optional - Default null
 * @param {string} uuid ID unique de la request - Optional - Default null
 * @param {string} contentType content type de la request - Optional - Default 'application/json'
 * @param {boolean} stringify Objet à serialiser dans le body - Optional - Default false
 */
export const superFetch = async ({
  url,
  method = 'GET',
  body = null,
  uuid = null,
  contentType = 'application/json',
  stringify = true,
  platform,
  invoiceApi = false,
}: Params) => {
  try {
    if (url) {
      const baseUrl = invoiceApi ? env.apiUrlInvoice : env.apiUrl(platform)

      const headers = new Headers({
        ...(contentType && { 'Content-Type': contentType }),
        ...(uuid && { 'X-REQUEST-ID': uuid }),
        ...(token && { Authorization: `Bearer ${token}` }),
      } as any)

      let response = null

      if (!platform) {
        platform = userPlatform
      }

      switch (method) {
        case 'POST':
        case 'PATCH':
        case 'PUT':
        case 'DELETE':
          let contentBody = null
          if (stringify === true) {
            contentBody = JSON.stringify(body)
          } else {
            contentBody = body
          }
          response = await fetch(`${baseUrl}${url}`, {
            credentials: 'include',
            method: method,
            body: contentBody,
            headers,
          })
          break
        default:
          response = await fetch(`${baseUrl}${url}`, {
            credentials: 'include',
            method: method,
            headers,
          })
      }

      if (response.status === 401) {
        // window.localStorage.clear();
        // window.location.href = "/";
        // alert(url + " : 401 Unauthorized");
        console.error(response.status, await response.text())
      }

      return response
    } else {
      throw new Error('Url est obligatoire pour le fetch wrapper')
    }
  } catch (e) {
    throw e
  }
}

export const checkStatus = (response: any) => {
  const { status, statusText } = response
  if (status < 200 || status >= 300) {
    throw new Error(`Erreur ${status} : ${statusText}`)
  }
}

export const asyncCheckStatus = async (resp: any) => {
  let data = undefined
  try {
    data = await resp.json()
  } catch (error) {}

  const { status, statusText } = resp
  if (status < 200 || status >= 300) {
    const message = data?.title ?? statusText
    throw new Error(`Erreur ${status} : ${message}`)
  }

  return data
}
