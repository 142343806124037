//** @jsxImportSource @emotion/react */
import { useCreateFactureMutation } from 'queries/crawler'

type Props = {
  execId: number
}

export const BtnCreateFacture = ({ execId }: Props) => {
  const { mutate, isLoading } = useCreateFactureMutation(execId)
  return (
    <button
      className="btn btn-danger btn-sm"
      disabled={isLoading}
      onClick={() => {
        const payload = {
          executionId: execId,
          numeroFacture: Math.floor(Math.random() * 1_000_000).toString(),
        }
        console.log('create facture:', payload)
        mutate(payload)
      }}
    >
      Créer une facture
    </button>
  )
}
