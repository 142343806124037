//** @jsxImportSource @emotion/react */
import { useState } from 'react'
import { QueryBoundary, Section } from 'library'
import SupplierSelect from 'components/crawler/SupplierSelect'
import CustomerSelect from 'components/crawler/CustomerSelect'
import StartDate from 'components/crawler/StartDate'
import EndDate from 'components/crawler/EndDate'
import { Executions } from 'components/crawler/Executions'
import { Factures } from 'components/crawler/Factures'
//import { FormCreateCrawler } from 'components/crawler/FormCreateCrawler'

export default function Crawler() {
  const [execId, setExecId] = useState<number | null>(null)
  const [dev, setDev] = useState(false)
  const toggleDev = () => setDev((dev) => !dev)

  return (
    <Section>
      <div css={{ '> * + *': { marginTop: '2rem' } }}>
        <header
          css={{
            display: 'flex',
            justifyContent: 'space-between',
          }}
        >
          <div
            css={{
              display: 'grid',
              gridTemplateColumns: 'repeat(7, minmax(150px, 220px))',
              gridGap: 20,
            }}
          >
            <SupplierSelect />
            <CustomerSelect />
            <StartDate />
            <EndDate />
          </div>

          <div css={{ minWidth: 'max-content' }}>
            {process.env.NODE_ENV === 'development' && (
              <label>
                <input
                  type="checkbox"
                  checked={dev}
                  onChange={() => toggleDev()}
                  className="mr-1"
                />
                <span>Développeur</span>
              </label>
            )}
          </div>
        </header>

        <div
          css={{
            minHeight: '250px',
            display: 'grid',
            gridTemplateColumns: 'repeat(2, minmax(0, 1fr))',
            gap: '2rem',
          }}
        >
          <div css={{ position: 'relative' }}>
            <h5>Executions</h5>
            <QueryBoundary height="100%">
              <Executions value={execId} onChange={setExecId} dev={dev} />
            </QueryBoundary>
          </div>

          {execId !== null && (
            <div css={{ position: 'relative' }}>
              <h5>Factures</h5>
              <QueryBoundary height="100%">
                <Factures execId={execId} dev={dev} />
              </QueryBoundary>
            </div>
          )}
        </div>

        {/* {dev && <FormCreateCrawler />} */}
      </div>
    </Section>
  )
}
