import { useEffect, useState } from 'react'

const { pdfjsLib: pdfjs } = window

const useDocument = (data: any) => {
  const [loading, setLoading] = useState(true)
  const [document, setDocument] = useState<any>(null)

  useEffect(() => {
    if (!data) return

    let document: any = null

    const getPdf = async () => {
      try {
        // console.log('pdf: loading document')
        document = await pdfjs.getDocument({ data: new Uint8Array(data) })
          .promise
        setDocument(document)
        setLoading(false)
      } catch (error) {
        console.log(error)
      }
    }

    getPdf()

    return () => {
      if (document) {
        // console.log('pdf: cleanup document')
        document.cleanup()
        document.destroy()
      }
    }
  }, [data])

  return { document, loading }
}

export default useDocument
