import { useCallback, useState } from 'react'

type Callback = () => void
type Hook = (initial: boolean) => [boolean, Callback, Callback, Callback]

const useBoolean: Hook = (initial) => {
  const [value, set] = useState(initial)
  const toggle = useCallback(() => set((x) => !x), [])
  const setTrue = useCallback(() => set(true), [])
  const setFalse = useCallback(() => set(false), [])
  return [value, toggle, setTrue, setFalse]
}

export default useBoolean
