//** @jsxImportSource @emotion/react */
import { FC, ReactChild } from 'react'
import { NavLink } from 'react-router-dom'
import { Nav, NavItem } from 'reactstrap'

import { Icon } from 'library'

interface Props {
  url: string
  routes: {
    id: string
    label: string
    icon: string | ReactChild
  }[]
}

const TabNav: FC<Props> = ({ url, routes }) => {
  return (
    <Nav css={{ height: '100%' }}>
      {routes.map((route) => (
        <NavItem
          key={route.id}
          css={{
            height: '100%',
            a: {
              position: 'relative',
              height: '100%',
              padding: '0 8px',
              margin: '0 16px',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              color: 'rgba(255, 255, 255, .75)',
              textDecoration: 'none',
              ':after': {
                content: '""',
                display: 'block',
                width: '100%',
                height: 5,
                backgroundColor: '#007b5a',
                position: 'absolute',
                bottom: 0,
                transition: 'transform .1s ease',
                transformOrigin: '50%',
                transform: 'scaleX(0)',
              },
              '&:hover': {
                color: '#fff',
              },
              '&.active': {
                color: '#fff',
                ':after': {
                  transform: 'scaleX(1)',
                },
              },
            },
          }}
        >
          <NavLink to={`${url}/${route.id}`} activeClassName="active">
            {typeof route.icon === 'string' ? (
              <Icon id={route.icon} style={{ marginRight: '.75rem' }} />
            ) : (
              <span style={{ marginRight: '.75rem' }}>{route.icon}</span>
            )}
            {route.label}
          </NavLink>
        </NavItem>
      ))}
    </Nav>
  )
}

export default TabNav
