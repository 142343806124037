//** @jsxImportSource @emotion/react */
import { useSelector, useDispatch } from 'react-redux'
import { Alert } from 'reactstrap'
import { HeaderButton } from 'library'
import { reparseFilterPdfs } from 'store/invoices/invoices'

import { getPdfs } from 'store/invoices/pdfs'

const PdfsStats = () => {
  const dispatch = useDispatch()
  const { items, loading, error, errors, total } = useSelector(getPdfs)

  if (error || loading || !items) return null

  const count = Object.values(items).length

  return (
    <Alert
      color="info"
      css={{
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        lineHeight: 1.6,
        margin: 0,
      }}
    >
      <div
        css={{
          display: 'flex',
          '>*:not(:first-of-type)': {
            marginLeft: '1rem',
          },
        }}
      >
        <div>
          <strong>{count}</strong> / <strong>{total}</strong> pdfs, dont{' '}
          <strong>{errors}</strong> en erreur
        </div>
      </div>

      <HeaderButton
        color="info"
        text="Reparser le filtre"
        iconId="refresh"
        onClick={async () => {
          try {
            await dispatch(reparseFilterPdfs.request())
          } catch (error) {
            console.log(error)
          }
        }}
      />
    </Alert>
  )
}

export default PdfsStats
