import React, { FC } from 'react'
import { withRouter } from 'react-router'
import { superFetch, checkStatus } from 'helpers/apiConf'

import { CellButton, Loader } from 'library'

const initialState = {
  invoiceId: null,
  loading: false,
  error: null,
}

interface State {
  invoiceId: number | null
  loading: boolean
  error: string | null
}

type Action =
  | { type: 'request' }
  | { type: 'success'; invoiceId: number }
  | { type: 'failure'; error: Error }

function reducer(state: State, action: Action): State {
  switch (action.type) {
    case 'request':
      return {
        invoiceId: null,
        loading: true,
        error: null,
      }
    case 'success':
      return {
        invoiceId: action.invoiceId,
        loading: false,
        error: null,
      }
    case 'failure':
      return {
        invoiceId: null,
        loading: false,
        error: action.error.message,
      }
    default:
      return state
  }
}

const EditButton: FC<any> = ({ id, history }) => {
  const [{ invoiceId, loading, error }, dispatch] = React.useReducer(
    reducer,
    initialState
  )

  React.useEffect(() => {
    if (invoiceId) {
      history.push(`/factures/details/${invoiceId}`)
    }
  }, [history, invoiceId])

  const onClick = React.useCallback(() => {
    const fetchInvoiceId = async (pdfId: number) => {
      try {
        dispatch({ type: 'request' })
        const response = await superFetch({
          url: `pdfs/${pdfId}/invoice`,
          method: 'GET',
          invoiceApi: true,
        })
        checkStatus(response)
        const data = await response.json()
        dispatch({ type: 'success', invoiceId: data.id })
      } catch (error: any) {
        console.log(error)
        dispatch({ type: 'failure', error })
      }
    }
    fetchInvoiceId(id)
  }, [id])

  return loading ? (
    <span style={{ padding: '0 6.4px', top: 1, position: 'relative' }}>
      <Loader size={14} width={4} />
    </span>
  ) : (
    <CellButton
      iconId="edit"
      style={{ margin: 0, color: error ? '#ff4669' : 'unset' }}
      title={error ? error : 'Accéder au détail'}
      onClick={onClick}
    />
  )
}

export default withRouter(EditButton)
