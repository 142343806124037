//** @jsxImportSource @emotion/react */
import { FC, useCallback } from 'react'

import texture from 'assets/images/pdf/texture.png'
import zoomInIco from 'assets/images/pdf/zoomIn.png'
import zoomOutIco from 'assets/images/pdf/zoomOut.png'
import downloadIco from 'assets/images/pdf/download.png'

interface Props {
  scale: number
  setScale: any
  fileName?: string
  data: any
}

const STEP = 0.1
const LIMIT = 0.5

const PdfToolbar: FC<Props> = ({
  scale,
  setScale,
  fileName = 'file',
  data,
}) => {
  const zoomIn = useCallback(() => setScale((x: number) => x + STEP), [
    setScale,
  ])

  const zoomOut = useCallback(
    () => setScale((x: number) => (x - STEP < LIMIT ? LIMIT : x - STEP)),
    [setScale]
  )

  const download = useCallback(() => {
    const blob = new Blob([data], { type: 'application/pdf' })
    const a = window.document.createElement('a')
    a.href = URL.createObjectURL(blob)
    a.download = fileName
    a.style.display = 'none'
    window.document.body.appendChild(a)
    a.click()
    a.remove()
  }, [data, fileName])

  const percent = Math.round(scale * 100)

  return (
    <div
      css={{
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        color: '#fff',
        height: 32,
        fontSize: 12,
        backgroundColor: '#474747',
        backgroundImage: `url(${texture}), linear-gradient(hsla(0,0%,32%,.99), hsla(0,0%,27%,.95))`,
        boxShadow:
          'inset 0 1px 1px hsla(0,0%,0%,.15), inset 0 -1px 0 hsla(0,0%,100%,.05), 0 1px 0 hsla(0,0%,0%,.15), 0 1px 1px hsla(0,0%,0%,.1)',
        '.buttons:focus, .buttons:hover': {
          '.zoom-btn': {
            backgroundColor: 'hsla(0,0%,0%,.12)',
            backgroundImage:
              'linear-gradient(hsla(0,0%,100%,.05), hsla(0,0%,100%,0))',
            backgroundClip: 'padding-box',
            border: '1px solid hsla(0,0%,0%,.35)',
            borderColor:
              'hsla(0,0%,0%,.32) hsla(0,0%,0%,.38) hsla(0,0%,0%,.42)',
            boxShadow:
              '0 1px 0 hsla(0,0%,100%,.05) inset, 0 0 1px hsla(0,0%,100%,.15) inset, 0 1px 0 hsla(0,0%,100%,.05)',
            transitionProperty: 'background-color, border-color, box-shadow',
            transitionDuration: '150ms',
            transitionTimingFunction: 'ease',
          },
          '.separator': {
            opacity: 0,
          },
        },
        '.separator': {
          padding: '8px 0',
          width: 1,
          backgroundColor: 'hsla(0,0%,0%,.5)',
          boxShadow: '0 0 0 1px hsla(0,0%,100%,.08)',
          display: 'inline-block',
          margin: '4px 0',
        },
        '.pdf-btn': {
          outline: 'none',
          background: 'none',
          width: 32,
          height: 25,
          minWidth: 16,
          padding: '2px 6px 0',
          border: '1px solid transparent',
          userSelect: 'none',
          cursor: 'pointer',
          position: 'relative',
          margin: 0,
          marginRight: -1,
          marginTop: 5,
          borderTopLeftRadius: 2,
          borderBottomLeftRadius: 2,
          borderRightColor: 'transparent',
          img: {
            position: 'absolute',
            display: 'inline-block',
            transform: 'scale(0.5)',
            top: -5,
            left: -1,
          },
        },
      }}
    >
      <div />

      <div css={{ display: 'flex', alignItems: 'center' }}>
        <div>
          <button className="pdf-btn" title="Zoom arrière" onClick={zoomOut}>
            <img src={zoomOutIco} alt="" />
          </button>
          <span className="separator" />
          <button className="pdf-btn" title="Zoom avant" onClick={zoomIn}>
            <img src={zoomInIco} alt="" />
          </button>
        </div>
        <div style={{ width: '3rem', marginLeft: '.8rem', lineHeight: 1 }}>
          {percent} %
        </div>
      </div>

      <div>
        <button className="pdf-btn" title="Télécharger" onClick={download}>
          <img src={downloadIco} alt="" />
        </button>
      </div>
    </div>
  )
}

export default PdfToolbar
