import { render } from 'react-dom'
import '@fontsource/muli'
import 'react-table/react-table.css'
import 'react-datetime/css/react-datetime.css'
import 'flag-icon-css/css/flag-icons.css'
import 'styles/scss/index.scss'
import App from 'App'
import * as serviceWorker from 'serviceWorker'
import initMoment from 'helpers/moment'

initMoment()

render(<App />, document.getElementById('root'))

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister()

// PDFJS
declare global {
  interface Window {
    pdfjsLib: any
  }
}

const { pdfjsLib: pdfjs } = window

pdfjs.GlobalWorkerOptions.workerSrc = `/pdfjs/pdfjs-dist-2.1.266.worker.min.js`
