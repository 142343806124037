import { RowRenderProps } from 'react-table'
import { useDispatch } from 'react-redux'

import { Icon, CellButton } from 'library'
import { statuses } from 'labels'
import { updatePdfStatus } from 'store/invoices/pdfs'

const isSuccess = (status: number) => [8].includes(status)

const isError = (status: number) => [6, 7].includes(status)

const CellIcon = (props: { id: string; color: string }) => (
  <Icon size={14} style={{ top: 2, marginRight: 8 }} {...props} />
)

const SuccessIcon = () => <CellIcon id="check-full" color="success" />

const ErrorIcon = () => <CellIcon id="warning" color="danger" />

export const StatusCell = (props: RowRenderProps) => {
  const {
    value,
    original: { id, errorMessage },
  } = props

  const dispatch = useDispatch()

  return (
    <div
      style={{ textAlign: 'left' }}
      {...(errorMessage && { title: errorMessage })}
    >
      {isSuccess(value) && <SuccessIcon />}
      {isError(value) && <ErrorIcon />}
      {statuses[value] || ''}
      {isError(value) && (
        <CellButton
          iconId="download"
          style={{ margin: '0 0 0 4px' }}
          title={"Télécharger à nouveau depuis l'espace client du fournisseur"}
          onClick={() => {
            dispatch(updatePdfStatus.request({ id, status: 1 }))
          }}
        />
      )}
    </div>
  )
}
