import { useEffect, useState } from 'react'

const useWidth = (el: HTMLElement | null) => {
  const [width, setWidth] = useState<any>(null)
  useEffect(() => {
    if (el) {
      const width = el.getBoundingClientRect().width
      setWidth(width)
      // console.log('useWidth', width)
    }
  }, [el])
  return width
}

export default useWidth
