import React, { FC, ReactChild } from 'react'

interface Props {
  label: ReactChild
  inline?: boolean
  styles?: object
}

const FormGroup: FC<Props> = ({
  label,
  children,
  inline = false,
  styles = {},
  ...props
}) => {
  return (
    <div {...props}>
      <label
        style={{
          width: '100%',
          fontSize: 14,
          marginBottom: 0,
          ...(inline && { display: 'flex', alignItems: 'center' }),
          ...styles,
        }}
      >
        <div
          style={{
            margin: inline ? '0 .8rem 0 0' : '0 0 .8rem 0',
            fontWeight: 'bold',
          }}
        >
          {label}
        </div>
        {children}
      </label>
    </div>
  )
}

export default FormGroup
