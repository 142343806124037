import { createStructuredSelector, createSelector } from 'reselect'
import { from, of, pipe } from 'rxjs'
import {
  filter,
  map,
  switchMap,
  mapTo,
  withLatestFrom,
  catchError,
} from 'rxjs/operators'
import { combineEpics } from 'redux-observable'
import pickBy from 'lodash/pickBy'
import {
  createAction,
  createReducer,
  ActionType,
  isActionOf,
  createAsyncAction,
} from 'typesafe-actions'

import { superFetch, checkStatus } from 'helpers/apiConf'
import {
  getAdminClient,
  getAdminProvider,
  setAdminProvider,
} from 'store/invoices/selection'
import { RootState, RootAction } from 'store'

// Types
interface Pdl {
  id: number
  label: string
  pdl: number
}

interface Pdls {
  [key: string]: Pdl
}

interface NewItem {
  label: string
}

export interface PdlState {
  items: Pdls | null
  loading: boolean
  error: string | null
  newItem: NewItem | null
}

// Actions
interface PostPayload {
  id: number
  label: string
  pdl: number
  update: boolean
}

export const fetchPdl = createAsyncAction(
  '@data-mgmt/invoices/pdl/FETCH_PDL_REQUEST',
  '@data-mgmt/invoices/pdl/FETCH_PDL_SUCCESS',
  '@data-mgmt/invoices/pdl/FETCH_PDL_FAILURE'
)<undefined, Pdls, Error>()

export const postPdl = createAsyncAction(
  '@data-mgmt/invoices/pdl/POST_PDL_REQUEST',
  '@data-mgmt/invoices/pdl/POST_PDL_SUCCESS',
  '@data-mgmt/invoices/pdl/POST_PDL_FAILURE'
)<PostPayload, undefined, Error>()

export const deletePdl = createAsyncAction(
  '@data-mgmt/invoices/pdl/DELETE_PDL_REQUEST',
  '@data-mgmt/invoices/pdl/DELETE_PDL_SUCCESS',
  '@data-mgmt/invoices/pdl/DELETE_PDL_FAILURE'
)<number, undefined, Error>()

export const setNewItem = createAction(
  '@data-mgmt/invoices/pdl/SET_NEW_ITEM',
  (action) => (newItem: NewItem) => action(newItem)
)

export const deleteNewItem = createAction(
  '@data-mgmt/invoices/pdl/DELETE_NEW_ITEM',
  (action) => () => action()
)

const actions = { fetchPdl, postPdl, deletePdl, setNewItem, deleteNewItem }
export type PdlAction = ActionType<typeof actions>

// Reducer
const initialState: PdlState = {
  items: null,
  loading: false,
  error: null,
  newItem: null,
}

export default createReducer<PdlState, PdlAction>(initialState)
  .handleAction(fetchPdl.request, (state) => ({
    ...state,
    loading: true,
    error: null,
  }))
  .handleAction(fetchPdl.success, (state, action) => ({
    ...state,
    items: action.payload,
    loading: false,
    error: null,
  }))
  .handleAction(fetchPdl.failure, (state, action) => ({
    ...state,
    items: {},
    loading: false,
    error: action.payload.message,
  }))
  .handleAction([postPdl.request, postPdl.request], (state) => ({
    ...state,
    loading: true,
    error: null,
  }))
  .handleAction([postPdl.success, postPdl.success], (state) => ({
    ...state,
    loading: false,
    error: null,
  }))
  .handleAction([postPdl.failure, postPdl.failure], (state, action) => ({
    ...state,
    loading: false,
    error: action.payload.message,
  }))
  .handleAction(setNewItem, (state, action) => ({
    ...state,
    newItem: action.payload,
  }))
  .handleAction(deleteNewItem, (state) => ({
    ...state,
    newItem: null,
  }))

// Selectors
export const getItems = (state: RootState) => state.invoices.pdl.items
export const getLoading = (state: RootState) => state.invoices.pdl.loading
export const getError = (state: RootState) => state.invoices.pdl.error
export const getNewItem = (state: RootState) => state.invoices.pdl.newItem

const getFilteredPdl = createSelector(
  getItems,
  getAdminClient,
  (pdls, client) => {
    if (!pdls) return null
    return pickBy(pdls, (item: any) => item.client === client)
  }
)

interface Selection {
  pdl: Pdls | null
  loading: boolean
  error: string | null
}

export const getPdl = createStructuredSelector<RootState, Selection>({
  pdl: getFilteredPdl,
  loading: getLoading,
  error: getError,
})

// API
interface ResponseItem {
  id: number
  nomTranscode: string
  nomFacture: string
  nomFournisseur: string
  uorId: number
}

const normalize = (data: ResponseItem[]) =>
  data.reduce(
    (acc, item) => ({
      ...acc,
      [item.id]: {
        id: item.id,
        pdl: item.nomTranscode,
        label: item.nomFacture,
        provider: item.nomFournisseur,
        client: item.uorId,
      },
    }),
    {}
  )

const api = {
  fetchPdl: async (provider: string | null) => {
    const response = await superFetch({
      url: `transcodage?supplier=${provider}`,
      invoiceApi: true,
    })
    checkStatus(response)
    const data = await response.json()
    return normalize(data)
  },
  postPdl: async (
    client: number | null,
    provider: string | null,
    payload: PostPayload
  ) => {
    const { id, label, pdl, update } = payload
    const response = await superFetch({
      url: update ? `transcodage/${id}` : 'transcodage',
      invoiceApi: true,
      method: update ? 'PUT' : 'POST',
      body: {
        Id: id,
        UorId: client,
        CodeFournisseur: provider,
        NomFacture: label,
        NomTranscode: pdl,
      },
    })
    checkStatus(response)
    return response
  },
  deletePdl: async (id: number) => {
    const response = await superFetch({
      url: `transcodage/${id}`,
      invoiceApi: true,
      method: 'DELETE',
    })
    checkStatus(response)
    return response
  },
}

// Epics
export const pdlEpic = combineEpics<RootAction, RootAction, RootState>(
  (action$, state$) =>
    action$.pipe(
      filter(isActionOf(fetchPdl.request)),
      withLatestFrom(state$),
      switchMap(([_, state]) =>
        from(api.fetchPdl(getAdminProvider(state))).pipe(
          map(fetchPdl.success),
          catchError<any, any>(pipe(fetchPdl.failure, of))
        )
      )
    ),
  (action$, state$) =>
    action$.pipe(
      filter(isActionOf(postPdl.request)),
      withLatestFrom(state$),
      switchMap(([action, state]) =>
        from(
          api.postPdl(
            getAdminClient(state),
            getAdminProvider(state),
            action.payload
          )
        ).pipe(
          map(postPdl.success),
          catchError<any, any>(pipe(postPdl.failure, of))
        )
      )
    ),
  (action$) =>
    action$.pipe(
      filter(isActionOf(deletePdl.request)),
      switchMap((action) =>
        from(api.deletePdl(action.payload)).pipe(
          map(deletePdl.success),
          catchError<any, any>(pipe(deletePdl.failure, of))
        )
      )
    ),
  (action$) =>
    action$.pipe(
      filter(
        isActionOf([setAdminProvider, postPdl.success, deletePdl.success])
      ),
      mapTo(fetchPdl.request())
    )
)
