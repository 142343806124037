import { useDeleteExecutionMutation } from 'queries/crawler'
import { CellButton } from 'library'

type Props = {
  id: number
}

export const BtnDeleteExecution = ({ id }: Props) => {
  const { mutate, isLoading } = useDeleteExecutionMutation(id)
  return (
    <CellButton
      iconId="trash"
      color="danger"
      style={{ margin: 0 }}
      disabled={isLoading}
      onClick={(e) => {
        e.stopPropagation()
        mutate()
      }}
    />
  )
}
