import { createAction, createAsyncAction } from 'typesafe-actions'

import { Response, ParsingResult } from './types'

export const fetchPdfs = createAsyncAction(
  '@data-mgmt/invoices/pdfs/FETCH_REQUEST',
  '@data-mgmt/invoices/pdfs/FETCH_SUCCESS',
  '@data-mgmt/invoices/pdfs/FETCH_FAILURE'
)<undefined, Response, Error>()

export const evaluatePdf = createAsyncAction(
  '@data-mgmt/invoices/pdfs/EVALUATE_REQUEST',
  '@data-mgmt/invoices/pdfs/EVALUATE_SUCCESS',
  '@data-mgmt/invoices/pdfs/EVALUATE_FAILURE'
)<number, ParsingResult | null, Error>()

export const deletePdf = createAsyncAction(
  '@data-mgmt/invoices/pdfs/DELETE_REQUEST',
  '@data-mgmt/invoices/pdfs/DELETE_SUCCESS',
  '@data-mgmt/invoices/pdfs/DELETE_FAILURE'
)<number, undefined, Error>()

export const deletePdfs = createAsyncAction(
  '@data-mgmt/invoices/pdfs/DELETE_PDFS_REQUEST',
  '@data-mgmt/invoices/pdfs/DELETE_PDFS_SUCCESS',
  '@data-mgmt/invoices/pdfs/DELETE_PDFS_FAILURE'
)<number[], undefined, Error>()

export const reparsePdfs = createAsyncAction(
  '@data-mgmt/invoices/pdfs/REPARSE_PDFS_REQUEST',
  '@data-mgmt/invoices/pdfs/REPARSE_PDFS_SUCCESS',
  '@data-mgmt/invoices/pdfs/REPARSE_PDFS_FAILURE'
)<number[], undefined, Error>()

export const updatePdfStatus = createAsyncAction(
  '@data-mgmt/invoices/pdfs/UPDATE_STATUS_REQUEST',
  '@data-mgmt/invoices/pdfs/UPDATE_STATUS_SUCCESS',
  '@data-mgmt/invoices/pdfs/UPDATE_STATUS_FAILURE'
)<{ id: number; status: number }, undefined, Error>()

export const setPageIndex = createAction(
  '@data-mgmt/invoices/pdfs/SET_PAGE_INDEX',
  (action) => (pageIndex: number) => action(pageIndex)
)

export const setPageSize = createAction(
  '@data-mgmt/invoices/pdfs/SET_PAGE_SIZE',
  (action) => (pageSize: number) => action(pageSize)
)
