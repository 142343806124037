import { useQuery } from 'react-query'
import * as api from 'api/countries'

export function useCountriesQuery() {
  return useQuery({
    queryKey: ['countries'],
    queryFn: () => api.getCountries(),
    suspense: false,
    refetchOnWindowFocus: false,
  })
}