//** @jsxImportSource @emotion/react */
import moment from 'moment'
import { Crawler } from 'api/types'
import {
  useSearchCrawlersQuery,
  useCreateExecutionMutation,
} from 'queries/crawler'

const randomElement = <T,>(l: Array<T>): T =>
  l[Math.floor(Math.random() * l.length)]

const randomPeriod = () => {
  const n1 = Math.floor(Math.random() * 10)
  const n2 = n1 - Math.floor(Math.random() * 10)
  return {
    dateDebut: moment()
      .subtract(n1, 'month')
      .format('YYYY-MM-DD'),
    dateFin: moment()
      .subtract(n2, 'month')
      .format('YYYY-MM-DD'),
  }
}

export const BtnCreateExecution = () => {
  const { data: crawlers, ...query } = useSearchCrawlersQuery({
    suspense: false,
  })
  const { mutate, ...mutation } = useCreateExecutionMutation()
  const isLoading = query.isFetching || mutation.isLoading
  return (
    <button
      className="btn btn-danger btn-sm"
      disabled={isLoading || !crawlers || crawlers.list.length === 0}
      onClick={() => {
        if (!crawlers) return
        const crawlerId = randomElement<Crawler>(crawlers.list).id
        const { dateDebut, dateFin } = randomPeriod()
        const payload = { crawlerId, dateDebut, dateFin }
        mutate(payload)
      }}
    >
      Créer une execution
    </button>
  )
}
