import { combineReducers, Reducer, AnyAction } from 'redux'
import { combineEpics } from 'redux-observable'
import { reducer as notifications } from 'react-notification-system-redux'
import { reducer as oidc } from 'redux-oidc'

import user from './user'
import invoices, { invoicesRootEpic, InvoicesRootAction } from './invoices'

export const rootReducer = combineReducers({
  notifications: notifications as Reducer<any[], AnyAction>,
  oidc,
  user,
  invoices,
})

export const rootEpic = combineEpics(invoicesRootEpic)
export type RootState = ReturnType<typeof rootReducer>
export type RootAction = InvoicesRootAction
