//** @jsxImportSource @emotion/react */
import { FC } from 'react'
import { FormGroup, Label, Input, FormFeedback } from 'reactstrap'
import { useSelector } from 'react-redux'

import { getInvoice } from 'store/invoices/invoice'

const InvoiceTotalInput: FC<any> = ({
  field,
  form: { touched, errors },
  formatter = (x: any) => x,
  label,
  ...props
}) => {
  const { invoice } = useSelector(getInvoice)
  const sum: any =
    invoice && invoice.lignes
      ? formatter(
          Object.values(invoice.lignes as any).reduce((acc, item: any) => {
            return item.montantHT === null
              ? acc
              : +(acc + item.montantHT).toFixed(4)
          }, 0)
        )
      : null

  return (
    <FormGroup css={{ display: 'flex', alignItems: 'center', marginBottom: 0 }}>
      <Label for={props.name} css={{ width: 130, marginBottom: 0 }}>
        {label}
      </Label>

      <Input
        type="text"
        bsSize="sm"
        {...field}
        {...props}
        value={formatter(field.value) || ''}
        invalid={!!errors[field.name]}
        css={{ width: 170 }}
      />

      {touched[field.name] && errors[field.name] ? (
        <FormFeedback css={{ width: 90, margin: '0 0 0 10px' }}>
          {errors[field.name]}
        </FormFeedback>
      ) : invoice && invoice.lignes ? (
        <div
          css={{
            width: 90,
            margin: '0 0 0 10px',
            fontSize: '80%',
            color: field.value !== sum ? '#ffae42' : 'unset',
          }}
        >
          Somme des lignes = {sum}
        </div>
      ) : null}
    </FormGroup>
  )
}

export default InvoiceTotalInput
