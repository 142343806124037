import React, { FC } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { getProviders, fetchProviders } from 'store/invoices/providers'
import { setProvider, getProvider } from 'store/invoices/selection'
import { Select, FormGroup } from 'library'

interface Props {
  inline?: boolean
  width?: number
  showAllOption?: boolean
}

const ProviderSelect: FC<Props> = ({
  inline,
  width,
  showAllOption = false,
}) => {
  const dispatch = useDispatch()
  const { providers, loading, error } = useSelector(getProviders)
  const selected = useSelector(getProvider)

  React.useEffect(() => {
    if (!providers && !loading) dispatch(fetchProviders.request())
  }, [dispatch, loading, providers])

  const options = React.useMemo(
    () =>
      !providers
        ? []
        : [
            ...(showAllOption ? [{ value: null, label: 'Tous' }] : []),
            ...Object.values(providers).map((p: any) => ({
              value: p.id,
              label: p.label,
            })),
          ],
    [providers, showAllOption]
  )

  if (error) return null

  return (
    <FormGroup label="Fournisseur" inline={inline}>
      <Select
        value={options.filter((option) => option.value === selected)}
        options={options}
        isLoading={loading}
        width={width}
        onChange={(option: any) => {
          dispatch(setProvider(option ? option.value : null))
        }}
        placeholder=""
      />
    </FormGroup>
  )
}

export default ProviderSelect
