import ReactSelect from 'react-select'

const selectColor = 'rgb(0,199,147)'
const focusColor = 'rgba(0,199,147,.3)'

const Select = ({ fontSize = 14, invalid, ...props }: any) => {
  return (
    <ReactSelect
      placeholder={
        props.isLoading
          ? 'Chargement...'
          : props.placeholder || 'Sélectionnez...'
      }
      styles={{
        container: (provided) => ({
          ...provided,
          fontSize: fontSize,
          textOverflow: 'ellipsis',
          whiteSpace: 'nowrap',
          width: props.width || 'unset',
        }),
        control: (provided, state) => ({
          ...provided,
          minHeight: 'initial',
          borderRadius: '.2rem',
          borderColor: invalid
            ? '#f00'
            : state.isFocused
            ? 'transparent'
            : '#d7dee4',
          boxShadow:
            state.isFocused && !invalid ? `0 0 0 1px ${selectColor}` : 'unset',
          paddingTop: 1,
          ':hover': {
            borderColor: invalid
              ? '#f00'
              : state.isFocused
              ? 'transparent'
              : selectColor,
          },
        }),
        indicatorSeparator: (provided) => ({
          ...provided,
          display: 'none',
        }),
        dropdownIndicator: (provided) => ({
          ...provided,
          padding: '0 6px',
        }),
        menu: (provided) => ({
          ...provided,
          marginTop: 1,
          zIndex: 100,
        }),
        menuList: (provided) => ({
          ...provided,
          padding: 0,
        }),
        option: (provided, state) => ({
          ...provided,
          padding: '6px 10px',
          textOverflow: 'ellipsis',
          whiteSpace: 'nowrap',
          ':active': {
            background: selectColor,
            color: '#fff',
          },
          ...(!state.isFocused
            ? {}
            : {
                background: focusColor,
                color: 'unset',
              }),
          ...(!state.isSelected
            ? {}
            : {
                background: selectColor,
                color: '#fff',
              }),
        }),
      }}
      {...props}
    />
  )
}

export default Select
