import { superFetch, asyncCheckStatus } from 'helpers/apiConf'
import { Crawler, CrawlerExecution, CrawlerFacture } from 'api/types'

/* Crawlers */

export async function searchCrawlers() {
  const resp = await superFetch({
    url: `supervision/crawlers`,
    invoiceApi: true,
  })
  const data = await asyncCheckStatus(resp)
  return data as Array<Crawler>
}

export async function createCrawler(crawler: Partial<Crawler>) {
  const resp = await superFetch({
    method: 'POST',
    url: `supervision/crawlers`,
    body: crawler,
    invoiceApi: true,
  })
  const data = await asyncCheckStatus(resp)
  return data as CrawlerExecution
}

export async function deleteCrawler(crawlerId: number) {
  const resp = await superFetch({
    method: 'DELETE',
    url: `supervision/crawlers/${crawlerId}`,
    invoiceApi: true,
  })
  await asyncCheckStatus(resp)
}

/* Executions */

export type SearchExecutionsParams = {
  customer: number
  supplier: string
  startDate: string
  endDate: string
}

export async function searchExecutions(
  params: SearchExecutionsParams
): Promise<Array<CrawlerExecution>> {
  const queryParams = Object.entries({
    client: params.customer,
    fournisseur: params.supplier,
    debut: params.startDate,
    fin: params.endDate,
  })
    .map((entry) => entry.join('='))
    .join('&')

  const resp = await superFetch({
    url: `supervision/executions/search?${queryParams}`,
    invoiceApi: true,
  })

  const data = await asyncCheckStatus(resp)
  return data as Array<CrawlerExecution>
}

export async function getExecution(execId: number) {
  const resp = await superFetch({
    url: `supervision/executions/${execId}`,
    invoiceApi: true,
  })
  const data = await asyncCheckStatus(resp)
  return data as unknown
}

export async function createExecution(execution: Partial<CrawlerExecution>) {
  const resp = await superFetch({
    method: 'POST',
    url: `supervision/crawlers/${execution.crawlerId}/executions`,
    body: execution,
    invoiceApi: true,
  })
  const data = await asyncCheckStatus(resp)
  return data as CrawlerExecution
}

export async function deleteExecution(execId: number) {
  const resp = await superFetch({
    method: 'DELETE',
    url: `supervision/executions/${execId}`,
    invoiceApi: true,
  })
  await asyncCheckStatus(resp)
}

/* Factures */

export async function searchFactures(execId: number) {
  const resp = await superFetch({
    url: `supervision/executions/${execId}/factures`,
    invoiceApi: true,
  })
  const data = await asyncCheckStatus(resp)
  return data as Array<CrawlerFacture>
}

export async function createFacture(facture: Partial<CrawlerFacture>) {
  const resp = await superFetch({
    method: 'POST',
    url: `supervision/executions/${facture.executionId}/factures`,
    body: facture,
    invoiceApi: true,
  })
  const data = await asyncCheckStatus(resp)
  return data as CrawlerExecution
}

export async function deleteFacture(facId: number) {
  const resp = await superFetch({
    method: 'DELETE',
    url: `supervision/facture/${facId}`,
    invoiceApi: true,
  })
  await asyncCheckStatus(resp)
}
