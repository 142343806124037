//** @jsxImportSource @emotion/react */
import { Fragment } from 'react'

import { Section } from 'library'
import AdminProviderSelect from '../filters/AdminProviderSelect'
import AdminClientSelect from '../filters/AdminClientSelect'
import IatTable from './IatTable'
import PdlTable from './PdlTable'

const Admin = () => {
  return (
    <Fragment>
      <Section title="Transcodage IAT">
        <header
          css={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'flex-end',
            marginBottom: 15,
          }}
        >
          <div
            css={{
              display: 'flex',
              '>*:not(:first-of-type)': { marginLeft: '1rem' },
            }}
          >
            <AdminProviderSelect inline width={180} />
          </div>
        </header>
        <IatTable />
      </Section>

      <Section title="Transcodage PDL">
        <header
          css={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'flex-end',
            marginBottom: 15,
          }}
        >
          <div
            css={{
              display: 'flex',
              '>*:not(:first-of-type)': { marginLeft: '1rem' },
            }}
          >
            <AdminProviderSelect inline width={180} />
            <AdminClientSelect inline width={200} />
          </div>
        </header>
        <PdlTable />
      </Section>
    </Fragment>
  )
}

export default Admin
