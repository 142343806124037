import { isActionOf } from 'typesafe-actions'
import { combineEpics } from 'redux-observable'
import { from, of, pipe } from 'rxjs'
import {
  filter,
  flatMap,
  map,
  mapTo,
  switchMap,
  catchError,
  withLatestFrom,
  debounceTime,
} from 'rxjs/operators'

import { RootState, RootAction } from 'store'

import * as api from './api'
import {
  fetchPdfs,
  deletePdf,
  deletePdfs,
  reparsePdfs,
  updatePdfStatus,
  evaluatePdf,
  setPageIndex,
} from './actions'
import { getPdf, getPageIndex, getPageSize } from './selectors'

import {
  setProvider,
  setClient,
  setStartDate,
  setEndDate,
  setPdfStatus,
  setPdl,
  setCreatedDate,
  setUpdatedDate,
  setInvoiceNumber,
  getProvider,
  getClient,
  getStartDate,
  getEndDate,
  getPdl,
  getPdfStatus,
  getCreatedDate,
  getUpdatedDate,
  getInvoiceNumber,
} from 'store/invoices/selection'

export default combineEpics<RootAction, RootAction, RootState>(
  (action$, state$) =>
    action$.pipe(
      filter(isActionOf(fetchPdfs.request)),
      withLatestFrom(state$),
      switchMap(([_, state]) =>
        from(
          api.fetchPdfs(
            getPageIndex(state),
            getPageSize(state),
            getProvider(state),
            getClient(state),
            getStartDate(state),
            getEndDate(state),
            getPdl(state),
            getCreatedDate(state),
            getUpdatedDate(state),
            getInvoiceNumber(state),
            getPdfStatus(state)
          )
        ).pipe(
          map(fetchPdfs.success),
          catchError<any, any>(pipe(fetchPdfs.failure, of))
        )
      )
    ),
  (action$) =>
    action$.pipe(
      filter(isActionOf(deletePdf.request)),
      switchMap((action) =>
        from(api.deletePdf(action.payload)).pipe(
          map(deletePdf.success),
          catchError<any, any>(pipe(deletePdf.failure, of))
        )
      )
    ),
  (action$) =>
    action$.pipe(
      filter(isActionOf(deletePdfs.request)),
      switchMap((action) =>
        from(api.deletePdfs(action.payload)).pipe(
          map(deletePdfs.success),
          catchError<any, any>(pipe(deletePdfs.failure, of))
        )
      )
    ),
  (action$) =>
    action$.pipe(
      filter(isActionOf(reparsePdfs.request)),
      switchMap((action) =>
        from(api.reparsePdfs(action.payload)).pipe(
          map(reparsePdfs.success),
          catchError<any, any>(pipe(reparsePdfs.failure, of))
        )
      )
    ),
  (action$, state$) =>
    action$.pipe(
      filter(isActionOf(updatePdfStatus.request)),
      withLatestFrom(state$),
      switchMap(([action, state]) =>
        from(
          api.forceDownloadPdf({
            ...getPdf(action.payload.id)(state),
            status: action.payload.status,
          })
        ).pipe(
          map(updatePdfStatus.success),
          catchError<any, any>(pipe(updatePdfStatus.failure, of))
        )
      )
    ),
  (action$) =>
    action$.pipe(
      filter(isActionOf(evaluatePdf.request)),
      switchMap((action) =>
        from(api.evaluatePdf(action.payload)).pipe(
          map(evaluatePdf.success),
          catchError<any, any>(pipe(evaluatePdf.failure, of))
        )
      )
    ),
  (action$) =>
    action$.pipe(
      filter(
        isActionOf([
          deletePdf.success,
          deletePdfs.success,
          reparsePdfs.success,
          updatePdfStatus.success,
        ])
      ),
      mapTo<any, any>(fetchPdfs.request())
    ),
  (action$) =>
    action$.pipe(
      filter(isActionOf(evaluatePdf.success)),
      filter((action: any) => action.payload && !action.payload.errorType),
      mapTo<any, any>(fetchPdfs.request())
    ),
  (action$) =>
    action$.pipe(
      filter(
        isActionOf([
          setProvider,
          setClient,
          setStartDate,
          setEndDate,
          setPdfStatus,
          setPdl,
          setCreatedDate,
          setUpdatedDate,
          setInvoiceNumber,
          evaluatePdf.success,
        ])
      ),
      debounceTime(200),
      flatMap(() => of(setPageIndex(0), fetchPdfs.request()))
    )
)
