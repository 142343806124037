const types = {
  USER_LOGIN_REQUEST: '@data-mgmt/user/USER_LOGIN_REQUEST',
  USER_LOGIN_SUCCESS: '@data-mgmt/user/USER_LOGIN_SUCCESS',
  USER_LOGIN_FAILURE: '@data-mgmt/user/USER_LOGIN_FAILURE',
  USER_LOGOUT_REQUEST: '@data-mgmt/user/USER_LOGOUT_REQUEST',
  USER_LOGOUT_SUCCESS: '@data-mgmt/user/USER_LOGOUT_SUCCESS',
  USER_LOGOUT_FAILURE: '@data-mgmt/user/USER_LOGOUT_FAILURE',
  PASSWORD_RESET_REQUEST: '@data-mgmt/user/PASSWORD_RESET_REQUEST',
  PASSWORD_RESET_SUCCESS: '@data-mgmt/user/PASSWORD_RESET_SUCCESS',
  PASSWORD_RESET_FAILURE: '@data-mgmt/user/PASSWORD_RESET_FAILURE',
  CHANGE_PASSWORD_REQUEST: '@data-mgmt/user/CHANGE_PASSWORD_REQUEST',
  CHANGE_PASSWORD_FAILURE: '@data-mgmt/user/CHANGE_PASSWORD_FAILURE',
  CHANGE_PASSWORD_SUCCESS: '@data-mgmt/user/CHANGE_PASSWORD_SUCCESS',
  USER_INFO_REQUEST: '@data-mgmt/user/USER_INFO_REQUEST',
  USER_INFO_SUCCESS: '@data-mgmt/user/USER_INFO_SUCCESS',
  USER_INFO_FAILURE: '@data-mgmt/user/USER_INFO_FAILURE',
  USER_FORM_POST_REQUEST: '@data-mgmt/user/USER_FORM_POST_REQUEST',
  USER_FORM_POST_SUCCESS: '@data-mgmt/user/USER_FORM_POST_SUCCESS',
  USER_FORM_POST_FAILURE: '@data-mgmt/user/USER_FORM_POST_FAILURE',
  USER_FORM_UPDATE: '@data-mgmt/user/USER_FORM_UPDATE',
  USER_FORM_RESET: '@data-mgmt/user/USER_FORM_RESET',
  USER_INFOS_SUCCESS: '@data-mgmt/user/USER_INFOS_SUCCESS',
}

export default types